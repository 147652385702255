import {APIClient} from './APIClient';
import constants from '../constants';

let api: APIClient | null = null;

export const getApiClient = (): APIClient => {
    if (!api) {
        api = new APIClient(constants.BASE_URL);
    }
    return api;
};
