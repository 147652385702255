import React from 'react';
import {AlertMessage} from '../../layout';
import './Pdf.css';

interface IPdfProps { url: string }
const Pdf: React.FC<IPdfProps> = ({url}) => (
    <object className={'pdf'} type="application/pdf" data={url}>
        <AlertMessage title={'Pdf not available'} message={'Pdf not available'}/>
    </object>
);
export default Pdf;
