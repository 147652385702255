import  React from 'react';
import '../../routes/benchmarks/benchmarks.css'
interface IQuestionItemProps {
    question: string;
    id: string,
    onClick: any
}

const  QuestionItem : React.FC<IQuestionItemProps> = (props: IQuestionItemProps) =>{
    return (
        <tr className={"nav-question"} >
            <td>
                <a href={"#"+props.id}>
                    <p>{props.question}</p>
                </a>
            </td>

        </tr>);
};

export default QuestionItem;
