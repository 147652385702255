import {general} from '../../../assets';
import React, {Ref, useRef, useState} from 'react';
import useOutsideAlerter from '../../../helpers/hooks/OutsideAlert';
import './Dropdown.css';

export interface IDropdownOptions {
    value: string,
    description: string,
    img?: string
}

interface IBaseDropdownProps {
    setValue: (val: string) => void;
    getTitle: () => IDropdownOptions;
    getValue: () => string;
    options: IDropdownOptions[];
    className?: 'small' | '';
    warning?: any;
    flipped?: boolean;
}

const BaseDropdown: React.FC<IBaseDropdownProps> = (
    {
        setValue,
        getTitle,
        getValue,
        options,
        className = '',
        warning,
        flipped,
    }) => {
    const [visible, setVisible] = useState(false);

    // Closes menu on click outside the dropdown
    const wrapperRef = useRef<HTMLDivElement>(null);
    useOutsideAlerter(wrapperRef, () => setVisible(false));

    /**
     * Handles updating change
     */
    const handleChange = (val: string) => {
        setVisible(!visible);
        setValue(val);
    };

    return (
        <div ref={wrapperRef} className={`dropdown ${flipped ? 'flipped' : ''}`}>
            <div
                className={`select ${visible ? 'active' : ''} ${warning ? 'warning' : ''}`}
                onClick={() => setVisible(!visible)}>
                <span
                    className={`${(getValue() === '') ? 'greyed' : ''} ${className ? className : ''}`}
                >
                    {getTitle().img &&
                    <img
                        className={'icon'}
                        src={getTitle().img}
                        alt=''/>
                    }
                    {getTitle().description}</span>
                <img className={'indicator'} src={general.dropdown} alt='^'/>
            </div>
            <DropdownMenu
                width={wrapperRef.current?.clientWidth}
                visible={visible}
                options={options}
                className={className}
                handleChange={handleChange}/>
        </div>
    );
};


interface IDropdownMenuProps {
    visible: boolean;
    options: IDropdownOptions[];
    className?: 'small' | '';
    handleChange: (val: string) => void;
    width?: number;
}

export const DropdownMenu = React.forwardRef<HTMLUListElement, IDropdownMenuProps>((
    {visible, options, className = '', handleChange, width},
    ref: Ref<HTMLUListElement>
) => (
    visible ? (
        <ul style={{width: (width ? width - 2 : undefined)}} ref={ref}>
            {options.length === 0 && (
                <div className="no-options">No remaining options</div>
            )}
            {options.map((item) =>
                <li
                    key={item.value}
                    value={item.value}
                    className={`${(item.img) ? 'icon' : ''} ${className ? className : ''}`}
                    onClick={() => handleChange(item.value)}>
                    {item.img &&
                    <img
                        className={'icon'}
                        src={item.img}
                        alt=''/>
                    }
                    {item.description}
                </li>
            )}
        </ul>
    ) : null
));

export default BaseDropdown;
