import React, {useState} from 'react';
import './EditCategory.css';
import '../../../submit/Submit.css';
import {SimpleDropdown} from '../../../common/dropdown';
import {getCategoryIcon} from '../../../../helpers/get-icons';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useHistory} from 'react-router-dom';
import {IBenchmark} from '../../../../state/reducers/benchmarks';
import {IQuestion} from '../../../../helpers/interfaces';
import {branding, general} from '../../../../assets';
import {BaseModal} from '../../../common/modal';
import {TextArea} from '../../../common/text-input';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {getApiClient} from '../../../../api';
import {BenchmarksAction, CategoryActions} from '../../../../state/actions';
import {Spinner} from '../../../common/spinner';
import {AlertMessage} from '../../../layout';
import {TextInput} from '../../../common';

/** Edit category title */
interface IEditTitleProps {
    categoryId: string;
    categoryTitle: string;
}

const EditTitle: React.FC<IEditTitleProps> = ({categoryId, categoryTitle}) => {
    const [editing, setEditing] = useState(false);
    const [error, setError] = useState();
    const dispatch = useDispatch();

    /** Handle submit */
    const handle = async (val: {title: string}, {setSubmitting}: any) => {
        setError(undefined);
        try {
                await getApiClient().categoriesService.update({categoryId, title: val.title});
            dispatch(CategoryActions.get());
            setSubmitting(false);
            setEditing(false);
        } catch (e) {
            setError(e);
        }
    };

    /** Form validation */
    const validation = Yup.object().shape({title: Yup.string().required('Required')});


    if (!editing) {
        return (
            <div onClick={() => setEditing(true)} className={'edit-title inactive'}>
                <img src={general.pen_blue} alt='edit'/>
                <span>edit title</span>
            </div>
        )
    } else {
        return (
            <div className={'edit-title'}>
                <Formik
                    initialValues={{title: categoryTitle}}
                    validationSchema={validation}
                    onSubmit={handle}>
                    {(props) => (
                        <Form onSubmit={props.handleSubmit}>
                            <TextInput
                                name={'title'}
                                label={'Title'}
                                value={props.values.title}
                                warning={(props.errors.title && props.touched.title) && props.errors.title}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}/>
                            <button
                                type={'submit'}
                                disabled={props.values.title === categoryTitle || props.isSubmitting}>
                                {props.isSubmitting && <Spinner/>}Submit
                            </button>
                            {props.isSubmitting && <Spinner/>}
                            <button
                                disabled={props.isSubmitting}
                                className={'cancel'}
                                onClick={() => setEditing(false)}>
                                Cancel
                            </button>
                        </Form>
                    )}
                </Formik>
                <AlertMessage className={'error'} message={error}/>
            </div>
        )
    }
};

/** Navigator */
const Navigator: React.FC = () => {
    const {questions} = useSelector((state: any) => (state.questions));
    return (
        <div className={'navigator'}>
            <h4>Navigate to</h4>
            {questions.map((item: IQuestion) => (
                <a key={item.id} href={"#" + item.id}>
                    {item.description}
                </a>
            ))}
        </div>
    );
};


/** Benchmark */
interface IBenchmarkProps {
    benchmark: any;
    onClick: () => void;
}

const Benchmark: React.FC<IBenchmarkProps> = ({benchmark, onClick}) => {
    return (
        <div
            onMouseDown={(e) => {
                e.preventDefault();
                onClick()
            }}>
            <p>
                {benchmark.description}
            </p>
        </div>
    );
};


/** Criteria Group */
interface ICriteriaGroupProps {
    categoryId: string;
    questionId: string;
    benchmarks: IBenchmark[];
    index: number;
    onClick: (val: { questionId: string, benchmarkId?: string }) => void;
}

const CriteriaGroup: React.FC<ICriteriaGroupProps> = ({categoryId, questionId, benchmarks, index, onClick}) => {
    const {questions} = useSelector((state: any) => (state.questions));


    return (
        <>
            {questions && (
                <div className={'criteria-group'}  id={questionId}>
                    <h3>Criteria {index + 1}</h3>
                    <div className={'question'}>
                        <p>{questions.find((item: IQuestion) => item.id === questionId)?.description}</p>
                    </div>
                    <div className={'benchmarks'}>
                        <h4>Benchmark</h4>
                        {benchmarks.filter((benchmark: any) => benchmark.category?.id === categoryId).map((benchmark: any) =>
                            <Benchmark key={benchmark.id}
                                       benchmark={benchmark}
                                       onClick={() => onClick({questionId: questionId, benchmarkId: benchmark.id})}/>)}
                        <div
                            className={'add-benchmark'}
                            onMouseDown={(e) => {
                                e.preventDefault();
                                onClick({questionId: questionId, benchmarkId: undefined})
                            }}>
                            <img src={branding.new_law_icon_blue} alt='+'/><span>new benchmark</span>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};


/** Edit / New Standard Modal */
interface IBenchmarkSchema {
    benchmark: string
}

interface IBenchmarkModalProps {
    categoryId?: string;
    questionId?: string;
    benchmarkId?: string;
    onClose: () => void;
}

const BenchmarkModal: React.FC<IBenchmarkModalProps> = ({questionId, categoryId, benchmarkId, onClose}) => {
    const {questions} = useSelector((state: any) => (state.questions));
    const {benchmarks} = useSelector((state: any) => (state.benchmarks));
    const dispatch = useDispatch();
    const [error, setError] = useState();

    // If no question, don't show anything
    if (!questionId || !categoryId) {
        return <p>nothing selected.</p>
    }

    /** Handle submission */
    const handle = async (val: IBenchmarkSchema, {setSubmitting}: any) => {
        if (!questionId) { // Can't submit if no question provided
            return;
        }
        setError(undefined);
        try {
            if (benchmarkId) {
                await getApiClient().standardsService.edit({benchmarkId: benchmarkId, description: val.benchmark});
            } else {
                await getApiClient().standardsService.create({questionId, categoryId, description: val.benchmark});
            }
            dispatch(BenchmarksAction.get());
            setSubmitting(false);
            onClose();
        } catch (e) {
            setError(e);
        }
    };

    const handleDelete = async () => {
        if (!benchmarkId) { // This will never happen unless the props are manipulated
            return;
        }
        try {
            await getApiClient().standardsService.delete({benchmarkId});
            dispatch(BenchmarksAction.get());
            onClose();
        } catch (e) {
            setError(e);
        }
    };

    /** Form validation */
    const validation = Yup.object().shape({benchmark: Yup.string().required('Required')});

    /** Initial value */
    const benchmarkObject = (benchmarkId) ? benchmarks[questionId]?.find((item: any) => item.id === benchmarkId) : undefined;
    const initial: IBenchmarkSchema = {benchmark: (!!benchmarkObject) ? benchmarkObject.description : ''};

    return (
        <div className={'benchmark-modal'}>
            <div className={'benchmark-content'}>
                <h3>{benchmarkId ? 'Edit' : 'Add New'} Benchmark</h3>
                <h5>{questions.find((item: IQuestion) => item.id === questionId)?.description}</h5>
                <Formik
                    initialValues={initial}
                    validationSchema={validation}
                    onSubmit={handle}>
                    {(props) => (
                        <Form onSubmit={props.handleSubmit}>
                            <div className={'box-area'}>
                                <TextArea
                                    name={'benchmark'}
                                    label={'Benchmark'}
                                    value={props.values.benchmark}
                                    warning={(props.errors.benchmark && props.touched.benchmark) && props.errors.benchmark}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}/>
                            </div>
                            <div className={'spacing'}>
                                <button
                                    className={'submit'}
                                    type={'submit'}
                                    onClick={() => props.handleSubmit()}
                                    disabled={initial.benchmark === props.values.benchmark || props.isSubmitting}>
                                    {props.isSubmitting &&
                                    <Spinner/>}{benchmarkId ? 'Submit Change' : 'Add New Benchmark'}
                                </button>
                            </div>
                            {(benchmarkId && initial.benchmark === props.values.benchmark) && (
                                <div className={'spacing'}>
                                    <button
                                        onClick={async (e) => {
                                            e.preventDefault();
                                            props.setSubmitting(true);
                                            await handleDelete()
                                        }}
                                        className={'submit red'}
                                        disabled={initial.benchmark !== props.values.benchmark || props.isSubmitting}>
                                        {props.isSubmitting && <Spinner/>}Remove Benchmark
                                    </button>
                                </div>
                            )}
                        </Form>
                    )}
                </Formik>
                <AlertMessage className={'error'} message={error}/>
            </div>
        </div>
    );
};


/** Main Component */
const EditCategory: React.FC = () => {
    const {categories} = useSelector((state: any) => (state.categories));
    const benchmarks = useSelector((state: any) => (state.benchmarks));
    const {categoryId} = useParams();
    const history = useHistory();

    /** Manage modal */
    const [modal, setModal] = useState<{ questionId: string, benchmarkId?: string }>();

    return (
        <div className={'edit-category'}>
            <h3>Edit Category {categoryId &&
            <EditTitle categoryId={categoryId} categoryTitle={categories[categoryId]?.title}/>}</h3>
            <BaseModal visible={!!modal} toggle={() => setModal(undefined)}>
                <BenchmarkModal onClose={() => setModal(undefined)} categoryId={categoryId} {...modal}/>
            </BaseModal>
            <SimpleDropdown
                name={'primaryCategory'}
                label={'Category'}
                value={categoryId}
                options={Object.keys(categories).map((key) => ({
                    value: key,
                    description: categories[key].title,
                    img: getCategoryIcon(categories[key].identifier).standard,
                }))}
                onChange={(value: string) => history.push(`/profile/edit-category/${value}`)}
                warning={false}/>
            {categoryId === undefined || categories[`${categoryId}`] === undefined ?
                <p className={'no-category'}>Select a category to continue.</p> : (
                    <div>
                        <Navigator/>
                        {benchmarks.benchmarks && Object.keys(benchmarks.benchmarks).map((key: string, index: number) => (
                            // @ts-ignore // The categoryId will never be undefined, the check is above
                            <CriteriaGroup key={key} index={index} categoryId={categoryId} questionId={key}
                                           benchmarks={benchmarks.benchmarks[key]} onClick={(val) => setModal(val)}/>
                        ))}
                    </div>
                )}
        </div>
    );
};

export default EditCategory;
