export {default as LoginModal} from './LoginModal';
export {default as EditUserModal} from './EditUserModal';
export {default as ForgotModal} from './ForgotModal';
export {default as NewUserModal} from './NewUserModal';
export {default as ResetModal} from './ResetModal';


export enum PanelState {
    LOGIN = 'login',
    REGISTER = 'register',
    FORGOT = 'forgot',
}

export interface IAuthModalProps {
    setPanel: (panel: PanelState) => void;
    close: () => void;
}
