import {AxiosInstance, AxiosResponse} from 'axios';

const PATH = 'country';

export class CountriesService {
    private readonly axiosInstance: AxiosInstance;
    constructor(axios: AxiosInstance) {
        this.axiosInstance = axios;
    }

    //================================
    // Endpoints
    //================================

    /**
     * Get all countries
     */
    public all = async (): Promise<AxiosResponse> => {
        return await this.axiosInstance.get(`/${PATH}/all`);
    };
}
