import React from 'react';
import './TextInput.css';

interface ITextAreaProps {
    label?: string,
    name?: string,
    value?: string,
    onChange?: any,
    onBlur?: any,
    warning?: any
    autocomplete?: string;
}

const TextArea: React.FC<ITextAreaProps> = (
    {
        label,
        name,
        value,
        onChange,
        onBlur,
        warning,
        autocomplete = 'on',
    }) => {
    return (
        <textarea
            className={`text-input ${warning ? 'warning' : ''}`}
            name={name}
            value={value}
            placeholder={label}
            onChange={onChange}
            onBlur={onBlur}
            autoComplete={autocomplete}/>
    );
};
export default TextArea;
