import React from 'react';
import {IPagination} from '../../../helpers/interfaces';


interface ISearchPaginationProps {
    pagination: IPagination | null;
    onChange: (val: number) => Promise<void> | void;
}

const SearchPagination: React.FC<ISearchPaginationProps> = ({pagination, onChange}) => {


    if (pagination !== null) {
        if (pagination.pageCount > 1) {
            // Note: for easy of reading of the url, pagination starts at 1 not 0
            const nonZeroIndex = pagination.pageIndex + 1;
            return (
                <div>
                    <p>

                        {(nonZeroIndex !== 1) && <>
                            <button style={{color: 'var(--grey)', fontWeight: 300, paddingRight: 4}} onClick={() => onChange(1)}>{'1'} </button>
                            <button onClick={() => onChange(nonZeroIndex - 1)}>{'<'}</button>
                        </>}
                        {nonZeroIndex}/{pagination.pageCount}
                        {(nonZeroIndex < pagination.pageCount) &&
                        <button onClick={() => onChange(nonZeroIndex + 1)}>{'>'}</button>}
                    </p>
                </div>
            );
        }
    }
    return null;
};
export default SearchPagination;
