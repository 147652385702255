import {AxiosInstance, AxiosResponse} from 'axios';
import {destroyToken, getToken, storeToken} from '../persist';

const PATH = 'auth';

export class AuthService {
    private readonly axiosInstance: AxiosInstance;
    constructor(axios: AxiosInstance) {
        this.axiosInstance = axios;
        this.setHeaders();
    }

    //================================
    // Private
    //================================

    /**
     * Sets the authorization header if existing token is valid
     */
    private setHeaders = (): void => {
        if (this.isTokenValid()) {
            this.axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + getToken();
        } else {
            this.axiosInstance.defaults.headers.common['Authorization'] = null;
        }
    };

    /**
     * Determines if provided token is valid
     */
    private checkTokenAge = (token: string): boolean => {
        if (token) {
            const payload = token.split('.')[1];
            if (payload) {
                const decoded: any = JSON.parse(atob(payload));
                return (Date.now() / 1000 < decoded.exp);
            }
        }
        return false;
    };

    /**
     * Updates token to provided token
     */
    private updateToken = (token: any): void => {
        storeToken(token);
        this.setHeaders();
    };


    //================================
    // Utility
    //================================

    /**
     * Logout user by removing their credentials and resetting the headers
     */
    public logout = (): void => {
        destroyToken();
        this.setHeaders();
    };

    /**
     * Checks if token exists in local storage and is valid
     */
    public isTokenValid = (): boolean => {
        const token = getToken();
        if (token) {
            return this.checkTokenAge(token);
        }
        return false;
    };

    //================================
    // Endpoints
    //================================

    /**
     * Logs user in
     */
    public login = async (email: string, password: string): Promise<AxiosResponse> => {
        const response = await this.axiosInstance.post(`/${PATH}/login`, {email, password});
        this.updateToken(response.data.token);
        return response;
    };

    /**
     * Extend user session
     */
    public extend = async (): Promise<AxiosResponse> => {
        const response = await this.axiosInstance.post(`/${PATH}/extend`);
        this.updateToken(response.data.token);
        return response;
    };

    /**
     * Forgot password reset request
     */
    public forgot = async (email: string): Promise<AxiosResponse> => {
        return await this.axiosInstance.post(`/${PATH}/password-forgot`, {email});
    };

    /**
     * Reset password with token
     */
    public reset = async (email: string, token: string, password: string): Promise<AxiosResponse> => {
        const response = await this.axiosInstance.post(`/${PATH}/password-reset`, {email, token, password});
        this.updateToken(response.data.token);
        return response;
    };
}

