import globe from './globe.png';
import calendar from './calendar.png';
import hash from './hash.png';
import tick from './tick.png';

export default {
    globe,
    calendar,
    hash,
    tick,
};
