import React, {useState} from 'react';
import './AddCategory.css';
import {Form, Formik} from 'formik';
import {TextInput} from '../../../common';
import {AlertMessage} from '../../../layout';
import {Spinner} from '../../../common/spinner';
import * as Yup from 'yup';
import {useDispatch, useSelector} from 'react-redux';
import {getApiClient} from '../../../../api';
import {CategoryActions} from '../../../../state/actions';
import { useHistory } from 'react-router-dom';

type Schema = { category: string };
const initial: Schema = {category: ''};
const AddCategory: React.FC = () => {
    const {loading} = useSelector((state: any) => (state.auth));
    const dispatch = useDispatch();
    const history = useHistory();
    const [formError, setError] = useState(null);
    const [formSuccess, setSuccess] = useState<string | null>(null);

    const handle = async (values: Schema) => {
        setError(null);
        setSuccess(null);
        try {
            const {category} = values;
            if (!category) {
                throw new Error('Form failed.')
            }
            const response = await getApiClient().categoriesService.create(category);
            setSuccess('Successfully created category');
            dispatch(CategoryActions.get());
            history.push(`/profile/edit-category/${response.data.id}`);
        } catch (e) {
            setError(e);
        }
    };

    const validation = Yup.object().shape({
        category: Yup.string()
            .required('Required'),
    });


    return (
        <div className={'add-category'}>
            <h3>Add New Category</h3>
            <p>Adds a new category to the Gender Law Index. {'\n\n'}Contact support to add in a custom icon.{'\n'}(Note:
                This action cannot be undone without directly modifying the database)</p>
            <Formik
                initialValues={initial}
                validationSchema={validation}
                onSubmit={handle}>
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                  }) =>
                    <Form onSubmit={handleSubmit}>
                        <TextInput
                            name={'category'}
                            label={'Category'}
                            type={'text'}
                            warning={(errors.category && touched.category) && errors.category}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.category}/>
                        {values.category !== initial.category &&
                        <div className='button-wrapper'>
                            <button className={'submit'} disabled={isSubmitting || loading}>
                                {(isSubmitting || loading) && <Spinner/>}Add New Category
                            </button>
                        </div>
                        }
                        <div className={'warning-space'}>
                            <AlertMessage className={'error'} message={formError}/>
                        </div>
                        <div className={'warning-space'}>
                            <AlertMessage className={'success'} message={formSuccess}/>
                        </div>
                    </Form>
                }
            </Formik>
        </div>
    );
};
export default AddCategory;
