import {IRouteConfig} from '../../helpers/interfaces';
import Benchmarks from './Benchmarks';

const PREFIX = '/benchmarks';

const routes: IRouteConfig[] = [
    {
        prefix: PREFIX,
        path: '',
        component: Benchmarks,
    },
];

export default routes;
