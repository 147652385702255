import React from "react";
import SearchCategoryFilter from "../../components/search/SearchFilterList";
import './search.css'
import {withRouter, RouteComponentProps} from 'react-router-dom';
import * as queryString from 'querystring';
import {SearchBar} from '../../components/search/bar';
import {getApiClient} from '../../api';
import SearchCountryList from '../../components/search/SearchCountryList';
import {SearchResults, SearchPagination} from '../../components/search/results';
import {IPagination} from '../../helpers/interfaces';
import {SEARCH_PREFIX} from './index';
import { AlertMessage } from "../../components/layout";
import constants from '../../constants';
import { IDropdownOptions } from "../../components/common/dropdown/BaseDropdown";
import { SimpleDropdown } from "../../components/common/dropdown";

/**
 * Container for searching of laws
 */

interface ISearchProps extends RouteComponentProps {
}

interface ISearchState {
    laws: any[],
    pagination: IPagination | null,
    loading: boolean,
    error: any,
    viewFilter: boolean,
    search: {
        country: string,
        category: string,
        query: string,
        page: string,
        sortingName: string,
        sortingDir: string,
    }
}

const sortingNameOptions: IDropdownOptions[] = [
    {
        value: 'modified',
        description: 'Last Updated',
    },
    {
        value: 'score',
        description: 'Score',
    },
    {
        value: 'year',
        description: 'Year',
    },
    {
        value: 'country',
        description: 'Country',
    },
    {
        value: 'title',
        description: 'Title',
    },
];
const sortingDirectionOptions: IDropdownOptions[] = [
    {
        value: 'desc',
        description: 'Descending',
    },
    {
        value: 'asc',
        description: 'Ascending',
    },
];

class Search extends React.Component<ISearchProps, ISearchState> {
    constructor(props: ISearchProps) {
        super(props);

        this.state = {
            laws: [],
            pagination: null,
            loading: false,
            error: null,
            viewFilter: false,
            search: {
                country: '',
                category: '',
                query: '',
                page: '',
                sortingName: sortingNameOptions[0].value,
                sortingDir: sortingDirectionOptions[0].value,
            }
        }
    }


    async componentDidMount(): Promise<void> {
        document.title = `${constants.TITLE} - Search`;
        const search = new URLSearchParams(this.props.location.search);
        this.setState({
            search: {
                country: search.get('country') ?? this.state.search.country,
                category: search.get('category') ?? this.state.search.category,
                query: search.get('q') ?? this.state.search.query,
                page: search.get('pg') ?? this.state.search.page,
                sortingName: search.get('sorting') ?? this.state.search.sortingName,
                sortingDir: search.get('dir') ?? this.state.search.sortingDir,
            }
        }, async () => {
            await this.search(true);
        });

    }

    pushStateToParams(): void {
        const {country, category, query, page, sortingName, sortingDir} = this.state.search;

        if ([country, category, query, page, sortingName, sortingDir].every(v => v === '')) {
            this.props.history.push(`/${SEARCH_PREFIX}`);
        } else {
            const val = Object.assign({},
                country && {country},
                category && {category},
                query && {q: query},
                page && {pg: page},
                sortingName && {sorting: sortingName},
                sortingDir && {dir: sortingDir},
            );
            this.props.history.push(`/${SEARCH_PREFIX}?` + queryString.stringify(val));
        }
    }

    async search(first?: boolean): Promise<void> {
        if (!first) {
            this.pushStateToParams();
        }
        if (!this.state.loading) {
            this.setState({loading: true, error: null});
            try {
                const search = {...this.state.search}; // To prevent copy with reference
                if (this.state.search.category === 'pending' || this.state.search.category === 'blog') {
                    search.category = '';
                }
                const laws = await getApiClient().lawsService.all(search, this.state.search.category);
                window.scrollTo(0, 0);
                this.setState({laws: laws.data.items, pagination: laws.data.pageInfo, loading: false});
            } catch (e) {
                this.setState({error: e, loading: false});
            }
        }

    };

    async clickCategory(categoryId: string): Promise<void> {
        this.setState({search: {...this.state.search, category: categoryId}}, async () => {
            await this.search();
        });
    }

    async clickCountry(countryId: string): Promise<void> {
        this.setState({search: {...this.state.search, country: countryId}}, async () => {
            await this.search();
        });
    }

    changeParameter(name: keyof ISearchState["search"]) {
        return async (value: string | number) => {
            this.setState({search: {...this.state.search, [name]: `${value}`}}, async () => {
                await this.search();
            });
        };
    };

    setFilter(status: boolean): void {
        this.setState({viewFilter: status});
    }

    render(): React.ReactElement {
        return (
            <div className={"search content-sizing"}>
                <AlertMessage message={this.state.error}/>
                <div className={"search-content"}>
                    <h2>Search laws</h2>
                    <div className={`filter-list ${this.state.viewFilter ? '' : 'filters-hidden'}`}>
                        <h3>Filter by</h3>
                        <SearchCategoryFilter
                            value={this.state.search.category}
                            onSubmit={async (id: string) => {this.setFilter(false); await this.clickCategory(id)}}/>
                        <SearchCountryList
                            value={this.state.search.country}
                            onSubmit={async (id: string) => {this.setFilter(false); await this.clickCountry(id)}}/>
                    </div>
                    <div className="search-bar-row">
                        <SearchBar
                            value={this.state.search.query}
                            onChange={(query: string) => this.setState({search: {...this.state.search, query}})}
                            onSubmit={() => this.search()}
                            onFilterClick={() => this.setFilter(!this.state.viewFilter)}
                        />
                        <div className="search-sorting">
                            Order By
                            <div className="search-sorting-dropdowns">
                                <SimpleDropdown
                                    value={this.state.search.sortingName}
                                    options={sortingNameOptions}
                                    onChange={this.changeParameter('sortingName')}
                                />
                                <SimpleDropdown
                                    value={this.state.search.sortingDir}
                                    options={sortingDirectionOptions}
                                    onChange={this.changeParameter('sortingDir')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="result-panel">
                        <SearchResults
                            laws={this.state.laws}
                            loading={this.state.loading}
                            notFoundText={'We didn\'t find that one! Try some different keywords.'}/>
                        <SearchPagination
                            pagination={this.state.pagination}
                            onChange={this.changeParameter('page')}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Search);
