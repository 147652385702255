import React, {useEffect, useState} from 'react';
import TextEditor, {compositeDecorator} from './TextEditor';
import {Formik, FormikHelpers} from 'formik';
import {convertFromRaw, convertToRaw, EditorState, RawDraftContentState, ContentState} from 'draft-js';
import * as Yup from 'yup';
import {TextInput} from '../common';
import './Blog.css';
import {IBlog, IUser} from '../../helpers/interfaces';
import {Spinner} from '../common/spinner';
import {AlertMessage} from '../layout';
import {Persist} from '../../helpers/formik-persist';
import BlogComments, {BlogCommentObject} from './BlogComments';
import {Modal} from '../common/modal';
import {getApiClient} from '../../api';

export type BlogFormSchema = {
    title: string;
    author: string;
    comments: BlogCommentObject[];
}

interface IBlogFormProps {
    lawId: string;
    onSubmit: (values: BlogFormSchema & { blog: RawDraftContentState }, cb?: (error?: any) => void) => Promise<void>;
    user?: IUser;
    previous?: IBlog;
}


const BlogForm: React.FC<IBlogFormProps> = ({lawId, onSubmit, user, previous}) => {
    const [editorState, setEditorState] = useState<EditorState>();
    const [error, setError] = useState();

    const handleDelete = async () => {
        setError(undefined);
        try {
            await getApiClient().blogsService.disable(lawId);
            sessionStorage.removeItem(`blog-${lawId}-editor`); // Clear persistent editor
            sessionStorage.removeItem(`blog-${lawId}`); // Clear persistent form
            window.location.reload();
        } catch (e) {
            setError(error);
        }
    };

    /** Handle submission */
    const handle = async (values: BlogFormSchema, {setSubmitting}: FormikHelpers<any>) => {
        setError(undefined);
        if (editorState) {
            const blog = convertToRaw(editorState.getCurrentContent());
            await onSubmit({...values, blog}, (e) => {
                setSubmitting(false);
                if (!e) {
                    sessionStorage.removeItem(`blog-${lawId}-editor`); // Clear persistent editor
                    sessionStorage.removeItem(`blog-${lawId}`); // Clear persistent form
                } else {
                    setError(e);
                }
            });
        }
    };

    /** Load persisted data if it exists or initialize an empty editor */
    useEffect(() => {
        const restored = sessionStorage.getItem(`blog-${lawId}-editor`);
        if (restored) { // If there is a blog in memory
            const state = EditorState.createWithContent(
                convertFromRaw(JSON.parse(restored)),
                compositeDecorator,
            );
            setEditorState(state);
        } else if (previous) { // If there is a previous blog
            const state = EditorState.createWithContent(
                convertFromRaw(previous.blog),
                compositeDecorator,
            );
            setEditorState(state);
        } else {
            const state = EditorState.createWithContent(ContentState.createFromText(' '), compositeDecorator);
            setEditorState(state);
        }
    }, [lawId, previous]);

    /** Persist editor state to local storage  */
    useEffect(() => {
        if (editorState) {
            sessionStorage.setItem(`blog-${lawId}-editor`, JSON.stringify(convertToRaw(editorState.getCurrentContent())));
        }
    }, [editorState, lawId, previous]);

    /** Initial form schema */
    const initial: BlogFormSchema = {
        title: previous ? previous.title : '',
        author: previous ? previous.author : ((user) ? `${user.firstName} ${user.lastName}` : ''),
        comments: previous ? previous.comments : []
    };

    /** Form validation */
    const validation = Yup.object().shape({
        title: Yup.string().required('Required'),
        author: Yup.string().required('Required'),
        comments: Yup.array().of(Yup.object().shape({
            author: Yup.string().required('Required'),
            body: Yup.string().required('Required'),
        })),
    });

    if (!editorState) {
        return <p>loading...</p>
    }
    return (
        <div className={'blog'}>
            <Formik
                initialValues={initial}
                validationSchema={validation}
                onSubmit={handle}>
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                      isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit} onKeyPress={e => {
                        if (e.key === 'Enter') e.preventDefault();
                    }}>
                        <div className={'spacing'}>
                            <TextInput
                                label={'Title'}
                                name={'title'}
                                key={'title'}
                                warning={(errors.title && touched.title) && errors.title}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title}/>
                        </div>
                        <div className={'spacing'}>
                            <TextInput
                                label={'Author'}
                                name={'author'}
                                key={'author'}
                                warning={(errors.author && touched.author) && errors.author}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.author}/>
                        </div>
                        <div className={'spacing'}>
                            <TextEditor initial={editorState}
                                        onChange={(val) => setFieldValue('blog', setEditorState(val))}/>
                        </div>
                        <div className={'spacing'}>
                            <BlogComments
                                name={'comments'}
                                value={values.comments}
                                setFieldValue={setFieldValue}
                                warning={(errors.comments && touched.comments) && errors.comments}/>
                        </div>
                        <AlertMessage className={'error'} title={'Failed to send blog'} message={error}/>
                        <div className={'submit-spacer'}>
                            <div className={'spacing'}>
                                <button type={'submit'} className={'submit'}>{(isSubmitting) && <Spinner/>}Make Public
                                </button>
                            </div>
                            <div className={'spacing'}>
                                {previous && (
                                    <Modal trigger={<button className={'submit red'}>Delete Blog</button>} size={'small'}>
                                        <>
                                            <h3>Delete Blog</h3>
                                            <h5>{values.title}</h5>
                                            <button
                                                className={'submit red'}
                                                onClick={async (e) => {
                                                    e.preventDefault(); await handleDelete();
                                                }}>Are you sure you want to delete this blog?
                                            </button>
                                            <AlertMessage message={error}/>
                                        </>
                                    </Modal>
                                )}
                            </div>
                        </div>
                        <Persist name={`blog-${lawId}`} isSessionStorage/>
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default BlogForm;
