import {IRouteConfig} from '../../helpers/interfaces';
import Login from './Login';
import Profile from './Profile';
import {UserRole} from '../../helpers/enums';

// const PREFIX = '/auth';

const routes: IRouteConfig[] = [
    {
        path: '/login',
        component: Login,
    },
    {
        path: [
            '/profile/edit-law/:lawId',
            '/profile/edit-category/:categoryId',
            '/profile/my-evaluations',
            '/profile/new-law',
            '/profile/new-category',
            '/profile/user-management',
            '/profile'
        ],
        component: Profile,
        requiredRole: UserRole.EVALUATOR,
    },
];

export default routes;
