import React, { useState } from 'react';
import './WomenLeaders.css';
import {useSelector} from 'react-redux';
import Markdown from '../../components/common/Markdown';
import {images} from '../../assets';
import SearchIndex from '../../components/home/SearchIndex';
import { IState } from '../../state/reducers';
import { ICountryContent } from '../../state/reducers/staticContent';
import { BaseModal } from '../../components/common/modal';
import { Link } from 'react-router-dom';


interface ILeaderBlockProps {
    title: string;
    image: string;
    onReadMore?(): void;
}
const LeaderBlock: React.FC<ILeaderBlockProps> = ({title, image, onReadMore}) => (
    <button className="leader-component" onClick={onReadMore}>
        <img src={image} alt='title' className="leader-image"/>
        <h4>{title}</h4>
        <button className="read-more">
            Read More
        </button>
    </button>
);

type LeaderName = keyof ICountryContent['leaders']['sections'];
interface ILeaderModalProps {
    leader?: LeaderName;
    onClose(): void;
}

const LeaderModal: React.FC<ILeaderModalProps> = (props) => {
    const {
        leader,
        onClose,
    } = props;

    const leaderContent: ICountryContent = useSelector((state: IState) => (state.content.country));
    const {countries} = useSelector((state: IState) => (state.countries));

    const findCountry = (countryIdentifier: string) => {
        const foundCountry = Object.entries(countries).find(([_, countryData]) => {
            return countryData.identifier === countryIdentifier;
        });
        if(!foundCountry) {
            return undefined;
        }
        return {
            ...foundCountry[1],
            id: foundCountry[0],
        };
    }

    if(!leader) {
        return null;
    }

    const leaderSection = leaderContent.leaders.sections[leader];
    const country = findCountry(leaderSection.country);

    return (
        <BaseModal visible={true} toggle={onClose} size="medium">
            <div className="leader-modal">
                <img src={images[leader]} alt='title' className="leader-image"/>
                <div className="leader-modal-text">
                    <h4>{leaderContent.leaders.sections[leader].title}</h4>
                    <div>{leaderContent.leaders.sections[leader].content}</div>
                    {country && (
                        <Link to={`/laws?country=${country?.id}`} className="leader-modal-more">
                            See laws from {country?.title}
                        </Link>
                    )}
                </div>
            </div>
        </BaseModal>
    );
}

interface ICountryProps {}

const WomenLeaders: React.FC<ICountryProps> = () => {
    const leaderContent: ICountryContent = useSelector((state: IState) => (state.content.country));
    const {categories} = useSelector((state: IState) => (state.categories));
    const [modalLeader, setModalLeader] = useState<LeaderName>();

    if (!leaderContent) {
        return <div/>;
    }

    return (
        <div className={'leaders-page content-sizing'}>
            <LeaderModal
                leader={modalLeader}
                onClose={() => {setModalLeader(undefined)}}
            />
            <h2>Women Leaders</h2>
            <h3>{leaderContent.leaders.title}</h3>
            <div className={'shortened-content'}>
                <Markdown>{leaderContent.leaders.introduction}</Markdown>
            </div>
            <div className="leaders">
                <LeaderBlock
                    image={images.julia}
                    {...leaderContent.leaders.sections.julia}
                    onReadMore={() => setModalLeader('julia')}
                />
                <LeaderBlock
                    image={images.megawati}
                    {...leaderContent.leaders.sections.megawati}
                    onReadMore={() => setModalLeader('megawati')}
                />
                <LeaderBlock
                    image={images.gloria}
                    {...leaderContent.leaders.sections.gloria}
                    onReadMore={() => setModalLeader('gloria')}
                />
                <LeaderBlock
                    image={images.maria}
                    {...leaderContent.leaders.sections.maria}
                    onReadMore={() => setModalLeader('maria')}
                />
                <LeaderBlock
                    image={images.chandrika}
                    {...leaderContent.leaders.sections.chandrika}
                    onReadMore={() => setModalLeader('chandrika')}
                />
            </div>
            <h3>{leaderContent.info.title}</h3>
            <Markdown>{leaderContent.info.sections[0]}</Markdown>
            <SearchIndex indexes={categories} options="category"/>
            <Markdown>{leaderContent.info.sections[1]}</Markdown>

        </div>
    );
};

export default WomenLeaders;
