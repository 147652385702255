import React from 'react';
import {convertFromRaw, Editor, EditorState} from 'draft-js';
import 'draft-js/dist/Draft.css';
import {compositeDecorator} from './TextEditor';
import {CustomBlockStyling} from './Controls';
import {IBlog} from '../../helpers/interfaces';
import './Blog.css';
import './Text.css';
import {getDate} from '../../helpers';

interface ITextViewerProps {
    blog: IBlog;
}
const TextViewer: React.FC<ITextViewerProps> = ({blog}) => {
    const contentState = convertFromRaw(blog.blog);
    const editorState = EditorState.createWithContent(contentState, compositeDecorator);

    return (
        <div className={'blog'}>
            <h3 className={'title'}>{blog.title}</h3>
            <h4 className={'subtitle'}>{blog.author} <span className={'date'}>{getDate(blog.createdDate)}</span></h4>
            <div className={'viewer'}>
                <Editor
                    onChange={() => null}
                    blockStyleFn={CustomBlockStyling}
                    editorState={editorState}
                    readOnly/>
            </div>
            {(blog.comments && blog.comments.length !== 0) && (
            <div className={'view-comments'}>
                {blog.comments.map((comment, index) => (
                    <div key={`${index} ${comment.author}`} className={'single-comment'}>
                        <h4>{comment.author}</h4>
                        <p>{comment.body}</p>
                    </div>
                ))}
            </div>
            )}
        </div>
    );
};
export default TextViewer;
