import React from 'react';
import styled from 'styled-components';

const Div = styled.div`
    margin: 8px;
`;

const Button = styled.button`
    width: 100%; 
    box-sizing: border-box;
    font-size: 1em;
    padding: 8px;
    border-radius: 4px;
    outline: none;
    background-color: deepskyblue;
    color: white;
    transition: background-color .1s ease;
    :disabled {
      color: grey;
      background-color: lightgrey;
    }
    :active {
      background-color: cornflowerblue;
    }
`;

interface ITextInput {children: any, disabled: boolean}

export default ({children, disabled}: ITextInput) => (
    <Div>
        <Button type={'submit'} disabled={disabled}>{children}</Button>
    </Div>
);
