import React, {useCallback, useEffect, useState} from 'react';
import {Form, Formik} from 'formik';
import {AlertMessage} from '../../layout';
import {TextInput} from '../../common';
import './AuthModal.css';
import {Spinner} from '../../common/spinner';
import * as Yup from 'yup';
import {BaseModal} from '../../common/modal';
import {useLocation, useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {AuthActions} from '../../../state/actions';
import {getApiClient} from '../../../api';

type Schema = { email: string, password: string };
const ResetModal: React.FC = () => {
    const [error, setError] = useState<any>(null);
    const [token, setToken] = useState<string | null>(null);
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const handle = async (values: Schema): Promise<void> => {
        if (token === null) {
            setError('No token provided in URL please check your reset email for the valid link.');
        } else {
            try {
                //reset
                await getApiClient().authService.reset(values.email, token, values.password);
                // If reset is successful the token will be updated. Dispatch extend is called to populate user
                dispatch(AuthActions.extend());
                history.push('/');
            } catch (e) {
                setError(e);
            }
        }
    };

    const getToken = async () => {
        const search = new URLSearchParams(location.search);
        const t = search.get('token');
        await setToken(t);
        if (t === null) {
            setError('No token provided in URL please check your reset email for the valid link.')
        }
    };
    const memoizedCallback = useCallback(getToken, []);
    useEffect(() => {
        memoizedCallback();
    }, [memoizedCallback]);

    const getInitial = (): Schema => {
        const search = new URLSearchParams(location.search);
        return {
            email: search.get('email') || '',
            password: '',
        }
    };

    const validation = Yup.object().shape({
        email: Yup.string()
            .email()
            .required('Required'),
        password: Yup.string()
            .required('Required'),
    });

    return (
        <BaseModal visible={true} size={'small'} toggle={() => history.push('/')}>
            <div className='auth-form'>
                <div className={'header-space'}>
                    <h3>Enter a new password</h3>
                </div>
                <Formik
                    initialValues={getInitial()}
                    validationSchema={validation}
                    onSubmit={handle}>
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                      }) =>
                        <Form onSubmit={handleSubmit} autoComplete={'off'}>
                            <div className={'input-space'}>
                                <TextInput
                                    name={'email'}
                                    label={'Email'}
                                    type={'text'}
                                    warning={errors.email && touched.email && errors.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    autocomplete={'off'}/>
                            </div>
                            <div className={'input-space'}>
                                <TextInput
                                    name={'password'}
                                    label={'Password'}
                                    type={'password'}
                                    warning={errors.password && touched.password && errors.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    autocomplete={'off'}/>
                            </div>
                            <div className={'warning-space'}>
                                <AlertMessage className={'error'} message={error}/>
                            </div>
                            <button
                                type='submit'
                                className={'submit'}
                                disabled={isSubmitting}
                                onClick={() => handleSubmit()}>
                                {(isSubmitting) && <Spinner/>}Update
                            </button>
                            <div className={'links-space'}/>
                        </Form>
                    }
                </Formik>
            </div>
        </BaseModal>
    );
};
export default ResetModal;
