import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ProfileSidebar } from '../../components/profile/sidebar';
import { ProfileDetails } from '../../components/profile/details';
import './Profile.css';
import constants from '../../constants';

interface IProfileProps extends RouteComponentProps {}
class Logout extends React.Component<IProfileProps> {
    componentDidMount(): void {
        document.title = `${constants.TITLE} - Profile`;
    }

    render(): React.ReactElement {
        return (
            <div className='profile content-sizing'>
                <h2>My Profile</h2>
                <div className='content'>
                    <div className={'sidebar'}><ProfileSidebar/></div>
                    <ProfileDetails/>
                </div>
            </div>
        );
    }
}

export default withRouter(Logout);
