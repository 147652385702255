import React from 'react';
import {CircleIcon} from '../../components/common/circle';
import constants from '../../constants';
import {Legend} from '../../components/laws/analysis';
import {IScoringContent} from '../../state/reducers/staticContent';
import { connect } from 'react-redux';
import Placeholder from '../../components/layout/Placeholder';
import {AlertMessage} from '../../components/layout';
import './Scoring.css';
import { ScorePill } from '../../components/common/score-pill';
import Markdown from '../../components/common/Markdown';
import {Citations} from '../../components/common/citations';

/**
 * Container for the scoring page
 */
interface IScoringProps {
    content?: IScoringContent;
    loading: boolean;
}
class Scoring extends React.Component<IScoringProps> {

    componentDidMount(): void {
        document.title = `${constants.TITLE} - Scoring`;
    }

    render(): React.ReactElement {
        const {content, loading} = this.props;

        if (loading) {
            return <Placeholder loading={loading}/>;
        }
        if (!content) {
            return <AlertMessage title={'Something went wrong'} message={'Content was not able to be loaded.'}/>
        }
        return (
            <div className={'scoring content-sizing'}>
                <h2>{content.title}</h2>
                <div className={'scale'}>
                    <div className={'subheading'}>
                        <h3>{content.subheader}</h3>
                        <p className={'sub-content'}>{content.subcontent}</p>
                        <div className={'legend-container'}>
                            <Legend/>
                        </div>
                    </div>
                    <ExampleSection
                        customTitle={<ScaleHeading {...constants.scale.regressive}/>}
                        {...content.scale.regressive}/>
                    <ExampleSection
                        customTitle={<ScaleHeading {...constants.scale.blind}/>}
                        {...content.scale.blind}/>
                    <ExampleSection
                        customTitle={<ScaleHeading {...constants.scale.neutral}/>}
                        {...content.scale.neutral}/>
                    <ExampleSection
                        customTitle={<ScaleHeading {...constants.scale.responsive}/>}
                        {...content.scale.responsive}/>
                    <ExampleSection
                        customTitle={<ScaleHeading {...constants.scale.inconclusiveOrNA}/>}
                        {...content.scale.inconclusiveOrNA}/>
                </div>
                <div className={'standards'}>
                    <h3>{content.standard.title}</h3>
                    <p>{content.standard.content}</p>
                    <div className={'standards-list'}>
                        <ul>
                            {[5, 4, 3, 2, 1, -1].map((val) => (
                                <li key={val}><div className={'standard-content'}><ScorePill score={val}/></div></li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className={'disclaimer'}>
                    <ExampleSection {...content.disclaimer}/>
                </div>
                <Citations citations={content.citations}/>
            </div>
        );
    }
}

/** Heading with circle */
interface IScaleHeadingProps {
    color: string;
    description: string;
}
const ScaleHeading: React.FC<IScaleHeadingProps> = ({color, description}) => (
    <div className={'scale-heading'}>
        <CircleIcon color={color} diameter={12}/>
        <h4>{description}</h4>
    </div>
);

/** JSX object for an example */
interface IExampleSectionProps {
    customTitle?: JSX.Element;
    title?: string;
    content: string;
    example: string;
}
const ExampleSection: React.FC<IExampleSectionProps> = ({customTitle, title, content, example}) => (
    <div className={'example-section'}>
        {(!!customTitle) ? customTitle : <h3>{title}</h3>}
        <Markdown>{content}</Markdown>
        <h4 className={'example'}>Example</h4>
        <Markdown className={'example-content'}>{example}</Markdown>
    </div>
);

const mapStateToProps = (state : any ) =>{
    return {
        content: state.content.scoring,
        loading: state.content.loading,
    }
};

export default connect(mapStateToProps)(Scoring);
