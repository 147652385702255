import React from "react";
import {Text, View} from "@react-pdf/renderer";
import {commonStylesheet} from "./EvaluationDocument";

interface ISummaryProps {
    summary: string;
}

export const Summary: React.FC<ISummaryProps> = ({summary}) => (
    <View style={commonStylesheet.text}>
        <Text style={commonStylesheet.title}>Summary</Text>
        <Text>{summary}</Text>
    </View>
);