import {useSelector} from "react-redux";
import {getApiClient} from "../../../api";
import ReactPDF from "@react-pdf/renderer";
import EvaluationDocument from "../pdf/EvaluationDocument";
import React, {useCallback, useState} from "react";
import {general} from "../../../assets";
import {ILaw} from "../../../helpers/interfaces";
import './DownloadLink.css';
import {BaseModal} from "../../common/modal";
import {Spinner} from "../../common/spinner";

interface IDownloadLinkProps {
    law: ILaw;
}

const DownloadLink: React.FC<IDownloadLinkProps> = (props) => {
    const {law} = props;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [modalShowing, setModalShowing] = useState(false);

    const {questions} = useSelector((state: any) => (state.questions));

    const downloadPDF = useCallback(async () => {
        if(loading) {
            return;
        }

        setLoading(true);
        setError(false);
        setModalShowing(true);

        try {
            const blog = await getApiClient().blogsService.get(law.id);
            const lawHeatmap = (await getApiClient().assessService.getHeatmap(law.id)).data;
            const evaluatorHeatmaps = await Promise.all(law.assessments.map(async (assessment) => {
                const heatmap = await getApiClient().assessService.getSingleScoreDetail(assessment.id);
                return heatmap.data;
            }));

            const pdfBlob = await ReactPDF.pdf((
                <EvaluationDocument
                    law={law}
                    blog={blog.status === 200 ? blog.data : undefined}
                    overallHeatmap={lawHeatmap}
                    evaluatorHeatmaps={evaluatorHeatmaps}
                    questions={questions}
                />
            )).toBlob();

            const objectUrl = URL.createObjectURL(pdfBlob);

            const toTwo = (val: number) => `${val}`.padStart(2, '0');
            const date = new Date();
            const dateString = `${date.getFullYear()}${toTwo(date.getMonth() + 1)}${toTwo(date.getDate())}`;

            const downloadLink = document.createElement('a');
            downloadLink.style.display = 'none';
            downloadLink.href = objectUrl;
            downloadLink.download = `Gender_Legislative_Index-${law.country.title.replace(/ /g, '_')}-${law.lawNumber}-${dateString}.pdf`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);

            URL.revokeObjectURL(objectUrl);

            setModalShowing(false);
        } catch(e) {
            console.error(e);
            setError(true);
        }

        setLoading(false);
    }, [law, questions, loading]);

    return (
        <>
            <button onClick={downloadPDF} className="download-button">
                <img src={general.document_icon} alt="document icon" />
                Download evaluation (PDF)
            </button>
            <BaseModal visible={modalShowing} toggle={() => setModalShowing(false)} canClose={!loading}>
                <div className="download-modal">
                    <div className="download-modal-title">
                        {error
                            ? 'Error generating PDF'
                            : 'Generating PDF'
                        }
                    </div>
                    {loading && <Spinner/>}
                    {error && (
                        <div className="download-error">
                            Something went wrong. Please try again later.
                        </div>
                    )}
                </div>
            </BaseModal>
        </>
    );
};

export default DownloadLink;
