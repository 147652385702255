import React from 'react';
import './AlertMessage.css';

/**
 * Error message box
 */
interface IAlertMessage {
    message?: any | string;
    title?: string;
    className?: '' | 'error' | 'success' | 'info';
}
const AlertMessage: React.FC<IAlertMessage> = ({message, title, className= 'error'}) => {
    const getTitle = (text: string) => text.replace(/^\w/, c => c.toUpperCase());

    const getMessage = () => {
        let errorMessage =  JSON.stringify(message, null, 2);
        if (message.response) {
            if (message.response.data) {
                if (message.response.data.message) {
                    errorMessage = message.response.data.message;
                } else {
                    errorMessage = message.response.data.error;
                }
            }
        } else {
            errorMessage = message.message;
        }
        // Note: errorMessage can be non-string in certain cases
        if (typeof errorMessage === 'string') {
            return errorMessage;
        }
        return JSON.stringify(errorMessage);
    }

    if (message) {
        return (
                <div className={`alert ${className}`}>
                <span className={'title'}>{title ? title : getTitle(className)}</span>
                {(typeof message !== 'string') ? (
                    <p className={'message'}>{getMessage()}</p>
                ) : (
                    <p className={'message'}>{message}</p>
                )}
            </div>
        );
    } else {
        return null;
    }
};

export default AlertMessage;
