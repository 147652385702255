import {createAction} from '../../helpers';
import { Dispatch } from 'redux';
import { QuestionsState } from '../reducers/questions';
import {getApiClient} from '../../api';

export const QUESTION_ACTIONS = {
    QUESTIONS_REQUEST: 'REQUEST_QUESTIONS',
    QUESTIONS_SUCCESS: 'QUESTIONS_SUCCESS',
    QUESTIONS_FAILURE: 'QUESTIONS_FAILURE',
};

const QuestionActions = {
    questionsRequest: () => createAction(QUESTION_ACTIONS.QUESTIONS_REQUEST),
    questionsSuccess: (questions: any) => createAction(QUESTION_ACTIONS.QUESTIONS_SUCCESS, questions),
    questionsFailure: (error: any) => createAction(QUESTION_ACTIONS.QUESTIONS_FAILURE, error)
};

const get = () => async (dispatch: Dispatch, state: QuestionsState) => {
    if (state.questions === undefined) {
        try {
            dispatch(QuestionActions.questionsRequest());
            const questions = await getApiClient().assessService.getQuestions();
            dispatch(QuestionActions.questionsSuccess(questions.data));
        } catch (e) {
            dispatch(QuestionActions.questionsFailure(e));
        }
    }
    // else do nothing, questions are already loaded
};


export default {
    get,
}

