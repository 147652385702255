import React from 'react';
import CategoryItem from './SearchCategoryItem';
import {useSelector} from 'react-redux';
import {getCountryIcon} from '../../helpers/get-icons';
import {general} from '../../assets';
import './SearchFilterList.css';

interface ISearchFilterProps {
    value: string,
    onSubmit: (countryId: string) => Promise<void>
}

const SearchCountryList: React.FC<ISearchFilterProps> = ({value, onSubmit}: ISearchFilterProps) => {
    const {countries} = useSelector((state: any) => (state.countries));

    return (
        <div className="search-country-list">
            <h4 className="search-filter-title">Country</h4>
            <div className="search-filter-items">
                <CategoryItem
                    key={'clear-country'}
                    onClick={() => onSubmit('')}
                    categoryItem={{
                        selected: '' === value,
                        icon_selected: general.globe_orange,
                        title: 'All Countries',
                        id: 'country-all',
                        icon: general.globe_grey,
                    }}/>
                {Object.keys(countries).map((key) =>
                    <CategoryItem
                        key={key}
                        onClick={() => onSubmit(key)}
                        categoryItem={{
                            selected: key === value,
                            icon_selected: getCountryIcon(countries[key].identifier),
                            title: countries[key].title,
                            id: key,
                            icon: getCountryIcon(countries[key].identifier),
                        }}/>)}
            </div>
        </div>
    );
}

export default SearchCountryList;
