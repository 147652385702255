import React from 'react';
import {ICategory, ICountry} from '../../../helpers/interfaces';
import './Breadcrumb.css';
import { Link } from 'react-router-dom';
import {getCountryIcon} from '../../../helpers/get-icons';

interface IBreadcrumbProps {
    country?: ICountry,
    category?: ICategory,
}

const Breadcrumb: React.FC<IBreadcrumbProps> = ({country, category}: IBreadcrumbProps) => {
    if (!country || !category) {
        return null;
    }
    return (
        <div className='breadcrumb'>
            <img src={getCountryIcon(country?.identifier)} alt='icon'/>
            <span>
                <Link to={`/laws?country=${country?.id}`}>{country?.title}</Link>
                &nbsp;&gt;&nbsp;
                <Link to={`/laws?country=${country?.id}&category=${category.id}`}>{category.title}</Link>
            </span>
        </div>
    );
};

export default Breadcrumb;
