import {AxiosInstance, AxiosResponse} from 'axios';

const PATH = 'categories';

export class CategoriesService {
    private readonly axiosInstance: AxiosInstance;
    constructor(axios: AxiosInstance) {
        this.axiosInstance = axios;
    }

    //================================
    // Endpoints
    //================================

    /**
     * Get all categories
     */
    public getCategories = async (): Promise<AxiosResponse> => {
        return await this.axiosInstance.get(`/${PATH}?pageSize=100`);
    };

    //================================
    // Private Endpoints
    //================================

    /**
     * Create a new category
     */
    public create = async (title: string): Promise<AxiosResponse> => {
        const identifier = title.toLowerCase().replace(/ /g,'');
        return await this.axiosInstance.post(`/${PATH}/create`, {title, identifier});
    };

    /**
     * Change category title
     */
    public update = async ({categoryId, title}: {categoryId: string, title: string}): Promise<AxiosResponse> => {
        const identifier = title.toLowerCase().replace(/ /g,'');
        return await this.axiosInstance.post(`/${PATH}/update/${categoryId}`, {title, identifier})
    };
}
