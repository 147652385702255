const KEY = 'token';

const storeToken = (key: string): void => {
    localStorage.setItem(KEY, key);
};

const getToken = (): string | null => {
    return localStorage.getItem(KEY);
};

const destroyToken = (): void => {
    localStorage.removeItem(KEY);
};

export {storeToken, getToken, destroyToken};
