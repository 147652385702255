import React, {useEffect} from 'react';
import RootRouter from './routes';
import {ErrorBoundary, TopBar, Footer} from './components/layout';
import {useDispatch} from 'react-redux';
import {
    AuthActions,
    StaticContentActions,
    CategoryActions,
    CountryActions,
    BenchmarksAction,
    QuestionActions
} from './state/actions';
import {getNavButton} from "./helpers/topbar-helpers";

const App: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(AuthActions.extend());
        dispatch(CategoryActions.get());
        dispatch(CountryActions.get());
        dispatch(StaticContentActions.getContent());
        dispatch(BenchmarksAction.get());
        dispatch(QuestionActions.get());
    });

    return (
        <div className='main-container'>
            <TopBar buttons={getNavButton()}/>
            <div className='main-content'>
                <ErrorBoundary>
                    <RootRouter/>
                </ErrorBoundary>
            </div>
            <Footer/>
        </div>
    );
};

export default App;
