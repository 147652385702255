import React from 'react';
import {general} from '../../../assets';
import {IQuestion} from '../../../helpers/interfaces';
import './ProgressIndicator.css';

interface IIconProps {
    onClick: () => void;
    index: number;
    touched: boolean;
    errors: boolean;
    active: boolean;
}
const Icon: React.FC<IIconProps> = ({onClick, index, touched, errors, active}) => {
    /** If no errors, display default */
    /** If active, display blue bubble */
    /** If touched and errors, display red bubble */
    /** If touched but no errors then the page has been validated, display tick */

    let stateClassName = '';
    if(touched) {
        if(errors) {
            stateClassName = 'warning';
        } else {
            stateClassName = 'complete';
        }
    }

    return (
        <div
            onMouseDown={e => {
                e.preventDefault();
                onClick()
            }}
            className={`${active ? 'active' : ''} ${stateClassName}`}
            title={`Question ${index + 1}`}
        >
            <span>{index + 1}</span>
        </div>
    );
};

interface ISubmitIconProps {
    onClick: () => void;
    touched: boolean;
    errors: boolean;
    active: boolean;
}
const SubmitIcon: React.FC<ISubmitIconProps> = ({onClick, touched, errors, active}) => {
    /** If no errors, display default */
    /** If active, display blue bubble */
    /** If touched and errors, display red bubble */
    return (
        <div
            onMouseDown={e => {
                e.preventDefault();
                onClick()
            }}
            className={`${active ? 'active' : ''} ${(errors && touched) ? 'warning' : ''}`}
            title="Submit"
        >
            <img className="inner-icon" src={general.speech} alt='Comment icon'/>
        </div>
    );
};


interface IProgressIndicatorProps {
    questions: IQuestion[];
    setPage: (page: number) => void;
    touched: any;
    errors: any;
    dirty: boolean;
    page: number;
}
const ProgressIndicator: React.FC<IProgressIndicatorProps> = ({questions, setPage, touched, dirty, errors, page}) => {
    const getTouched = (questionId: string) => {
        if (!!touched?.intention?.[questionId]) {
            return true;
        } else if (!!touched?.effect?.[questionId]) {
            return true;
        }
        return false;
    };

    const getErrors = (questionId: string) => {
        if (!!errors?.intention?.[questionId]) {
            return true;
        }
        if (!!errors?.effect?.[questionId]) {
            return true;
        }
        return false;
    };

    const submitPageIndex = questions.length;

    return (
        <div className={'progress-indicator'}>
            {questions.map((question, index) => (
                <Icon
                    key={index}
                    index={index}
                    onClick={() => setPage(index)}
                    active={page === index}
                    touched={getTouched(question.id)}
                    errors={getErrors(question.id)}
                />
            ))}
            <SubmitIcon
                onClick={() => setPage(submitPageIndex)}
                active={page === submitPageIndex}
                touched={touched.comments}
                errors={errors.comments}
            />
        </div>
    );
};
export default ProgressIndicator;
