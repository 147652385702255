import React, {useState} from 'react';
import './Toggle.css';
import {general} from '../../../assets';
import {useSelector} from "react-redux";
import {IState} from "../../../state/reducers";

type Variant = 'intention' | 'effect';

interface IToggleTabProps {
    variant: Variant,
    onClick(val: Variant): void,
    value: Variant,
}

const variantToNameMap: Record<Variant, string> = {
    intention: 'Intention',
    effect: 'Likely Effect',
};

export const ToggleTab: React.FC<IToggleTabProps> = (props) => {
    const {
        variant,
        onClick,
        value,
    } = props;

    const staticContent = useSelector((state: IState) => state.content)
    const [infoHovering, setInfoHovering] = useState(false);

    const classNames = ['toggle-tab'];
    const selected = value === variant;
    if(selected) {
        classNames.push('selected');
    }

    return (
        <div
            className={classNames.join(' ')}
            onClick={() => onClick(variant)}
        >
            {variantToNameMap[variant]}
            <div
                className="toggle-info-icon"
                onMouseEnter={() => setInfoHovering(true)}
                onMouseLeave={() => setInfoHovering(false)}
            >
                <img
                    src={selected ? general.info_orange : general.info_grey}
                    alt="information-icon"
                />
                {infoHovering && (
                    <div className="toggle-info-popup">
                        <div className="toggle-info-title">
                            {variantToNameMap[variant]}
                        </div>
                        {staticContent.scoring.disclaimer.content}
                    </div>
                )}
            </div>
        </div>
    );
};

interface IToggleProps {
    value: Variant;
    onChange(val: Variant): void;
}

const Toggle: React.FC<IToggleProps> = ({value, onChange}) => {
    return (
        <div className="toggle">
            <ToggleTab variant="intention" value={value} onClick={onChange}/>
            <ToggleTab variant="effect" value={value} onClick={onChange}/>
        </div>
    );
};

export default Toggle;

