import React, {useEffect, useState} from 'react';
import 'draft-js/dist/Draft.css';
import {
    CompositeDecorator,
    DraftHandleValue,
    Editor,
    EditorState,
    RichUtils
} from 'draft-js';
import './TextEditor.css';
import Controls, {CustomBlockStyling} from './Controls';
import {LINK_TYPE, linkDecorator} from './Links';
import useDebounce from '../../helpers/hooks/useDebounce';

/** Custom decorator (used for links) */
export const compositeDecorator = new CompositeDecorator([linkDecorator]);

interface ITextEditorProps {
    onChange: (editorState: EditorState) => void;
    initial?: EditorState;
}
const TextEditor: React.FC<ITextEditorProps> = ({onChange, initial}) => {
    /** Holds text editor state */
    const [current, setCurrent] = useState<EditorState>((initial) ? initial : EditorState.createEmpty(compositeDecorator));
    const debounced = useDebounce(current, 500); // Only update value if no new change has occurred for 500ms


    /** call onChange */
    useEffect(() => {
        if (onChange) {
            onChange(debounced);
        }
        // TODO: Find out how to rely on debounced dep without breaking onChange
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounced]);

    /** Handles command key presses */
    const handleKeyCommand = (command: any, editorState: EditorState): DraftHandleValue => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setCurrent(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    /** Block control */
    const onBlockToggle = (blockType: string): void => {
        const editorState = RichUtils.toggleBlockType(current, blockType);
        setCurrent(editorState);
    };

    /** Inline control */
    const onInlineToggle = (inlineType: string): void => {
        const editorState = RichUtils.toggleInlineStyle(current, inlineType);
        setCurrent(editorState);
    };

    /** Link control */
    const onLink = (link: string): void => {
        let contentState = current.getCurrentContent();
        contentState = contentState.createEntity(
            LINK_TYPE,
            'MUTABLE',
            {url: link}
        );
        const entityKey = contentState.getLastCreatedEntityKey();
        const editorState = RichUtils.toggleLink(current, current.getSelection(), entityKey);
        setCurrent(editorState);
    };

    /** Remove link control */
    const onRemoveLink = (): void => {
        const editorState = RichUtils.toggleLink(current, current.getSelection(), null);
        setCurrent(editorState);
    };

    return (
        <div className={'editor'}>
            <Controls
                editorState={current}
                onBlockToggle={onBlockToggle}
                onInlineToggle={onInlineToggle}
                onLink={onLink}
                onRemoveLink={onRemoveLink}/>
            <div className={'content'}>
            <Editor
                editorState={current}
                onChange={((editorState: EditorState) => setCurrent(editorState))}
                handleKeyCommand={handleKeyCommand}
                blockStyleFn={CustomBlockStyling}
                placeholder={/*todo: style placeholder*/ undefined}/>
            </div>
        </div>
    );
};
export default TextEditor;
