import constants from '../constants';

export default (choice?: string): {color: string, description: string} => {
    switch (choice) {
        case 'responsive':
            return constants.scale.responsive;
        case 'neutral':
            return constants.scale.neutral;
        case 'blind':
            return constants.scale.blind;
        case 'regressive':
            return constants.scale.regressive;
        case 'inconclusive':
            return constants.scale.inconclusive;
        case 'na':
            return constants.scale.na;
        case 'inconclusiveOrNA':
        default:
            return {color: 'transparent', description: 'Invalid'};
    }
}
