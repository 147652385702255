import React from 'react';
import '../../search/SearchCategoryItem.tsx'
import {branding} from '../../../assets';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {AuthActions} from '../../../state/actions';
import './Sidebar.css'
import {roleCheck} from '../../../helpers';
import {UserRole} from '../../../helpers/enums';

interface IItemProps {
    title: string;
    selected: boolean;
    branding: { icon: string, icon_selected: string };
    onClick?: () => void;
}

const Item: React.FC<IItemProps> = ({selected, branding, title, onClick}) => (
    <div className={'category-filter'} onClick={onClick}>
        <div className={'category-item'}>
            <img src={selected ? branding.icon_selected : branding.icon} alt={title}/>
            <div className={selected ? 'title-selected' : 'title'}>
                {title}
            </div>
        </div>
    </div>
);

const Sidebar: React.FC = () => {
    const {user} = useSelector((state: any) => (state.auth));
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();


    const logout = () => {
        dispatch(AuthActions.logout());
        history.push('/');
    };

    return (
        <div className='profile-sidebar'>
            <Item
                title='Profile Details'
                selected={location.pathname === '/profile'}
                branding={{icon: branding.profile_detail_grey, icon_selected: branding.profile_detail_blue}}
                onClick={() => history.push('/profile')}/>
            <Item
                title='My Evaluations'
                selected={location.pathname === '/profile/my-evaluations'}
                branding={{icon: branding.my_evaluation_grey, icon_selected: branding.my_evaluation_blue}}
                onClick={() => history.push('/profile/my-evaluations')}/>
            {(user && roleCheck(user.role, UserRole.ARCHITECT)) && (
                <>
                    <Item
                        title='User Management'
                        selected={location.pathname === '/profile/user-management'}
                        branding={{icon: branding.user_management_grey, icon_selected: branding.user_management_blue}}
                        onClick={() => history.push('/profile/user-management')}/>
                    <Item
                        title='Add New Law'
                        selected={location.pathname === '/profile/new-law'}
                        branding={{icon: branding.new_law_icon_grey, icon_selected: branding.new_law_icon_blue}}
                        onClick={() => history.push('/profile/new-law')}/>
                    <Item
                        title='Add New Category'
                        selected={location.pathname === '/profile/new-category'}
                        branding={{icon: branding.new_law_icon_grey, icon_selected: branding.new_law_icon_blue}}
                        onClick={() => history.push('/profile/new-category')}/>
                    <div className={'sidebar-divider'}/>
                    <Item
                        title='Edit Category'
                        selected={location.pathname.includes('/profile/edit-category')}
                        branding={{icon: branding.my_evaluation_grey, icon_selected: branding.my_evaluation_blue}}
                        onClick={() => history.push('/profile/edit-category')}/>
                    {location.pathname.includes('/profile/edit-law/') && (
                        <>
                            <Item
                                title='Edit Law'
                                selected={location.pathname.includes('/profile/edit-law/')}
                                branding={{icon: branding.edit_law_grey, icon_selected: branding.edit_law_blue}}/>
                        </>
                    )}
                </>
            )}
            <div className={'sidebar-divider'}/>
            <Item
                title='Logout'
                selected={false}
                branding={{icon: branding.back_icon_grey, icon_selected: branding.back_icon_blue}}
                onClick={() => logout()}/>
        </div>
    )
};

export default Sidebar;
