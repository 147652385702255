import React, {useRef, useState} from 'react';
import './Dropdown.css';
import useOutsideAlerter from '../../../helpers/hooks/OutsideAlert';
import BaseDropdown from './BaseDropdown';
import {IDropdownOptions} from './BaseDropdown';

interface IDropdownProps {
    name?: string,
    options?: IDropdownOptions[];
    onChange?: (value: string) => void,
    className?: 'small' | '',
    value?: string,
    warning?: any,
    label?: string,
}

const SimpleDropdown: React.FC<IDropdownProps> = ({options = [], onChange, className = '', value = '', label = '', warning}: IDropdownProps) => {
    // Sets visibility of menu
    const [visible, setVisible] = useState(false);

    // Closes menu on click outside the dropdown
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => setVisible(false));

    /**
     * Handles updating change
     */
    const handleChange = (val: string) => {
        setVisible(!visible);
        if (onChange) {
            onChange(val);
        }
    };

    /**
     * Returns the current value for either the predefined value or the formik value
     */
    const getValue = () => value;

    /**
     * Used to return either the placeholder or the selected value
     */
    const getTitle = (): IDropdownOptions => {
        // Return the placeholder or the current value
        if (getValue() === '' || getValue() === undefined) {
            return {value: '', description: `${label}`};
        } else {
            if (getValue()) {

            }
            const selected = getSelected(getValue());
            return (selected) ? selected : {value: '', description: `${label}`};
        }
    };

    /**
     * Used to retrieve the current selected details
     */
    const getSelected = (selectedId: string): IDropdownOptions | undefined => {
        return  options.find((item) => item.value === selectedId);
    };

    return (
        <BaseDropdown
            options={options}
            getTitle={getTitle}
            getValue={getValue}
            setValue={handleChange}
            className={className}/>
    );
};

export default SimpleDropdown;
