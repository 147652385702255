import {Dispatch} from 'redux';
import {createAction} from "../../helpers";
import {AxiosError} from "axios";
import fallback from '../../gli-static-config.json'
import {IGlossaryContent, IHomeContent, IScoringContent} from '../reducers/staticContent';
export const STATIC_CONTENT_ACTIONS = {

    GET_HOME_CONTENT : 'GET_HOME_CONTENT',
    GET_HOME_CONTENT_SUCCESS : 'GET_HOME_CONTENT_SUCCESS',
    GET_HOME_CONTENT_ERROR: 'GET_HOME_CONTENT_ERROR',

    GET_ALL_CONTENT: 'GET_ALL_CONTENT',
    GET_ALL_CONTENT_SUCCESS: 'GET_ALL_CONTENT_SUCCESS',
    GET_ALL_CONTENT_ERROR: 'GET_ALL_CONTENT_ERROR',
};

interface IAction {
    home: IHomeContent;
    scoring: IScoringContent;
    glossary: IGlossaryContent;
}

const StaticContentActions = {

    readHomeContent: () => createAction(STATIC_CONTENT_ACTIONS.GET_HOME_CONTENT),
    getHomeContentSuccess: (content: IHomeContent) => createAction(STATIC_CONTENT_ACTIONS.GET_HOME_CONTENT_SUCCESS, content),
    getHomeContentError: (error: AxiosError) => createAction(STATIC_CONTENT_ACTIONS.GET_HOME_CONTENT_ERROR,error),
    getAllContent: () => createAction(STATIC_CONTENT_ACTIONS.GET_ALL_CONTENT),
    getAllContentSuccess: (content: IAction) => createAction(STATIC_CONTENT_ACTIONS.GET_ALL_CONTENT_SUCCESS, content),
    getAllContentError: (content: IAction & {error: AxiosError}) => createAction(STATIC_CONTENT_ACTIONS.GET_ALL_CONTENT_ERROR, content),
};

const getHomeContent = ()=> async (dispatch: Dispatch) : Promise<void> =>{

    dispatch(StaticContentActions.readHomeContent());
    //get content
    // const rest = await axios.get("./gli-static-config.json");
    // console.log('rest',rest);
    // let data = await rest.text();

    // @ts-ignore
    // const home : IHomeContent = JSON.parse( data);

   dispatch(StaticContentActions.getHomeContentSuccess(fallback.home));
};

/** retrieves content object */
const getContent = ()=> async (dispatch: Dispatch) : Promise<void> => {
    dispatch(StaticContentActions.getAllContent());
    try {
        const response = fallback; // TODO: Async get of json file
        dispatch(StaticContentActions.getAllContentSuccess(response));
    } catch (e) {
        dispatch(StaticContentActions.getAllContentError({...fallback, error: e}));
    }
};



export default {
    getHomeContent,
    getContent,
}
