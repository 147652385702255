import React from 'react';
import './Footer.css';

const Footer = () => (
    <footer>
        <p>© 2018 - {(new Date()).getFullYear()}</p>
        <div className={'content'}>
        </div>
    </footer>
);
export default Footer;
