import {IRouteConfig} from '../../helpers/interfaces';
import Detail from './Detail';
import Evaluation from './Evaluation';
import {UserRole} from '../../helpers/enums';

const PREFIX = '/laws';
const UUID_REGEX = '[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}';

const routes: IRouteConfig[] = [
    {
        prefix: PREFIX,
        path: '/:lawId/evaluate',
        component: Evaluation,
        requiredRole: UserRole.EVALUATOR,
    },
    {
        prefix: PREFIX,
        path: [`/:lawId/:assessmentId(${UUID_REGEX})?/:type(intention|effect)?`],
        component: Detail,
    }
];

export default routes;
