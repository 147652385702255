import {createAction} from '../../helpers';
import { Dispatch } from 'redux';
import {getApiClient} from '../../api';
import {StandardActions} from './index';
import {CategoriesState} from '../reducers/categories';

export const CATEGORIES_ACTIONS = {
    CATEGORIES_REQUEST: 'CATEGORIES_REQUEST',
    CATEGORIES_SUCCESS: 'CATEGORIES_SUCCESS',
    CATEGORIES_FAILURE: 'CATEGORIES_FAILURE',
};

const CategoriesActions = {
    categoriesRequest: () => createAction(CATEGORIES_ACTIONS.CATEGORIES_REQUEST),
    categoriesSuccess: (categories: any[]) => createAction(CATEGORIES_ACTIONS.CATEGORIES_SUCCESS, categories),
    categoriesFailure: (error: any) => createAction(CATEGORIES_ACTIONS.CATEGORIES_FAILURE, error)
};


/**
 * Pre-populates state with category ids
 *
 * TODO: pass in specific categories to load
 */
const get = (force?: boolean) => async (dispatch: Dispatch, state: CategoriesState) => {
    if (state.categories === undefined || force) {
        try {
            dispatch(CategoriesActions.categoriesRequest());
            const categories = await getApiClient().categoriesService.getCategories();
            dispatch(CategoriesActions.categoriesSuccess(categories.data));
            if (Array.isArray(categories.data)) {
                dispatch<any>(StandardActions.get(categories.data.map((item: any) => item.id)));
            }
    } catch (e) {
            dispatch(CategoriesActions.categoriesFailure(e));
        }
    }
    // else do nothing, categories are already loaded
};


export default {
    get,
}

