import {Payload} from "../../helpers/create-action";
import {BENCHMARK_ACTIONS} from "../actions";

export interface IBenchmark {
    id: string,
    description: string,
    categoryId: string,
    categoryTitle: string,
    categoryIdentifier: string,
    questionTextId: string,
    questionTextDescription: string
}

export interface BenchmarksState {
    loading: boolean;
    benchmarks: {[key: string]: []},
    error: any | null;
}

const initialState: BenchmarksState = {
    loading: false,
    benchmarks: {},
    error: null,
};

export default (state: BenchmarksState = initialState, {type, payload}: Payload): BenchmarksState => {
    switch (type) {
        case BENCHMARK_ACTIONS.BENCHMARK_REQUEST:
            return {...state, loading: true, error: null};
        case BENCHMARK_ACTIONS.BENCHMARK_SUCCESS:
            return {...state, loading: false, benchmarks: payload, error: null};
        case BENCHMARK_ACTIONS.BENCHMARK_FAILURE:
            return {...state, loading: false, error: payload};
        default:
            return state;
    }
}
