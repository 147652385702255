import {ILaw} from "../../../helpers/interfaces";
import React from "react";
import {Text, View, Image} from "@react-pdf/renderer";
import {createStylesheet} from "./styleHelper";
import {commonStylesheet} from "./EvaluationDocument";
import {pdfImages} from '../../../assets';

const stylesheet = createStylesheet({
    header: {
        fontSize: 12,
        fontWeight: 'bold',
        marginBottom: 25,
    },
    headerTitle: {
        backgroundColor: '#c1c1c1',
        padding: '8px 14px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    headerDetails: {
        backgroundColor: '#000000',
        color: '#ffffff',
        padding: '8px 14px',
    },
    headerDetailsMeta: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 4,
    },
    meta: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    metaIcon: {
        width: 10,
        height: 10,
        marginRight: 5,
    },
    metaSpacing: {
        marginLeft: 30,
    },
});

interface IHeaderProps {
    law: ILaw;
}

export const Header: React.FC<IHeaderProps> = ({law}) => (
    <View style={stylesheet.header} fixed>
        <View style={stylesheet.headerTitle}>
            <Text>Gender Legislative Index Evaluation</Text>
            <Text style={commonStylesheet.text}>PDF generated on {new Date().toLocaleDateString()}</Text>
        </View>
        <View style={stylesheet.headerDetails}>
            <Text>{law.title}</Text>
            <View style={[commonStylesheet.text, stylesheet.headerDetailsMeta]}>
                <View style={stylesheet.meta}>
                    <Image source={pdfImages.globe} style={stylesheet.metaIcon} />
                    <Text>Jurisdiction: {law.country.title}</Text>
                </View>
                <View style={[stylesheet.meta, stylesheet.metaSpacing]}>
                    <Image source={pdfImages.calendar} style={stylesheet.metaIcon} />
                    <Text>Year Enacted: {law.year}</Text>
                </View>
                <View style={[stylesheet.meta, stylesheet.metaSpacing]}>
                    <Image source={pdfImages.hash} style={stylesheet.metaIcon} />
                    <Text>Number: {law.lawNumber}</Text>
                </View>
            </View>
        </View>
    </View>
);
