import {branding} from '../assets';
import country from '../assets/country';

export const getCategoryIcon = (categoryIdentifier: string): {standard: any, unselected: any, selected: any} => {
    switch (categoryIdentifier) {
        case 'familylaw': return {standard: branding.family_icon, unselected: branding.family_icon_grey, selected: branding.family_icon_orange};
        case 'financialserviceslaw': return {standard: branding.financial_services, unselected: branding.financial_services_grey, selected: branding.financial_services_orange};
        case 'gender-basedviolencelaw': return {standard: branding.gender_based_violance, unselected: branding.gender_based_violance_grey, selected: branding.gender_based_violance_orange};
        case 'labourlaw': return {standard: branding.labour_icon, unselected: branding.labour_icon_grey, selected: branding.labour_icon_orange};
        case 'reproductivehealthlaw': return {standard: branding.reproductive_icon, unselected: branding.reproductive_icon_grey, selected: branding.reproductive_icon_orange};
        case 'extractiveindustrieslaw':
        case 'extractivesindustrylaw': {
            return {standard: branding.extractive_services_law, unselected: branding.extractive_services_law_grey, selected: branding.extractive_services_law_orange};
        }
        case 'taxationlaw': return {standard: branding.taxation_icon, unselected: branding.taxation_icon_grey, selected: branding.taxation_icon_orange};
        default: return {standard: branding.family_icon, unselected: branding.family_icon_grey, selected: branding.family_icon_orange};
    }
};

export const getCountryIcon = (countryIdentifier: string): any => {
    switch (countryIdentifier) {
        case 'srilanka': return country.srilanka;
        case 'philippines': return country.philippines;
        case 'indonesia': return country.indonesia;
        case 'australia': return country.australia;
        default: return country.indonesia;
    }
};
