import {UserRole} from './enums';

const roleCheck = (curr: UserRole, required: UserRole): boolean => {
    switch (required) {
        case UserRole.ARCHITECT:
            return UserRole.ARCHITECT === curr;
        case UserRole.EVALUATOR:
            return UserRole.EVALUATOR === curr || UserRole.ARCHITECT === curr;
        case UserRole.GUEST:
            return UserRole.EVALUATOR === curr || UserRole.ARCHITECT === curr || UserRole.GUEST === curr;
        default:
            return false;
    }
};

export default roleCheck;
