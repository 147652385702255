import React from 'react';
import './ScorePill.css';
import {getScore} from '../../../helpers/get-score';

interface IScorePillProps {
    score?: number | string;
    fill?: boolean;
    bold?: boolean;
}

const ScorePill: React.FC<IScorePillProps> = (props) => {
    const {
        score = -1,
        fill = false,
        bold = false,
    } = props;

    const classNames = ['score-pill', `score-pill-${getScore(score).className}`];

    if(fill) {
        classNames.push('score-pill-fill');
    }

    if(bold) {
        classNames.push('score-pill-bold');
    }

    return (
        <div className={classNames.join(' ')}>
            {getScore(score).description}
        </div>
    );
};
export default ScorePill;
