import {createAction} from '../../helpers';
import {Dispatch} from 'redux';
import {getApiClient} from '../../api';

export const STANDARDS_ACTIONS = {
    STANDARDS_REQUEST: 'STANDARDS_REQUEST',
    STANDARDS_SUCCESS: 'STANDARDS_SUCCESS',
    STANDARDS_FAILURE: 'STANDARDS_FAILURE',
};

const StandardsActions = {
    standardsRequest: (categoryIds: string[]) => createAction(STANDARDS_ACTIONS.STANDARDS_REQUEST, categoryIds),
    standardsSuccess: (standards: any) => createAction(STANDARDS_ACTIONS.STANDARDS_SUCCESS, standards),
    standardsFailure: (error: any) => createAction(STANDARDS_ACTIONS.STANDARDS_FAILURE, error)
};

const get = (categoryIds: string[], force?: boolean) => async (dispatch: Dispatch) => {
    try {
        dispatch(StandardsActions.standardsRequest(categoryIds));
        const standards = await getApiClient().standardsService.getStandards(categoryIds);
        dispatch(StandardsActions.standardsSuccess(standards.data));
    } catch (e) {
        dispatch(StandardsActions.standardsFailure(e));
    }
};


export default {
    get,
}

