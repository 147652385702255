import {Scale} from "../../../helpers/get-score";
import {general} from "../../../assets";
import React from "react";
import './ScorePopup.css';

type Direction = 'left' | 'right' | 'centered';

interface IScorePopupProps {
    score: string | number;
    expandDirection?: Direction;
    mediumScreenExpandDirection?: Direction;
    smallScreenExpandDirection?: Direction;
}

const getDirectionClass = (direction: Direction, variant: 'large' | 'medium' | 'small') => {
    switch(direction) {
        case 'right':
            return `score-popup-right-${variant}-screen`;
        case 'centered':
            return `score-popup-centered-${variant}-screen`;
        case 'left':
        default:
            return `score-popup-left-${variant}-screen`;
    }
}

const ScorePopup: React.FC<IScorePopupProps> = (props) => {
    const {
        score,
        expandDirection = 'left',
        mediumScreenExpandDirection = expandDirection,
        smallScreenExpandDirection = mediumScreenExpandDirection
    } = props;

    const popupClasses = [
        'score-popup',
        getDirectionClass(expandDirection, 'large'),
        getDirectionClass(mediumScreenExpandDirection, 'medium'),
        getDirectionClass(smallScreenExpandDirection, 'small'),
    ];

    return (
        <div className={popupClasses.join(' ')}>
            <div className="score-popup-title">How well does this law meet international standards</div>
            {Scale.map(scaleItem => {
                const current = [scaleItem.score.alpha, scaleItem.score.numerical].includes(score);
                const classes = [
                    'score-popup-item',
                    `score-popup-${scaleItem.className}`,
                ];
                if(current) {
                    classes.push('score-popup-item-current');
                }

                return (
                    <div className={classes.join(' ')} key={scaleItem.score.alpha}>
                        <img src={general.tick} alt={'tick'}/>
                        {scaleItem.description}
                    </div>
                );
            })}
        </div>
    );
};

export default ScorePopup;
