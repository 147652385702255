import axios, {AxiosInstance, AxiosResponse} from 'axios';
import constants from '../constants';
import {axiosLogger} from '../helpers/development';
import {AuthService} from './services/authService';
import {LawsService} from './services/lawsService';
import {UsersService} from './services/usersService';
import {AssessService} from './services/assessService';
import {CategoriesService} from './services/categoriesService';
import {StandardsService} from './services/standardsService';
import {CountriesService} from './services/countriesService';
import {StorageService} from './services/storageService';
import {BlogsService} from './services/blogsService';

export class APIClient {
    public readonly authService: AuthService;
    public readonly lawsService: LawsService;
    public readonly usersService: UsersService;
    public readonly assessService: AssessService;
    public readonly categoriesService: CategoriesService;
    public readonly countriesService: CountriesService;
    public readonly standardsService: StandardsService;
    public readonly storageService: StorageService;
    public readonly blogsService: BlogsService;

    private readonly axiosInstance: AxiosInstance;
    private readonly baseUrl: string;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
        this.axiosInstance = axios.create({
            baseURL: this.baseUrl
        });

        // Print api requests
        if (constants.NODE_ENV === 'development') {
            this.axiosInstance.interceptors.request.use((request) => {
                    axiosLogger('Request', request);
                    return request;
                },
            );
        }

        // Print api responses
        this.axiosInstance.interceptors.response.use(
            async (response: AxiosResponse) => {
                if (constants.NODE_ENV === 'development') {
                    axiosLogger('Response', response);
                }
                return response;
            },
        );

        this.authService = new AuthService(this.axiosInstance);
        this.lawsService = new LawsService(this.axiosInstance);
        this.usersService = new UsersService(this.axiosInstance);
        this.assessService = new AssessService(this.axiosInstance);
        this.categoriesService = new CategoriesService(this.axiosInstance);
        this.countriesService = new CountriesService(this.axiosInstance);
        this.standardsService = new StandardsService(this.axiosInstance);
        this.storageService = new StorageService(this.axiosInstance);
        this.blogsService = new BlogsService(this.axiosInstance);
    }
}
