import {Payload} from '../../helpers/create-action';
import {QUESTION_ACTIONS} from '../actions/questions';

export interface QuestionsState {
    loading: boolean;
    questions: any[] | null;
    error: any | null;
}

const initialState: QuestionsState = {
    loading: false,
    questions: null,
    error: null,
};

export default (state: QuestionsState = initialState, {type, payload}: Payload) => {
    switch (type) {
        case QUESTION_ACTIONS.QUESTIONS_REQUEST:
            return {...state, loading: true, error: null};
        case QUESTION_ACTIONS.QUESTIONS_SUCCESS:
            return {...state, loading: false, questions: payload, error: null};
        case QUESTION_ACTIONS.QUESTIONS_FAILURE:
            return {...state, loading: false, error: payload};
        default:
            return state;
    }
}
