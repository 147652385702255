import React, { Ref } from 'react';
import './Pages.css';
import '../../submit/Submit.css';

interface IPagesProps {
    children: JSX.Element | JSX.Element[] | (JSX.Element | JSX.Element[])[];
    handleSubmit: any;
    isSubmitting?: boolean;
    page: number;
    previous: () => void;
    next: () => void;
    pageCount: number;
};


const Pages = React.forwardRef<HTMLDivElement, IPagesProps>((
    {children, page, previous, next, pageCount, handleSubmit , isSubmitting = false},
    ref: Ref<HTMLDivElement>
) => (
    <div className="page" ref={ref}>
        {React.Children.toArray(children)[page]}
        <div className={'pages-buttons'}>
            {page > 0 && <button className={'leftside'} type={'button'} onClick={previous}>previous </button>}
            {(page === pageCount - 1) ? (
                <><br/><button className={'submit'} type={'button'} onClick={handleSubmit} disabled={isSubmitting}>submit</button></>
            ) : (
                <button className={'rightside'} type={'button'} onClick={next}>next</button>
            )}
        </div>
    </div>
));

export default Pages;
