import {Form, Formik} from 'formik';
import React, {useState} from 'react';
import * as Yup from 'yup';
import '../../../common/text-input/TextInput.css';
import {AlertMessage} from '../../../layout';
import {getApiClient} from '../../../../api';

interface ILawCommentProps {
    lawId: string;
    comments?: string;
    cb?: () => void | Promise<void>;
}
const LawComment: React.FC<ILawCommentProps> = ({comments = '', lawId, cb}) => {
    const [error, setError] = useState();

    const handle = async (val: {comments: string}, {setSubmitting}: any) => {
        setError(undefined);
        try {
            await getApiClient().lawsService.comment(lawId, val.comments);
            if (cb) { await cb(); }
        } catch (e) {
            setError(e);
        }
        setSubmitting(false);
    };

    const validation = Yup.object().shape({
        comments: Yup.string() // Not required
    });

    return (
        <>
            <h3>Add Comment</h3>
            <Formik
                initialValues={{comments: (comments) ? comments : ''}}
                validationSchema={validation}
                onSubmit={handle}>
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                      isSubmitting,
                  }) =>
                    <Form onSubmit={handleSubmit}>
                        <textarea
                            name={'comments'}
                            key={'comments'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.comments}
                            className={'text-input box'}/>
                        {(((comments) ? comments : '') !== values.comments) && <button className={'submit'} type={'submit'}>add comment</button>}
                        <AlertMessage message={error}/>
                    </Form>
                }
            </Formik>
        </>
    )
};
export default LawComment;
