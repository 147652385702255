import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkSubSuper from '../../helpers/md-sub-super-script';
import './Markdown.css';


/** TODO: either superscripts can link to the citations or links open in new tabs not both, Custom link renderer that opens links in new tab  */
const LinkRenderer: React.FC<any> = (props) => (
    <a  href={props.href} target={'_blank'} className={'md-link'}>{props.children}</a>
);

interface IMarkdownProps {
    children?: string;
    className?: string;
}
const Markdown: React.FC<IMarkdownProps> = ({children, className }) => (
    <ReactMarkdown
        className={className}
        source={children}
        plugins={[remarkSubSuper]}
        renderers={{
            sub: 'sub',
            sup: 'sup',
            link: LinkRenderer,
        }}/>
);
export default Markdown;
