import React from 'react';
import './EvaluationPanel.css'

const MultipleChoiceOptions: { value: string, description: string }[] = [
    {value: 'responsive', description: 'Responsive'},
    {value: 'neutral', description: 'Neutral'},
    {value: 'blind', description: 'Blind'},
    {value: 'regressive', description: 'Regressive'},
    {value: 'inconclusive', description: 'Inconclusive'},
    {value: 'na', description: 'Not Applicable'},
];


interface IMultipleChoiceProps {
    group: 'intention' | 'effect';
    name: string;
    warning: string | null;
    onChange: any;
    onBlur: any;
    value: string;
    setFieldValue: any;
    setTouched: any;
}

const MultipleChoiceRadio: React.FC<IMultipleChoiceProps> = ({group, name, warning, onChange, onBlur, value, setFieldValue, setTouched}) => {
    return (
        <div className={`multiplechoice-container ${warning ? 'warning' : ''}`}>
            {MultipleChoiceOptions.map((mc) => (
                <div key={`${name}.${mc.value}`}>
                    <div className={`multiple-choice ${mc.value}`}>
                        <label htmlFor={`${name}.${mc.value}`}>{mc.description}</label>
                        <input
                            type='radio'
                            name={name}
                            id={`${name}.${mc.value}`}
                            value={mc.value}
                            onBlur={onBlur}
                            checked={value === mc.value}
                            onTouchStart={() => setTouched(name, true)}
                            onChange={() => setFieldValue(name, mc.value)}/>
                    </div>
                </div>
            ))}
        </div>
    );
};
export default MultipleChoiceRadio;
