import React, {ChangeEvent} from 'react';
import './SearchBar.css';
import {general} from '../../../assets';

interface IBarProps {
    value: string,
    onChange: (value: string) => void,
    onSubmit: () => void,
    onFilterClick: () => void,
}

const Bar: React.FC<IBarProps> = ({value, onChange, onSubmit, onFilterClick}) => {

    const handleSubmit = (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit();
    };

    return (
        <div className={'search-wrapper'}>
            <img alt={''} className={'filter'} src={general.filter} onClick={() => onFilterClick()}/>
            <form className='search-bar' onSubmit={handleSubmit}>
                <img className={'indicator'} src={general.search} alt='^'/>
                <input
                    type='text'
                    placeholder='Search Index'
                    value={value}
                    onChange={(value) => onChange(value.target.value)}/>
            </form>
        </div>
    );
};

export default Bar;
