import {AxiosInstance, AxiosResponse} from 'axios';
import {IQuestionSchema} from '../../components/evaluation/evaluation-panel/QuestionForm';
import {IAssessmentHeatmap, IScoreDetail} from "../../helpers/interfaces";

const PATH = 'assess';

export class AssessService {
    private readonly axiosInstance: AxiosInstance;
    constructor(axios: AxiosInstance) {
        this.axiosInstance = axios;
    }

    //================================
    // Endpoints
    //================================

    /**
     * Get all questions
     */
    public getQuestions = async (): Promise<AxiosResponse> => {
        return await this.axiosInstance.get(`/${PATH}/questions`);
    };

    /**
     * Get heatmap values for a given law
     */
    public getHeatmap = async (lawId: string): Promise<AxiosResponse<IScoreDetail>> => {
        return await this.axiosInstance.get(`/${PATH}/heatmap/${lawId}`);
    };

    /**
     * Get single assessment values
     */
    public getSingleScoreDetail = async (assessmentId: string): Promise<AxiosResponse<IAssessmentHeatmap>> => {
        return await this.axiosInstance.get(`/${PATH}/heatmap-single/${assessmentId}`);
    };

    //================================
    // Private Endpoints
    //================================
    /**
     *  Submit an assessment
     */
    public submit = async (lawId: string, schema: IQuestionSchema) => {
        return await this.axiosInstance.post(`/${PATH}/law/${lawId}`, schema);
    };

    /**
     * Get previous assessment by me for a law
     */
    public getPreviousByLaw = async (lawId: string) => {
        return await this.axiosInstance.get(`/${PATH}/by-law/${lawId}/me`);
    };
}
