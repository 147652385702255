import React from 'react';
import {Form, Formik} from 'formik';
import {AlertMessage} from '../../layout';
import {useDispatch, useSelector} from 'react-redux';
import {TextInput} from '../../common';
import './AuthModal.css';
import {IAuthModalProps, PanelState} from './index';
import * as Yup from 'yup';
import {Spinner} from '../../common/spinner';
import {AuthActions} from '../../../state/actions';


type Schema = { email: string, password: string };
const initial: Schema = {email: '', password: ''};

const LoginModal: React.FC<IAuthModalProps> = ({setPanel, close}: IAuthModalProps) => {
    const {loading, error} = useSelector((state: any) => (state.auth));
    const dispatch = useDispatch();

    const handle = async (values: any): Promise<void> => {
        await dispatch(AuthActions.login(values.email, values.password, () => {
            close();
            window.location.reload();
        }));
    };

    const validation = Yup.object().shape({
        email: Yup.string()
            .email('Invalid email')
            .required('Required'),
        password: Yup.string()
            .required('Required'),
    });

    return (
        <div className='auth-form'>
            <div className={'header-space'}>
                <h3>Login to your account</h3>
            </div>
            <Formik
                initialValues={initial}
                validationSchema={validation}
                onSubmit={handle}>
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                  }) =>
                    <Form>
                        <div className={'input-space'}>
                            <TextInput
                                name={'email'}
                                label={'Email'}
                                type={'email'}
                                warning={(errors.email && touched.email) && errors.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}/>
                        </div>
                        <div>
                            <TextInput
                                name={'password'}
                                label={'Password'}
                                type={'password'}
                                warning={(errors.password && touched.password) && errors.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}/>
                        </div>
                        <div className={'warning-space'}>
                            <AlertMessage className={'error'} message={error}/>
                        </div>
                        <button className={'submit'} type='submit' disabled={isSubmitting || loading}
                                onClick={() => handleSubmit()}>
                            {(isSubmitting) && <Spinner/>} Login
                        </button>
                    </Form>
                }
            </Formik>
            <div className={'links-space'}>
                {/*<p>Don't have an account? <button className={'links'} onClick={() => setPanel(PanelState.REGISTER)}>Create one</button></p>*/}
                <p>Forgotten your password? <button className={'links'}
                                                    onClick={() => setPanel(PanelState.FORGOT)}>Reset</button></p>
                <br/>
            </div>
        </div>
    );
};
export default LoginModal;
