import React from 'react';
import './MyEvaluations.css';
import {SearchPagination, SearchResults} from '../../../search/results';
import {getApiClient} from '../../../../api';
import {IPagination} from '../../../../helpers/interfaces';
import {AlertMessage} from '../../../layout';

interface IMyEvaluationsProps {
}

interface IMyEvaluationsState {
    laws: any[];
    page: number;
    error?: any;
    loading: boolean;
    pagination: IPagination | null,
}

class MyEvaluations extends React.Component<IMyEvaluationsProps, IMyEvaluationsState> {
    constructor(props: IMyEvaluationsProps) {
        super(props);
        this.state = {
            laws: [],
            page: 0,
            loading: true,
            error: undefined,
            pagination: null,
        }
    }

    async componentDidMount(): Promise<void> {
        await this.search();
    }

    async search(): Promise<void> {
        this.setState({error: undefined, loading: true});
        try {
            const laws = await getApiClient().lawsService.assessed();
            this.setState({laws: laws.data.items, pagination: laws.data.pageInfo});
        } catch (e) {
            this.setState({error: e});
        }
        this.setState({loading: false});
    }

    async clickPage(nextPage: number): Promise<void> {
        this.setState({page: nextPage}, async () => {
            await this.search();
        });
    }

    render(): React.ReactElement {
        const {loading, laws, error} = this.state;

        if (loading) {
            return <p>loading...</p>;
        }
        return (
            <>
                <h3>My Evaluations</h3>
                <AlertMessage message={error}/>
                <SearchResults
                    laws={laws}
                    loading={false}
                    notFoundText={'No laws assessed yet.'}
                    goToMyEvaluation/>
                <SearchPagination
                    pagination={this.state.pagination}
                    onChange={(val) => this.clickPage(val)}/>
            </>
        );
    }
};
export default MyEvaluations;
