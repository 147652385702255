import {AxiosInstance, AxiosResponse} from 'axios';
import {RawDraftContentState} from 'draft-js';
import {BlogCommentObject} from '../../components/blog/BlogComments';

const PATH = 'blogs';

export class BlogsService {
    private readonly axiosInstance: AxiosInstance;
    constructor(axios: AxiosInstance) {
        this.axiosInstance = axios;
    }

    //================================
    // Endpoints
    //================================

    /**
     * Get blog by law id
     */
    public get = async (lawId: string): Promise<AxiosResponse> => {
        return await this.axiosInstance.get(`/${PATH}/${lawId}`);
    };


    //================================
    // Private Endpoints
    //================================

    /**
     * Get blog by law id
     */
    public create = async (lawId: string, {title, author, blog, comments}: {title: string, author: string, blog: RawDraftContentState, comments: BlogCommentObject[]}): Promise<AxiosResponse> => {
        return await this.axiosInstance.post(`/${PATH}`, {lawId, title, author, comments, blog: JSON.stringify(blog)});
    };

    public disable = async (lawId: string): Promise<AxiosResponse> => {
        return await this.axiosInstance.post(`/${PATH}/disable/${lawId}`);
    };
}
