import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {getApiClient} from '../../api';
import {AlertMessage, NotAuthorised} from '../../components/layout';
import {Analysis} from '../../components/laws/analysis';
import {Header} from '../../components/laws/header';
import {ILaw, IUser} from '../../helpers/interfaces';
import './Detail.css';
import {About} from '../../components/laws/about';
import {Score} from '../../components/laws/score';
import {Breakdown} from '../../components/laws/breakdown';
import constants from '../../constants';
import {Dispatch} from 'redux';
import {CategoryActions} from '../../state/actions';
import {connect} from 'react-redux';
import Placeholder from '../../components/layout/Placeholder';
import {UserRole} from '../../helpers/enums';

interface IDetailState {
    law: ILaw | null,
    loading: boolean,
    error: any
}

interface IDetailParams {
    lawId: string,
    assessmentId: string,
}

interface IDetailProps extends RouteComponentProps<IDetailParams> {
    getCategories: () => void;
    user: IUser;
}

/**
 * Container for details of a single law
 */
class Detail extends React.Component<IDetailProps, IDetailState> {
    constructor(props: IDetailProps) {
        super(props);
        this.state = {law: null, loading: true, error: null}
    }

    async componentDidMount(): Promise<void> {
        document.title = `${constants.TITLE} - Law Detail`;
        try {
            const law = await getApiClient().lawsService.get(this.props.match.params.lawId);
            this.setState({law: law.data, loading: false});
            if (law.data.flagged) {
                if (this.props.user && this.props.user.role !== UserRole.ARCHITECT) {
                    const assessment = law.data.assessments.find((item: any) => {
                        if (item.user) {
                            return item.user.id === this.props.user.id;
                        }
                        return false;
                    });
                    if (assessment) {
                        this.props.history.replace(`/laws/${this.props.match.params.lawId}/${assessment.id}`);
                    } else {
                        this.props.history.replace(`/laws/${this.props.match.params.lawId}/evaluate`);
                    }
                }
            }
        } catch (e) {
            this.setState({loading: false, error: e});
            console.error(e);
        }
    }

    render(): React.ReactElement {
        const {lawId} = this.props.match.params;
        const {law, error, loading} = this.state;
        // Display placeholder if loading
        if (loading) {
            return (<Placeholder loading={loading}/>);
        } else if (error) {
            // Handle errors
            if (error.response) {
                if (error.response.status === 401) {
                    return <NotAuthorised/>;
                }
            }
            return <AlertMessage className={'error'} title={'Error'} message={error}/>
        }
        if (!law) {
            return <AlertMessage className={'error'} title={'Error'} message={'Law failed to load.'}/>
        } else {
            return (
                <div>
                    <div className="stickied-header">
                        <Header
                            title={law.title}
                            score={law.score}
                            category={law.primaryCategory}
                            country={law.country}
                            evaluations={law.assessments.length}
                            year={law.year}
                        />
                    </div>
                    <AlertMessage message={error}/>
                    <div className='detail'>
                        <div className={'detail-component'}>
                            <Analysis lawId={lawId} law={law} assessments={law.assessments} flagged={law.flagged}/>
                        </div>
                        <div className='sidebar'>
                            <About law={law}/>
                            <div className="detail-sidebar-scoring">
                                <Score law={law}/>
                                <Breakdown assessments={law.assessments}/>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getCategories: () => dispatch<any>(CategoryActions.get()),
    }
};

const mapStateToProps = (state: any) => {
    return {
        user: state.auth.user,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Detail));
