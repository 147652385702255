import React, {useState} from 'react';
import LawForm, {LawSchema} from './LawForm';
import useLawUpload from './useLawUpload';
import {useHistory} from 'react-router-dom';
import {AlertMessage} from '../../../layout';

const AddLaw: React.FC = () => {
    const {addNewLaw} = useLawUpload();
    const [error, setError] = useState();
    const history = useHistory();

    const handle = async (values: LawSchema): Promise<void> => {
        try {
            const lawId = await addNewLaw(values);
            history.push(`/laws/${lawId}`);
        } catch (e) {
            setError(e);
        }
    };

    return (
        <>
            <h3>Add New Law</h3>
            <LawForm onSubmit={handle}/>
            <AlertMessage message={error}/>
        </>
    );
};
export default AddLaw;
