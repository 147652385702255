export interface INavButton {
    linkTo: string,
    selected: boolean,
    name: string
}

export const getNavButton = (): INavButton[] => {
    return ([
        {
            linkTo: '/home',
            selected: false,
            name: "Home"
        },
        {
            linkTo: '/laws',
            selected: false,
            name: "Search Index"
        },
        {
            linkTo: '/leaders',
            selected: false,
            name: 'Women Leaders'
        },
        {
            linkTo: '/benchmarks',
            selected: false,
            name: "Benchmarking"
        },
        {
            linkTo: '/scoring',
            selected: false,
            name: "Scoring"
        },
        {
            linkTo: '/glossary',
            selected: false,
            name: "Glossary of Terms"
        },
        {
            linkTo: '/about',
            selected: false,
            name: "About the GLI"
        },
    ]);
};

export const getNavButtonProfile = (loggedIn: boolean): INavButton => {
    if (loggedIn) {
        return {
            linkTo: '/profile',
            selected: false,
            name: "Profile"
        }
    }
    return {
        linkTo: '/login',
        selected: false,
        name: "Login"
    }
};
