import {ILaw} from "../../../helpers/interfaces";
import React from "react";
import {Link, Text, View} from "@react-pdf/renderer";
import {createStylesheet} from "./styleHelper";
import {commonStylesheet} from "./EvaluationDocument";

const stylesheet = createStylesheet({
    citation: {
        marginTop: 25,
    },
    citationText: {
        marginTop: 16,
    },
});

interface ICitationProps {
    law: ILaw;
}

export const Citation: React.FC<ICitationProps> = ({law}) => {
    const dates = [
        new Date(law.updatedDate),
        ...law.assessments.map(assessment => new Date(assessment.updatedDate)),
    ];
    const latestDate = dates.reduce((latest, date) => date > latest ? date : latest);
    const dateString = latestDate.toLocaleDateString('default', {month: 'long', day: 'numeric', year: 'numeric'});

    const url = `https://www.genderlawindex.org/laws/${law.id}`;
    return (
        <View style={[commonStylesheet.text, stylesheet.citation]}>
            <Text style={commonStylesheet.title}>Citing this evaluation</Text>
            <View style={stylesheet.citationText}>
                <Text>
                    Ramona Vijeyarasa, {dateString}, “Evaluation: {law.title}”, {law.country.title},
                    Gender Legislative Index, <Link src={url}>{url}</Link>
                </Text>
            </View>
        </View>
    );
};
