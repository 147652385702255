import {createAction} from '../../helpers';
import { Dispatch } from 'redux';
import {getApiClient} from '../../api';
import {CountriesState} from '../reducers/countries';

export const COUNTRIES_ACTIONS = {
    COUNTRIES_REQUEST: 'COUNTRIES_REQUEST',
    COUNTRIES_SUCCESS: 'COUNTRIES_SUCCESS',
    COUNTRIES_FAILURE: 'COUNTRIES_FAILURE',
};

const CountriesActions = {
    countriesRequest: () => createAction(COUNTRIES_ACTIONS.COUNTRIES_REQUEST),
    countriesSuccess: (countries: any[]) => createAction(COUNTRIES_ACTIONS.COUNTRIES_SUCCESS, countries),
    countriesFailure: (error: any) => createAction(COUNTRIES_ACTIONS.COUNTRIES_FAILURE, error)
};


/**
 * Pre-populates state with category ids
 *
 * TODO: pass in specific countries to load
 */
const get = (force?: boolean) => async (dispatch: Dispatch, state: CountriesState) => {
    if (state.countries === undefined || force) {
        try {
            dispatch(CountriesActions.countriesRequest());
            const countries = await getApiClient().countriesService.all();
            dispatch(CountriesActions.countriesSuccess(countries.data));
        } catch (e) {
            dispatch(CountriesActions.countriesFailure(e));
        }
    }
    // else do nothing, countries are already loaded
};


export default {
    get,
}

