import React from 'react';
import "./videoPlayer.css"
interface IVideoPlayerProps {
    video: string,
}

const VideoPlayer: React.FC<IVideoPlayerProps> = ({video}) => (
    <div className='video-container'>
        <iframe title={'landing'} src={video} frameBorder="0" allowFullScreen allow="autoplay; encrypted-media"/>
    </div>
);

export default VideoPlayer;
