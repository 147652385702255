import React, {useRef, useState} from 'react';
import {ContentState, ContentBlock, CharacterMetadata, DraftDecorator} from 'draft-js';
import './TextEditor.css';
import useOutsideAlerter from '../../helpers/hooks/OutsideAlert';

export const LINK_TYPE = 'LINK';

/** Link component */
interface ILinkProps {
    entityKey: string;
    contentState: ContentState;
    children: any;
}

const Link: React.FC<ILinkProps> = ({contentState, entityKey, children}) => {
    const {url} = contentState.getEntity(entityKey).getData();
    return (
        <a
            className={'editor-link'}
            href={url}
            onMouseDown={() => window.open(url, "_blank")}
            title={`link to: ${url}`}>
            {children}
        </a>
    );
};

/** Link strategy */
const findLinkEntities = (
    block: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState
) => {
    block.findEntityRanges((character: CharacterMetadata) => {
        const entityKey = character.getEntity();
        return entityKey !== null &&
            contentState.getEntity(entityKey).getType() === LINK_TYPE;
    }, callback);
};


/** Composite decorator to style link entities */
export const linkDecorator: DraftDecorator = {
    strategy: findLinkEntities,
    component: Link,
};

const LINK_DEFAULT = 'https://';

/** Link Popover */
interface ILinkPopoverProps {
    onLink: (link: string) => void;
}

const LinkPopover: React.FC<ILinkPopoverProps> = ({onLink}) => {
    const [active, setActive] = useState<boolean>(false);
    const [value, setValue] = useState<string>('https://');

    // Detects clicks outside of the component and hides the box
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => {
        if (active) {
            setValue(LINK_DEFAULT);
            setActive(false);
        }
    });

    return (
        <div style={{display: 'inline-block'}}>
            <div className={'editor-button'}>
                <span
                    onMouseDown={e => {
                        e.preventDefault();
                        setActive(!active);
                    }}>
                    Add Link
                </span>
            </div>
            <div
                ref={wrapperRef}
                className={`editor-popover ${(active) ? 'active' : ''}`}>
                <input
                    type='text'
                    placeholder={'Write link url...'}
                    onChange={e => setValue(e.target.value)}
                    onKeyPress={e => {
                        if (e.key === 'Enter'){
                            e.preventDefault();
                            onLink(value);
                            setValue(LINK_DEFAULT);
                            setActive(false);
                        }
                    }}
                    value={value}/>
            </div>
        </div>
    );
};


/** Link controls button */
interface ILinkButtonProps {
    onLink: (link: string) => void;
    onRemove: () => void;
    active: boolean; // Determines if remove link is active
}

/** Link control buttons */
export const LinkButton: React.FC<ILinkButtonProps> = ({onLink, onRemove, active}) => {
    return (
        <>
            <LinkPopover onLink={onLink}/>
            <div className={`editor-button`}>
            <span
                onMouseDown={e => {
                    e.preventDefault();
                    onRemove();
                }}>
                Remove Link
            </span>
            </div>
        </>
    );
};
