import React from 'react';
import './Citations.css';
import Markdown from '../Markdown';

interface ICitationsProps {
    citations: string[];
}
const Citations: React.FC<ICitationsProps> = ({citations}) => {
    return (
        <div id={'citations'} className={'citations'}>
            <ul>
                {citations.map((item, index) => (
                    <li key={index}><Markdown>{item}</Markdown></li>
                ))}
            </ul>
        </div>
    );
};
export default Citations;

