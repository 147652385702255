import React, {useEffect, useRef, useState} from 'react';
import {TextInput} from '../../common';
import {IQuestion} from '../../../helpers/interfaces';
import {Formik, FormikTouched} from 'formik';
import {Persist} from '../../../helpers/formik-persist';
import Pages from './Pages';
import MultipleChoiceRadio from './MultipleChoiceRadio';
import {FormMultiDropdown} from '../../common/dropdown';
import * as Yup from 'yup';
import ScoreRadio from './ScoreRadio';
import ProgressIndicator from './ProgressIndicator';
import './QuestionForm.css';
import {useSelector} from 'react-redux';
import {IDropdownOptions} from '../../common/dropdown/BaseDropdown';
import {getApiClient} from '../../../api';
import {TextArea} from '../../common/text-input';
import {general} from '../../../assets';
import {Modal} from '../../common/modal';
import Markdown from '../../common/Markdown';

interface ITitleComponent {
    tooltip?: JSX.Element,
    title: string,
}
const TitleComponent: React.FC<ITitleComponent> = ({tooltip, title}) => {
    return (
        <div className={'title-component'}>
            <p>{title}</p>
            {tooltip && (
                <Modal
                size={'small'}
                trigger={
                    <img className={'question-title-component-image'} src={general.info} alt='?'/>
                }>
                {tooltip}
                </Modal>
            )}
        </div>
    );
};

/** Evaluation DTO */
export interface IQuestionSchema {
    intention: IQuestionList;
    effect: IQuestionList;
    comments: string;
    score: string;
}
interface ITouchedQuestionSchema extends FormikTouched<IQuestionSchema> {};

interface IQuestionList {
    [key: string]: IQuestionGroup;
}
interface ITouchedQuestionList extends FormikTouched<IQuestionList> {};

/** Single question group */
interface IQuestionGroup {
    mc: string;
    justification: string;
    reference: string;
    standards: string[];
}

interface IQuestionGroupProps {
    question: IQuestion;
    categories: string[];
    values: any;
    onChange: any;
    onBlur: any;
    touched: any;
    errors: any;
    setFieldValue: any;
    setTouched: any;
}

/**
 * Question group
 *
 * Is a single question object
 * - Multiple Choice
 * - Justification (Description)
 * - Reference
 * - Standards (Benchmarks)
 */
const QuestionGroup: React.FC<IQuestionGroupProps> = ({question, categories, onChange, values, errors, touched, onBlur, setFieldValue, setTouched}) => {
    const {evaluation} = useSelector((state: any) => (state.content));
    const {benchmarks} = useSelector((state: any) => (state.benchmarks));

    const getValue = (property: any, group: 'intention' | 'effect', field: string): any | undefined => {
        return property && property[group] && property[group][question.id] && property[group][question.id][field];
    };

    const getWarning = (group: 'intention' | 'effect', field: string) => {
        return (
            getValue(errors, group, field) && getValue(touched, group, field)
        ) && getValue(errors, group, field)
    };

    const getBenchmarks = (): IDropdownOptions[] => { // Returns list of benchmarks
        if (!!benchmarks && benchmarks[question.id]) {

            return benchmarks[question.id]
                .filter((benchmark: any) => categories.includes(benchmark.category.id))
                .map((benchmark: any) => ({value: benchmark.id, description: benchmark.description}));
        } else {
            return [];
        }
    };

    const Question = (group: 'intention' | 'effect') => (
        <div className={'question-group'}>
            <p>{question.question_reference + 1}. {question.description}</p>
            <TitleComponent title={'Benchmark for good practice'} tooltip={
                <div className={'evaluation-modal'}>Benchmark for good practice
                    <Markdown>{'##### Responsive \n' + evaluation?.mc.responsive + '\n ##### Neutral\n' + evaluation?.mc.neutral + '\n ##### Blind\n' + evaluation?.mc.blind + '\n ##### Regressive\n' + evaluation?.mc.regressive }</Markdown>
                </div>}/>
            <MultipleChoiceRadio
                group={group}
                name={`${group}.${question.id}.mc`}
                key={`${group}.${question.id}.mc`}
                warning={getWarning(group, 'mc')}
                onChange={onChange}
                onBlur={onBlur}
                value={values[group][question.id].mc}
                setFieldValue={setFieldValue}
                setTouched={setTouched}/>
            <TitleComponent title={'Comments'} tooltip={<div className={'evaluation-modal'}>Comments<Markdown>{evaluation?.justification}</Markdown></div>}/>
            <TextArea
                label={''}
                name={`${group}.${question.id}.justification`}
                key={`${group}.${question.id}.justification`}
                warning={getWarning(group, 'justification')}
                onChange={onChange}
                onBlur={onBlur}
                value={values[group][question.id].justification}/>
            <TitleComponent title={'Reference'}/>
            <TextInput
                label={''}
                name={`${group}.${question.id}.reference`}
                key={`${group}.${question.id}.reference`}
                warning={getWarning(group, 'reference')}
                onChange={onChange}
                onBlur={onBlur}
                value={values[group][question.id].reference}/>
            <TitleComponent title={'Benchmarks'}/>
            <FormMultiDropdown
                label={'Benchmarks'}
                name={`${group}.${question.id}.standards`}
                options={getBenchmarks()}
                onChange={onChange}
                warning={getWarning(group, 'standards')}
                flipped
                scrollToMenu/>
        </div>
    );

    return (
        <div>
            <h3 className={'subsection-title'}>Intention
                <Modal
                    size={'small'}
                    trigger={
                        <img className={'question-title-component-image'} src={general.info} alt='?'/>
                    }>
                    <div className={'evaluation-modal'}>Intention
                        <Markdown>{evaluation?.subsections.intention + '\n\n' +evaluation?.questions[`${question.question_reference + 1}`]}</Markdown>
                    </div>
                </Modal>
            </h3>
            {Question('intention')}
            <h3 className={'subsection-title question-group-title'}>Effect
                <Modal
                    size={'small'}
                    trigger={
                        <img className={'question-title-component-image'} src={general.info} alt='?'/>
                    }>
                    <div className={'evaluation-modal'}>Effect
                        <Markdown>{evaluation?.subsections.effect}</Markdown>
                    </div>
                </Modal>
            </h3>
            {Question('effect')}
        </div>
    );
};


/**
 * Question Form
 *
 * Holds the full assessment object (intention/effect + comments/score)
 */

interface IQuestionFormProps {
    questions: IQuestion[],
    lawId: string,
    onSubmit: (val: IQuestionSchema) => Promise<void>,
    categories: string[]
}

const QuestionForm: React.FC<IQuestionFormProps> = ({questions, lawId, onSubmit, categories}) => {
    const {evaluation} = useSelector((state: any) => (state.content));

    /** Manage reloading assessment from previous */
    const [previousEvaluation, setPrevious] = useState<IQuestionSchema>();
    const [checkingPrevious, setCheckingPrevious] = useState(true);
    useEffect(() => {
        const loadPrevious = async () => {
            try {
                const previous = await getApiClient().assessService.getPreviousByLaw(lawId);
                setPrevious(previous.data);
            } catch (e) {
                //Do nothing.
            }
            setCheckingPrevious(false);
        }
        loadPrevious();
    }, [lawId]);


    /** Manage page state */
    const [page, setPage] = useState(0);
    const pageCount = 8;

    const pageRef = useRef<HTMLDivElement>(null);

    /** Scroll to top when changing page */
    useEffect(() => {
        pageRef?.current?.scrollTo({top: 0, behavior: 'smooth'});
    }, [page]);

    /** convert a question into a initial schema */
    const questionToInitial = (question: IQuestion): IQuestionList => {
        return {
            [question.id]: {
                mc: '',
                justification: '',
                reference: '',
                standards: [],
            }
        };
    };

    /** Get initial schema values */
    const getInitial = (): IQuestionSchema => {
        const initialQuestionsArray = questions.map(questionToInitial);
        const initialQuestions = initialQuestionsArray.reduce((accumulator: any, current) => ({...accumulator, ...current}));
        return {
            intention: initialQuestions,
            effect: initialQuestions,
            comments: '',
            score: ''
        }
    };

    /** Get initial touched schema, based on previous evaluation */
    const getInitialTouched = (): ITouchedQuestionSchema => {
        const touchedQuestions = (field: 'intention' | 'effect'): ITouchedQuestionList => questions.reduce((accum, question) => {
            const prevQuestion = previousEvaluation?.[field]?.[question.id];
            const touchedParts ={
                mc: !!prevQuestion?.mc,
                justification: !!prevQuestion?.justification,
                reference: !!prevQuestion?.reference,
                standards: !!prevQuestion?.standards?.length,
            };
            if(Object.values(touchedParts).some(v => !!v)) {
                return {
                    ...accum,
                    [question.id]: touchedParts,
                };
            }
            return accum;
        }, {});
        return {
            intention: touchedQuestions('intention'),
            effect: touchedQuestions('effect'),
            comments: !!(previousEvaluation?.comments),
            score: !!(previousEvaluation?.score),
        }
    };

    /** Form validation */
    const getValidation = () => {
        const questionList: any = questions.reduce((accumulator: any, current) => ({
            ...accumulator,
            [current.id]: Yup.object().shape({
                mc: Yup.string()
                    .oneOf(['regressive', 'blind', 'neutral', 'responsive', 'inconclusive', 'na'])
                    .required('Choice required'),
                justification: Yup.string().required('Required'),
                reference: Yup.string().required('Required'),
                standards: Yup.array(Yup.string()),
            })
        }), {});

        return Yup.object().shape({
            intention: Yup.object().shape(questionList),
            effect: Yup.object().shape(questionList),
            comments: Yup.string().required('Comment required'),
            score: Yup.string().oneOf(['a', 'b', 'c', 'd', 'e', 'f']).required(),
        });
    };

    /** Form handling */
    const handle = async (val: IQuestionSchema) => {
        await onSubmit(val);
    };


    /** handle page state */
    const previous = () => {
        if (page > 0) {
            setPage(page - 1);
        }
    };
    const next = () => {
        if (page <= pageCount - 1) {
            setPage(page + 1);
        }
    };

    const emptyInitial = getInitial();
    const validation = getValidation();
    if (checkingPrevious) {
        return <p>loading...</p>
    }
    return (
        <div className={'question-form'}>
            <Formik
                enableReinitialize={false}
                onSubmit={handle}
                initialValues={(previousEvaluation) ? previousEvaluation : emptyInitial}
                validationSchema={validation}
                initialTouched={getInitialTouched()}
            >{
                (props) => (
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className={'evaluation-heading'}>
                            <h3>Evaluation</h3>
                            <ProgressIndicator
                                questions={questions}
                                setPage={setPage}
                                touched={props.touched}
                                errors={props.errors}
                                dirty={(!!previousEvaluation || props.dirty)}
                                page={page}/>
                        </div>
                        <Pages
                            handleSubmit={() => props.handleSubmit()}
                            isSubmitting={props.isSubmitting}
                            previous={previous}
                            next={next}
                            page={page}
                            pageCount={pageCount}
                            ref={pageRef}
                        >
                            {questions.map((question: IQuestion) => (
                                <QuestionGroup
                                    key={question.id}
                                    categories={categories}
                                    onChange={props.handleChange}
                                    question={question}
                                    errors={props.errors}
                                    touched={props.touched}
                                    onBlur={props.handleBlur}
                                    setFieldValue={props.setFieldValue}
                                    setTouched={props.setTouched}
                                    values={props.values}/>))}
                            <div>
                                <h3>International Standard</h3>
                                <TitleComponent title={'Benchmark for scoring'} tooltip={
                                    <div className={'evaluation-modal'}>Benchmark for scoring
                                        <Markdown>{evaluation?.bands}</Markdown>
                                    </div>}/>
                                <ScoreRadio
                                    name={'score'} {...props} warning={(props.errors.score && props.touched.score) && props.errors.score}/>
                                <TitleComponent title={'Overall Comments'} tooltip={
                                    <div className={'evaluation-modal'}>Overall Comments
                                        <Markdown>{evaluation?.comments}</Markdown>
                                    </div>}/>
                                <TextArea
                                    label={'Comment'}
                                    name={'comments'}
                                    key={'comments'}
                                    warning={(props.errors.comments && props.touched.comments) && props.errors.comments}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.comments}/>
                            </div>
                        </Pages>
                        <Persist name={`evaluation-${lawId}`} isSessionStorage/>
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default QuestionForm;
