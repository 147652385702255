import React from 'react';
import AlertMessage from './AlertMessage';
import './Placeholder.css';

interface IPlaceholderProps {
    loading?: boolean;
}
const Placeholder: React.FC<IPlaceholderProps> = ({loading = false}) => {
    if (loading) {
        return (
            <div className={'placeholder'}>
                <span className={'placeholder-text'}><div className={'wheel'}/> Loading...</span>
            </div>
        );
    } else {
        return <AlertMessage className={'error'} message={'Failed to load.'} title={'Error'}/>
    }
};
export default Placeholder;
