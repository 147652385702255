import React, {CSSProperties, useEffect, useState} from 'react';
import {IScoreDetail, IScoreDetailMC} from '../../../helpers/interfaces';
import {CircleIcon} from '../../common/circle';
import {useDispatch, useSelector} from 'react-redux';
import {QuestionActions} from '../../../state/actions';
import './Grid.css';
import constants from '../../../constants';
import './ScoreDetail';
import {Tooltip} from "../../common/tooltip";
import TooltipEvaluatorCount, {ScoreOptions} from "../../common/tooltip/TooltipEvaluatorCount";
import TooltipEvaluator from "../../common/tooltip/TooltipEvaluator";
import {general} from "../../../assets";


// TODO split this into OverallGridRow and EvaluatorGridRow
interface GridCellProps {
    total: number;
    value: number;
    className: string,
    isEvaluator?: boolean;
    heatmapData?: IScoreDetailMC,
    scoreType: ScoreOptions,
    type: 'intention' | 'effect'
}

const GridCell: React.FC<GridCellProps> = (
    {
        total,
        value,
        className,
        isEvaluator,
        scoreType,
        heatmapData,
        type,
    }
) => {
    const [hovering, setHovering] = useState(false);

    let opacity = value / total;
    /**
     * Make opacity non-linear, so lower numbers are more faded.
     * closest I could get to match the design with 3 total evaluators
     **/
    opacity = Math.pow(opacity, 1/0.75);

    const isEmpty = value === 0;

    return (
        <td
            className={`grid-cell ${className} ${isEmpty ? 'grid-cell-empty' : ''}`}
            style={{'--opacity': `${opacity}`} as CSSProperties}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
        >
            <Tooltip visible={!isEmpty && hovering}>
                {isEvaluator
                    ? (!!heatmapData && <TooltipEvaluator
                        group={type}
                        choice={heatmapData?.choice}
                        justification={heatmapData?.justification}
                        reference={heatmapData?.reference}
                        standardIds={heatmapData?.standardIds}
                    />)
                    : <TooltipEvaluatorCount
                        count={value}
                        total={total}
                        score={scoreType}
                    />
                }
            </Tooltip>
            {isEvaluator
                ? (!isEmpty && <img src={general.tick_grey} alt={`${scoreType} score tick`}/>)
                : `${value}/${total}`
            }
        </td>
    );
};

interface IGridRowProps {
    heatmapData: IScoreDetailMC;
    isEvaluator?: boolean;
    type: 'intention' | 'effect'
}

const GridRow: React.FC<IGridRowProps> = ({heatmapData, isEvaluator, type}) => {
    const {
        mc: {
            responsive = 0,
            neutral = 0,
            blind = 0,
            regressive = 0,
            inconclusive = 0,
            na = 0,
        } = {}
    } = heatmapData;
    const total = regressive + blind + neutral + responsive + na + inconclusive;
    const inconclusiveOrNA = inconclusive + na;

    return (
        <>
            <GridCell
                value={inconclusiveOrNA}
                total={total}
                className={'grid-cell-na'}
                isEvaluator={isEvaluator}
                scoreType="inconclusiveOrNA"
                heatmapData={heatmapData}
                type={type}
            />
            <GridCell
                value={regressive}
                total={total}
                className={'grid-cell-regressive'}
                isEvaluator={isEvaluator}
                scoreType="regressive"
                heatmapData={heatmapData}
                type={type}
            />
            <GridCell
                value={blind}
                total={total}
                className={'grid-cell-blind'}
                isEvaluator={isEvaluator}
                scoreType="blind"
                heatmapData={heatmapData}
                type={type}
            />
            <GridCell
                value={neutral}
                total={total}
                className={'grid-cell-neutral'}
                isEvaluator={isEvaluator}
                scoreType="neutral"
                heatmapData={heatmapData}
                type={type}
            />
            <GridCell
                value={responsive}
                total={total}
                className={'grid-cell-responsive'}
                isEvaluator={isEvaluator}
                scoreType="responsive"
                heatmapData={heatmapData}
                type={type}
            />
        </>
    );
};


/**
 * Combined law assessment score detail
 */
interface IScoreDetailAggregateProps {
    heatmap?: IScoreDetail;
    type: 'intention' | 'effect';
    isEvaluator?: boolean;
}

const ScoreDetailAggregate: React.FC<IScoreDetailAggregateProps> = (
    {heatmap, type, isEvaluator}
) => {
    const {questions} = useSelector((state: any) => (state.questions));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(QuestionActions.get());
    }, [dispatch]);

    if (heatmap === undefined) {
        return null;
    }
    return (
        <div className={'score-detail'}>
            <table>
                <thead>
                <tr>
                    <td/>
                    <td className={'grid-legend'}>
                        <CircleIcon color={constants.scale.na.color}/>
                        <br/>
                        {constants.scale.inconclusiveOrNA.description}
                    </td>
                    <td className={'grid-legend'}>
                        <CircleIcon color={constants.scale.regressive.color}/>
                        <br/>
                        {constants.scale.regressive.description}
                    </td>
                    <td className={'grid-legend'}>
                        <CircleIcon color={constants.scale.blind.color}/>
                        <br/>
                        Gender<br/>Blind
                    </td>
                    <td className={'grid-legend'}>
                        <CircleIcon color={constants.scale.neutral.color}/>
                        <br/>
                        Gender<br/>Neutral
                    </td>
                    <td className={'grid-legend'}>
                        <CircleIcon color={constants.scale.responsive.color}/>
                        <br/>
                        {constants.scale.responsive.description}
                    </td>
                </tr>
                </thead>
                <tbody>
                {questions.map((question: any, index: number) => {
                    const heatmapData = heatmap[type].find((item: any) => item.questionId === question.id);
                    if(!heatmapData) {
                        return null;
                    }
                    if(!heatmapData.mc) {
                        if(heatmapData.choice) {
                            heatmapData.mc = {
                                [heatmapData.choice]: 1,
                            }
                        } else {
                            return null;
                        }
                    }
                    return (
                        <tr key={question.id}>
                            <td className={'question-cell'}>{index + 1}. {question.description}</td>
                            <td className={'question-cell-short'}>
                                {index + 1}.
                            </td>
                            <GridRow
                                key={question.id}
                                heatmapData={heatmapData}
                                isEvaluator={isEvaluator}
                                type={type}
                            />
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
};
export default ScoreDetailAggregate;
