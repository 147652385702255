import React from 'react';
import CategoryItem from "../../components/search/SearchCategoryItem";
import {connect} from "react-redux";
import {getCategoryIcon} from "../../helpers/get-icons";
import './benchmarks.css'

interface ICritiriaProps {
    question: string,
    questionId: string,
    categories: any,
    criteria: string,
    standards: any,
}

interface ICriteriaState {
    categoryKey: string,
    loading: boolean
}


class CriteriaItem extends React.Component<ICritiriaProps, ICriteriaState> {

    constructor(props: any) {
        super(props);

        this.state = {
            categoryKey: "8943afb7-e379-43ba-93b4-f69e1b8257c8",
            loading: true
        }
    }

    clickCategory(categoryId: string) {
        this.setState({categoryKey: categoryId});
    }

    static getDerivedStateFromProps(props: (ICritiriaProps), state: ICriteriaState) {
        if (Object.keys(props.standards).length === 0 || props.questionId === "") {
            return {
                loading: true
            };
        } else {
            return {
                loading: false
            };
        }

    }

    componentDidUpdate(prevProps: Readonly<ICritiriaProps>, prevState: Readonly<ICriteriaState>, snapshot?: any): void {
        if (prevState.loading !== this.state.loading) {
            this.setState({loading: !prevState.loading})
        }

    }

    render(): React.ReactElement<ICritiriaProps> {
        if (this.state.loading)
            return (<p>Loading..</p>);
        else
            return (

                <div className={"criteria"}>
                    <h3>{this.props.criteria}</h3>
                    <div className={"question"}>
                        <table>
                            <tbody>
                            <tr>
                                <td>
                                    <p>{this.props.question}</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <h4>Benchmark Examples</h4>
                    <div className={"criteria-content"}>
                        <div className={"filter"}>
                            {Object.keys(this.props.categories).map(key => (
                                <CategoryItem
                                    key={key}
                                    categoryItem={{
                                        selected: key === this.state.categoryKey, // refactor
                                        icon_selected: getCategoryIcon(this.props.categories[key].identifier).selected,
                                        title: this.props.categories[key].title,
                                        id: key,
                                        icon: getCategoryIcon(this.props.categories[key].identifier).unselected
                                    }} onClick={(id: string) => {
                                    this.clickCategory(id)
                                }}/>
                            ))}
                        </div>
                        <div className={'standards-result'}>
                            <table>
                                <tbody>
                                {this.props.standards[this.props.questionId].map((item: any) => {
                                    if (item.category.id === this.state.categoryKey) {
                                        return (
                                            <tr key={item.id}>
                                                <td><p>{item.description}</p></td>
                                            </tr>
                                        );
                                    }
                                    return null;
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )
    }
}

const mapStateToProps = (state: any) => {
    return {
        categories: state.categories.categories,
        standards: state.benchmarks.benchmarks
    }
};

export default connect(mapStateToProps)(CriteriaItem);
