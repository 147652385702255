import React from "react";
import './Legend.css';
import constants from '../../../constants';
import {CircleIcon} from '../../common/circle';

interface IIndicatorProps {
    color: string,
    description: string,
}

const Indicator: React.FC<IIndicatorProps> = ({color, description}: IIndicatorProps) => (
    <div className={'elem'}>
        <CircleIcon color={color}/>
        <span>{description}</span>
    </div>
);

const Legend: React.FC = () => (
    <div className='legend'>
        <Indicator {...constants.scale.inconclusiveOrNA}/>
        <Indicator {...constants.scale.regressive}/>
        <Indicator {...constants.scale.blind}/>
        <Indicator {...constants.scale.neutral}/>
        <Indicator {...constants.scale.responsive}/>
    </div>
);

export default Legend;
