import React, {useState} from 'react';
import {Form, Formik} from 'formik';
import {AlertMessage} from '../../layout';
import {TextInput} from '../../common';
import './AuthModal.css';
import {IAuthModalProps, PanelState} from './index';
import * as Yup from 'yup';
import {Spinner} from '../../common/spinner';
import {getApiClient} from '../../../api';


type Schema = {email: string};
const initial: Schema = {email: ''};

const ForgotModal: React.FC<IAuthModalProps> = ({setPanel, close}: IAuthModalProps) => {
    const [formError, setError] = useState<string | null>();
    const [formSuccess, setSuccess] = useState<string | null>(null);

    const handle = async (values: any, {setSubmitting}: any): Promise<void> => {
        setSubmitting(true);
        try {
            setError(null);
            setSuccess(null);
            const {email} = values;
            if (!email) {throw new Error('Form failed.')}
            await getApiClient().authService.forgot(email);
            setSuccess('If this email exists a password reset is on the way. \nIt may take up to 5 minutes for it to arrive, check your spam if it\'s not in your inbox.');
        } catch (e) {
            console.log(e);
            if (e.response) {
                if (e.response.status === 400) {
                    // User doesn't exist but mock success
                    setSuccess('If this email exists a password reset is on the way. \nIt may take up to 5 minutes for it to arrive, check your spam if it\'s not in your inbox.');
                    return;
                }
            }
            setError('Failed to reset password.');
        }
        setSubmitting(false);
    };

    const validation = Yup.object().shape({
        email: Yup.string()
            .email()
            .required('Required'),
    });

    return (
        <div className='auth-form'>
            <div className={'header-space'}>
            <h3>Reset password</h3>
            </div>
            <br/>
            <Formik
                initialValues={initial}
                validationSchema={validation}
                onSubmit={handle}>
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                  }) =>
                    <Form onSubmit={handleSubmit}>
                        <TextInput
                            name={'email'}
                            label={'Email'}
                            type={'email'}
                            warning={(errors.email && touched.email) && errors.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}/>
                        <div className={'warning-space'}>
                            <AlertMessage className={'error'} message={formError}/>
                        </div>
                        <div className={'warning-space'}>
                            <AlertMessage className={'success'} message={formSuccess}/>
                        </div>
                        <div className='button-wrapper'>
                            <button className={'submit'} type={'submit'} disabled={isSubmitting} onClick={() => handleSubmit()}>
                                {(isSubmitting) && <Spinner/>}Request Reset</button>
                        </div>
                        <div className={'links-space'}>
                            <p>
                                Remember your password? <button className={'links'}  onClick={() => setPanel(PanelState.LOGIN)}> Sign In</button>
                            </p>
                            <br/>
                        </div>
                    </Form>
                }
            </Formik>
        </div>
    );
};
export default ForgotModal;
