import {getApiClient} from '../../../../api';
import {LawSchema} from './LawForm';
import {useState} from 'react';
import {IFileDto, ILawDto} from '../../../../api/services/lawsService';

const useLawUpload = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0); // percentage

    const addNewLaw = async (schema: LawSchema): Promise<string /* law id */> => {
        setProgress(0);
        setLoading(true);

        // Upload file
        if (schema.file === undefined) { throw new Error('File cannot be null'); }
        const key = await uploadFile(schema.file);

        // Add law
        const law: ILawDto & IFileDto = {
            title: schema.title,
            countryId: schema.country,
            year: schema.year,
            lawNumber: schema.lawNumber,
            primaryCategory: schema.primaryCategory,
            categories: schema.categories,
            filekey: key,
            originalfilename: schema.file.name,
        };
        const response = await getApiClient().lawsService.create(law);

        setProgress(100);
        setLoading(false);
        return response.data.id;
    };

    const updateLaw = async (lawId: string, schema: LawSchema): Promise<string> => {
        setProgress(0);
        setLoading(true);



        // Upload and update file
        if (schema.file !== undefined) {
            const key = await uploadFile(schema.file);
            await getApiClient().lawsService.updateFile(lawId, {filekey: key, originalfilename: schema.file.name});
        }

        // construct law schema
        let law: ILawDto = {
            title: schema.title,
            countryId: schema.country,
            year: schema.year,
            lawNumber: schema.lawNumber,
            primaryCategory: schema.primaryCategory,
            categories: schema.categories,
        };

        const response = await getApiClient().lawsService.update(lawId, law);

        setProgress(100);
        setLoading(false);
        return response.data.id;
    };

    const uploadFile = async (file: File): Promise<string> => {
        const uploadUrl = await getApiClient().storageService.getPresignedUrl(file.name);
        const {key, url} = uploadUrl.data;
        if (key === undefined || url === undefined) { throw new Error('Presigned url malformed'); }
        await getApiClient().storageService.uploadFile(url, file, (progressEvent: ProgressEvent) => {
            setProgress(Math.round((progressEvent.loaded*100.0) / progressEvent.total* 100.0));
        });
        return key;
    };

    return {addNewLaw, updateLaw, loading, progress};
};

export default useLawUpload;
