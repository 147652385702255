import React from "react";
import {Link, Text, View} from "@react-pdf/renderer";
import {getScore} from "../../../helpers/get-score";
import {createStylesheet} from "./styleHelper";
import {commonStylesheet} from "./EvaluationDocument";

const stylesheet = createStylesheet({
    score: {
        marginTop: 25,
        display: 'flex',
        alignItems: 'flex-start',
    },
    scoreBox: {
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: '#000000',
        padding: '13px 8px',
        margin: '16px 0',
    },
});

interface IOverallScoreProps {
    score: number | undefined,
    evaluatorCount: number,
}

export const OverallScore: React.FC<IOverallScoreProps> = ({score, evaluatorCount}) => (
    <View style={[commonStylesheet.text, stylesheet.score]}>
        <Text style={commonStylesheet.title}>Score based on international standards</Text>
        <View style={stylesheet.scoreBox}>
            <Text>{
                score === undefined
                    ? "Law not yet evaluated" :
                    getScore(score).description
            }</Text>
        </View>
        <Text>
            This law has been scored by {evaluatorCount} evaluators. To learn more about how this score is calculated,
            visit <Link src="https://www.genderlawindex.org/scoring">Scoring</Link>.
        </Text>
        <Text>&nbsp;</Text>
        <Text>
            In every case, the law has been scored on its textual meaning only. No attention has been given to
            implementation as a fair evaluation would not be possible based on the information at hand. However, the GLI
            considers the distinction between intention and likely effect pivotal in many cases. Evaluators have
            considered both the stated or implicit intention of the law, as well as its likely effect, based exclusively
            on the text of the law. No consideration has been given to the particular cultural, political, social or
            demographic situations of that particular country.
        </Text>
    </View>
);
