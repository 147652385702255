import React, {useEffect, useRef, useState} from 'react';
import './Dropdown.css';
import {useField} from 'formik';
import useOutsideAlerter from '../../../helpers/hooks/OutsideAlert';
import {DropdownMenu, IDropdownOptions} from './BaseDropdown';
import {general} from '../../../assets';

interface IDropdownProps {
    name?: string;
    onChange?: (value: string) => void;
    options?: IDropdownOptions[];
    onBlur?: (value: any) => any; // Note: Potentially unused
    warning?: any;
    className?: 'small' | '';
    label?: string;
    flipped?: boolean;
    scrollToMenu?: boolean;
}
const FormMultiDropdown: React.FC<IDropdownProps> = (
    {
        name = '',
        options = [],
        onChange,
        className = '',
        label = '',
        warning,
        onBlur,
        flipped,
        scrollToMenu,
    }) => {
    // Sets visibility of menu
    const [visible, setVisible] = useState(false);

    // Used for form validation
    // Note: "field" is not used but is needed for Formik field to work
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [field, meta, helpers] = useField(name);

    // Closes menu on click outside the dropdown
    const wrapperRef = useRef<HTMLDivElement>(null);
    useOutsideAlerter(wrapperRef, () => setVisible(false));

    const menuRef = useRef<HTMLUListElement>(null);
    useEffect(() => {
        if(scrollToMenu && visible) {
            if(menuRef?.current) {
                menuRef.current.scrollIntoView({behavior: 'smooth', block: "nearest"});
            }
        }
    }, [scrollToMenu, visible]);

    /**
     * Handles updating change
     */
    const handleChange = (val: string) => {
        helpers.setValue([...meta.value, val]);
        // helpers.setTouched(true);
    };

    const handleRemove = (val: string) => {
        helpers.setValue(meta.value.filter((item: string) => item !== val));
    };

    /**
     * Used to retrieve the current selected details
     */
    const getSelected = (selectedId: string): IDropdownOptions => {
        const selected = options.find((item) => item.value === selectedId);
        return (selected) ? selected : {value: selectedId, description: selectedId};
    };

    const getOptions = () => {
        return options.filter((option: IDropdownOptions) => !meta.value.includes(option.value));
    };

    const setVisibility = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!e.defaultPrevented) {
            setVisible(!visible);
        }
    };

    return (
        <div onBlur={() => helpers.setTouched(true)}>
            <div ref={wrapperRef} className={`dropdown ${flipped ? 'flipped' : ''}`} onBlur={onBlur}>
                <div
                    className={`select ${visible ? 'active' : ''}  ${warning ? 'warning' : ''} multi`}
                    onClick={setVisibility}>
                    <div className={'multi-overflow-preventer'}>
                        {(meta.value.length === 0) && (
                            <p className={'greyed multi-placeholder'}>{label}</p>
                        )}
                        {(
                            meta.value.map((item: string) => (
                                <div
                                    key={`list ${item}`}
                                    className={'multi-label'}
                                >
                                    <img onClick={(e) => {
                                        e.preventDefault();
                                        handleRemove(getSelected(item).value)
                                    }} className='dropdown-cross' src={general.cross} alt='x'/>
                                    &nbsp;
                                    {getSelected(item).img &&
                                        <img
                                            className={'icon'}
                                            src={getSelected(item).img}
                                            alt=''
                                        />
                                    }
                                    <span>{getSelected(item).description}</span>
                                </div>
                            ))
                        )}
                    </div>
                    <img className={'indicator-multi'} src={general.dropdown} alt='^'/>
                </div>

                <DropdownMenu
                    ref={menuRef}
                    visible={visible}
                    options={getOptions()}
                    className={className}
                    handleChange={handleChange}
                    width={wrapperRef.current?.clientWidth}/>
            </div>
        </div>
    );
};

export default FormMultiDropdown;
