import {IRouteConfig} from '../../helpers/interfaces';
import Scoring from './Scoring';

const PREFIX = '/scoring';

const routes: IRouteConfig[] = [
    {
        prefix: PREFIX,
        path: '',
        component: Scoring,
    },
];

export default routes;
