import React from 'react';
import './About.css';
import {branding, general} from '../../../assets';
import {ILaw} from '../../../helpers/interfaces';
import { Link } from 'react-router-dom';
import {useSelector} from 'react-redux';
import {UserRole} from '../../../helpers/enums';
import {roleCheck} from '../../../helpers';
import { getCountryIcon } from '../../../helpers/get-icons';

const About = ({law}: {law: ILaw | null}) => {
    const {user} = useSelector((state: any) => (state.auth));

    if (!law) {
        return <div className='law-about'><p>loading...</p></div>;
    }
    return (
    <div className='law-about'>
        <h3>About this Legislation</h3>
        <div className='grid'>
            <img src={general.globe} alt='globe'/>
            <span className='field'>Jurisdiction: </span>
            <span className='val country'>
                <img src={getCountryIcon(law.country.identifier)} alt={`${law.country.title} flag`}/>
                {law?.country.title}
            </span>

            <img src={general.calendar} alt='calendar'/>
            <span className='field'>Year Enacted: </span>
            <span className='val'>{law?.year}</span>

            <img src={general.hash} alt='hash'/>
            <span className='field'>Number: </span>
            <span className='val'>{law?.lawNumber}</span>

            <img src={general.pdf} alt='pdf'/>
            <span className='both'><button className='button-link' onClick={() => window.open(law.url, '_blank')}>View Legislation (PDF)</button></span>
            {user && roleCheck(user.role, UserRole.EVALUATOR) && (
                <>
                    <img src={general.pen} alt='pen'/>
                    <span className='both'><Link className={'both'} to={`/laws/${law.id}/evaluate`}>Evaluate this Legislation</Link></span>
                </>
            )}

            {user && roleCheck(user.role, UserRole.ARCHITECT) && (
                <>
                    <img src={branding.edit_law} alt='pen'/>
                    <span className='both'><Link className={'both'} to={`/profile/edit-law/${law.id}`}>Edit this Legislation</Link></span>
                </>
            )}
        </div>
    </div>
);
}

export default About;
