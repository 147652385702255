import React, {useState} from 'react';
import {Form, Formik} from 'formik';
import {AlertMessage} from '../../layout';
import {TextInput} from '../../common';
import './AuthModal.css';
import {Spinner} from '../../common/spinner';
import * as Yup from 'yup';
import {FormDropdown} from '../../common/dropdown';
import {getApiClient} from '../../../api';


interface IRegisterModalProps {
    close?: () => void;
    refresh?: () => void;
}


type Schema = { firstName: string, lastName: string, email: string, role: string, password: string };
const initial: Schema = {firstName: '', lastName: '', email: '', role: 'evaluator', password: ''};
const NewUserModal: React.FC<IRegisterModalProps> = ({close, refresh}: IRegisterModalProps) => {

    const [error, setError] = useState(null);

    const handle = async (values: any): Promise<void> => {
        try {
            await getApiClient().usersService.register(values);
            if (refresh) {
                refresh();
            }
            if (close) {
                close();
            }
        } catch (e) {
            setError(e);
        }
    };

    const validation = Yup.object().shape({
        firstName: Yup.string()
            .required('Required'),
        lastName: Yup.string()
            .required('Required'),
        email: Yup.string()
            .email('Invalid email')
            .required('Required'),
        role: Yup.string()
            .required('Required'),
        password: Yup.string()
            .required('Required')
            .min(8),
    });

    return (
        <div className='auth-form'>
            <div className={'header-space'}>
                <h3>Manage Account</h3>
            </div>
            <Formik
                initialValues={initial}
                validationSchema={validation}
                onSubmit={handle}>
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                  }) =>
                    <Form onSubmit={handleSubmit} autoComplete={'off'}>
                        <div className={'input-space'}>
                            <TextInput
                                name={'firstName'}
                                label={'First Name'}
                                type={'text'}
                                warning={errors.firstName && touched.firstName && errors.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.firstName}
                                autocomplete={'off'}/>
                        </div>
                        <div className={'input-space'}>
                            <TextInput
                                name={'lastName'}
                                label={'Last Name'}
                                type={'text'}
                                warning={errors.lastName && touched.lastName && errors.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.lastName}
                                autocomplete={'off'}/>
                        </div>
                        <div className={'input-space'}>
                            <TextInput
                                name={'email'}
                                label={'Email'}
                                type={'text'}
                                warning={errors.email && touched.email && errors.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                autocomplete={'off'}/>
                        </div>
                        <div className={'input-space'}>
                            <FormDropdown
                                name={'role'}
                                onChange={handleChange}
                                warning={(errors.role && touched.role) && errors.role}
                                options={[{
                                    value: 'evaluator',
                                    description: 'Evaluator',
                                }, {
                                    value: 'architect',
                                    description: 'Architect',
                                }]}/>
                        </div>
                        <div className={'input-space'}>
                            <TextInput
                                name={'password'}
                                label={'Password'}
                                type={'password'}
                                warning={errors.password && touched.password && errors.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                autocomplete={'off'}/>
                        </div>
                        <div className={'warning-space'}>
                            <AlertMessage className={'error'} message={error}/>
                        </div>
                        <button
                            type='submit'
                            className={'submit'}
                            disabled={isSubmitting}
                            onClick={() => handleSubmit()}>
                            {(isSubmitting) && <Spinner/>}Add User
                        </button>
                        <div className={'links-space'}/>
                    </Form>
                }
            </Formik>
        </div>
    );
};
export default NewUserModal;
