import React from 'react';

interface IPointerProps {
    className?: string;
}
const Pointer = ({className}: IPointerProps) => (
    <svg width="12px" height="17px" viewBox="0 0 12 17" version="1.1">
        <title>Rectangle</title>
        <desc>Created with Sketch.</desc>
        <g id="Law-Detail" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Law-Detail---Group-Evaluation"  className={className} transform="translate(-977.000000, -607.000000)" fill="#000">
                <g id="International-Score-(summary)" transform="translate(967.000000, 522.000000)">
                    <g id="Benchmark" transform="translate(0.000000, 32.000000)">
                        <path d="M18.5806769,58.5619843 L18.7384336,67.0665082 C18.7589196,68.1708877 17.8802503,69.0827714 16.7758708,69.1032573 C16.7511441,69.103716 16.7264111,69.103716 16.7016845,69.1032573 L8.19716062,68.9455006 C7.09278111,68.9250146 6.21411178,68.013131 6.23459774,66.9087514 C6.24419726,66.3912501 6.45404848,65.8976227 6.82004017,65.531631 L15.1668073,57.1848639 C15.9478559,56.4038153 17.2141859,56.4038153 17.9952344,57.1848639 C18.3612261,57.5508555 18.5710774,58.0444829 18.5806769,58.5619843 Z" id="Rectangle" transform="translate(11.135176, 61.500000) rotate(-45.000000) translate(-11.135176, -61.500000) "></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
export default Pointer;
