import React from 'react';
import CategoryItem from "./SearchCategoryItem";
import {useSelector} from 'react-redux';
import {getCategoryIcon} from '../../helpers/get-icons';
import {branding} from '../../assets';
import './SearchFilterList.css';

interface ISearchFilterProps {
    value: string;
    onSubmit: (countryId: string) => Promise<void>;
}

const SearchCategoryFilter: React.FC<ISearchFilterProps> = ({value, onSubmit}) => {
    const {categories} = useSelector((state: any) => (state.categories));
    const {user} = useSelector((state: any) => (state.auth));

    return (
        <div className="search-filter-list">
            <h4 className="search-filter-title">Category</h4>
            <div className="search-filter-items">
                <CategoryItem
                    key={'clear-category'}
                    onClick={() => onSubmit('')}
                    categoryItem={{
                        selected: '' === value,
                        icon_selected: branding.all_categories_orange,
                        title: 'All Categories',
                        id: 'category-all',
                        icon: branding.all_categories_grey,
                    }}/>
                <CategoryItem
                    key={'blog'}
                    onClick={() => onSubmit('blog')}
                    categoryItem={{
                        selected: 'blog' === value,
                        icon_selected: branding.my_evaluation_orange,
                        title: 'Insights',
                        id: 'blog',
                        icon: branding.my_evaluation_grey,
                    }}/>
                {!!user && <CategoryItem
                    key={'pending'}
                    onClick={() => onSubmit('pending')}
                    categoryItem={{
                        selected: 'pending' === value,
                        icon_selected: branding.pending_evaluation_orange,
                        title: 'Pending Evaluation',
                        id: 'pending',
                        icon: branding.pending_evaluation_grey,
                    }}/>}
                {Object.keys(categories).map((key) =>
                    <CategoryItem
                        key={key}
                        onClick={() => onSubmit(key)}
                        categoryItem={{
                            selected: key === value,
                            icon_selected: getCategoryIcon(categories[key].identifier).selected,
                            title: categories[key].title,
                            id: key,
                            icon: getCategoryIcon(categories[key].identifier).unselected,
                        }}/>)}
            </div>
        </div>
    );
}

export default SearchCategoryFilter;
