import React from 'react';
import './Breakdown.css';
import {general} from '../../../assets';
import {useParams, Link} from 'react-router-dom';
import {ICompactAssessment} from '../../../helpers/interfaces';
import {useSelector} from 'react-redux';
import { ScorePill } from '../../common/score-pill';

interface IEvaluatorProps {
    order: number,
    score: string,
    assessmentId: string,
    user?: any;
}

interface IEvaluatorParams {
    lawId: string,
}

const Evaluator: React.FC<IEvaluatorProps> = ({order, score, assessmentId, user}: IEvaluatorProps) => {
    const auth = useSelector((state: any) => (state.auth));
    const {lawId} = useParams<IEvaluatorParams>();

    return (
        <div className="evaluator-item">
            <img className='icon' src={general.person} alt='person'/>
            <span>Evaluator [{order}] <Link to={`/laws/${lawId}/${assessmentId}`}>view benchmark analysis</Link>.</span>
            {user && (
                <p className="evaluator-name">
                    {user.email} {(auth.user?.id === user.id) ? '(your assessment)' : null}
                </p>
            )}
            <div className="evaluator-score">
                <ScorePill score={score}/>
            </div>
        </div>
    );
};


interface IBreakdownProps {
    assessments?: ICompactAssessment[];
}

const Breakdown: React.FC<IBreakdownProps> = ({assessments}: IBreakdownProps) => {
    if (!assessments || assessments.length === 0) {
        return null;
    }
    return (
        <div className={'breakdown'}>
            <h3>International Score (breakdown)</h3>
            {assessments.map((assessment, index) => (
                <Evaluator
                    key={index + 1}
                    order={index + 1}
                    score={assessment.score}
                    assessmentId={assessment.id}
                    user={assessment.user}/>)
            )}
        </div>
    );
}

export default Breakdown;
