import React, {useState} from 'react';
import './EvaluationPanel.css';
import QuestionForm, {IQuestionSchema} from './QuestionForm';
import {useSelector} from 'react-redux';
import {getApiClient} from '../../../api';
import {useHistory} from 'react-router-dom';
import {AlertMessage} from '../../layout';

interface IEvaluationPanelProps {
    lawId: string;
    categories: string[]; // Used to get relevant standards
}
const EvaluationPanel: React.FC<IEvaluationPanelProps> = ({lawId, categories}) => {
    const {questions} = useSelector((state: any) => (state.questions));
    const [error, setError] = useState(undefined);
    const history = useHistory();

    const onSubmit = async (schema: IQuestionSchema): Promise<void> => {

        try {
            setError(undefined);
            const assessment = await getApiClient().assessService.submit(lawId, schema);
            sessionStorage.removeItem(`evaluation-${lawId}`);
            history.push(`/laws/${lawId}/${assessment.data.id}`)
        } catch (e) {
            setError(e);
        }
    }

    if (questions) {
        return (
            <div className={'evaluation-panel'}>
                <QuestionForm questions={questions} lawId={lawId} onSubmit={onSubmit} categories={categories}/>
                <AlertMessage className={'error'} title={'Failed to send assessment'} message={error}/>
            </div>
        );
    } else {
        return <p>loading...</p>
    }
};

export default EvaluationPanel;
