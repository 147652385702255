import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import constants from '../constants';

let middleware;
if (constants.NODE_ENV === 'development') {
    middleware = composeWithDevTools(applyMiddleware(thunk));
} else {
    middleware = applyMiddleware(thunk);
}
const store = createStore(rootReducer, middleware);

export default store;
