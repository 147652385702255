import {Payload} from '../../helpers/create-action';
import {CATEGORIES_ACTIONS} from '../actions';
import {arrayToMap} from '../../helpers';

export interface CategoriesState {
    loading: boolean;
    categories: ICategoryMap,
    error: any | null;
}

interface ICategoryMap {
    [key: string]: {
        title: string;
        identifier: string;
        createdDate: string;
        updatedDate: string;
    };
}

const initialState: CategoriesState = {
    loading: false,
    categories: {},
    error: null,
};

export default (state: CategoriesState = initialState, {type, payload}: Payload): CategoriesState => {
    switch (type) {
        case CATEGORIES_ACTIONS.CATEGORIES_REQUEST:
            return {...state, loading: true, error: null};
        case CATEGORIES_ACTIONS.CATEGORIES_SUCCESS:
            return {...state, loading: false, categories: arrayToMap(payload), error: null};
        case CATEGORIES_ACTIONS.CATEGORIES_FAILURE:
            return {...state, loading: false, error: payload};
        default:
            return state;
    }
}
