import React, {useEffect} from 'react';
import './Modal.css';
import {general} from '../../../assets';

interface IModalProps {
    children: JSX.Element,
    visible: boolean,
    size?: 'auto' | 'small' | 'medium' | 'large',
    toggle: () => void,
    canClose?: boolean;
}

/**
 * Modal without state
 */
const Modal: React.FC<IModalProps> = (props) => {
    const {
        visible,
        children,
        size = 'small',
        toggle,
        canClose = true
    } = props;
    const parentClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!e.isDefaultPrevented()) {
            toggle();
        }
    };

    useEffect(() => {
        if(visible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
        return () => {
            document.body.style.overflow = 'unset';
        }
    }, [visible])

    return (
        <>
            {visible && (
                <div className='modal' onClick={parentClick}>
                    <div className={`modal-content ${(size === 'auto') ? '' : size}`} onClick={(e: React.MouseEvent<HTMLDivElement>) => e.preventDefault()}>
                        {canClose && <img onClick={toggle} className='modal-cross' src={general.cross} alt='x'/>}
                        {children}
                    </div>
                </div>
            )}
        </>
    )
};
export default Modal;
