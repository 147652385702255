import React from 'react';
import "./featuredlaws.css"
import {getApiClient} from "../../api";
import {ILaw} from "../../helpers/interfaces";
import LawItem from "../common/law-item/LawItem";
import {AlertMessage} from '../layout';

interface IFeaturedLawsState {
    error?: any;
    laws: ILaw [];
    loading: boolean;
}

interface IFeaturedLawsProps {
    options: 'highlighted' | 'pending' | 'blog';
}

class FeaturedLaws extends React.Component<IFeaturedLawsProps, IFeaturedLawsState> {

    constructor(props: IFeaturedLawsProps) {
        super(props);
        this.state = {
            laws: [],
            loading: true,
        }
    }

    async getLaws(): Promise<ILaw [] | undefined> {
        try {
            let response;
            switch (this.props.options) {
                case 'highlighted':
                    response = await getApiClient().lawsService.getRecentEvaluation();
                    return response.data;
                case 'pending':
                    response = await getApiClient().lawsService.all({
                        country: '',
                        category: '',
                        query: '',
                        page: '',
                        sortingName: '',
                        sortingDir: '',
                        limit: 4
                    }, 'pending');
                    return response.data.items;
                case 'blog':
                    response = await getApiClient().lawsService.all({
                        country: '',
                        category: '',
                        query: '',
                        page: '',
                        sortingName: '',
                        sortingDir: '',
                        limit: 4
                    }, 'blog');
                    return response.data.items;
                default:
                    return undefined;
            }
        } catch (e) {
            if (e.response) {
                if (e.response.data) {
                    this.setState({error: e.response.data.message, loading: false});
                    return;
                }
            }
            this.setState({error: e, loading: false});
        }
    }

    getTitle(): string {
        switch (this.props.options) {
            case 'pending':
                return 'Pending Evaluations';
            case 'highlighted':
                return 'Recently evaluated laws';
            case 'blog':
                return 'Recent Insights';
            default:
                return '';
        }
    }

    async componentDidMount(): Promise<void> {
        let laws = await this.getLaws();
        if (laws)
            this.setState({laws: laws, loading: false})
    }

    render() {
        const {laws, error, loading} = this.state;
        return (
            <div className="featured-laws">
                <h3>{this.getTitle()}</h3>
                <table className="recent-evaluation">
                    <tbody>
                        {laws.map(law => (
                            <LawItem key={law.id} law={law}/>)
                        )}
                        {(laws && laws.length === 0 && !loading) && (
                            <tr className="featured-empty">
                                <td>
                                    <p>No laws here at the moment, check back soon.</p>
                                </td>
                            </tr>
                        )}
                        {error && (
                            <tr>
                                <td>
                                    <AlertMessage className="error" message={error}/>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default FeaturedLaws
