import calendar from './calendar.svg';
import globe from './globe.svg';
import globe_grey from './globe_grey.svg';
import globe_blue from './globe_blue.svg';
import globe_orange from './globe_orange.svg';
import hash from './hash.svg';
import pdf from './pdf.svg';
import pen from './pen.svg';
import pen_grey from './pen_grey.svg';
import pen_blue from './pen_blue.svg';
import pointer from './pointerWrapper';
import pointer_unfilled from './pointer_unfilled.svg';
import person from './person.svg';
import speech from './speech.svg';
import tooltipPoint from './tooltip-point.svg';
import cross from './cross.svg';
import dropdown from './dropdown.svg';
import star from './star.svg';
import star_unfilled from './star-unfilled.svg';
import search from './search.svg';
import evaluation from './evaluation';
import hamburger from './hamburger.svg';
import iris_scan from './iris-scan.svg';
import iris_scan_grey from './iris-scan_grey.svg';
import filter from './filter.svg';
import info from './info.svg';
import uni_logo from './uni-logo.svg';
import tick from './tick.svg';
import tick_grey from './tick_grey.svg';
import info_grey from './info-circle-grey.svg';
import info_blue from './info-circle-blue.svg';
import info_orange from './info-circle-orange.svg';
import document_icon from './document.svg';

export default {
    calendar,
    globe,
    hash,
    pdf,
    pen,
    pen_grey,
    pen_blue,
    pointer,
    pointer_unfilled,
    person,
    speech,
    tooltipPoint,
    globe_grey,
    globe_blue,
    globe_orange,
    cross,
    dropdown,
    star,
    star_unfilled,
    search,
    evaluation,
    hamburger,
    iris_scan,
    iris_scan_grey,
    filter,
    info,
    uni_logo,

    tick,
    tick_grey,
    info_grey,
    info_blue,
    info_orange,
    document_icon,
};
