const scale = {
    regressive: {
        description: 'Gender Regressive',
        color: '#FF7E78',
    },
    blind: {
        description: 'Gender Blind',
        color: '#F7C346',
    },
    neutral: {
        description: 'Gender Neutral',
        color: '#74E8E2',
    },
    responsive: {
        description: 'Gender Responsive',
        color: '#9EE25A',
    },
    inconclusiveOrNA: {
        description: 'Inconclusive or NA',
        color: '#858F8E',
    },
    inconclusive: {
        description: 'Inconclusive',
        color: '#858F8E',
    },
    na: {
        description: 'NA',
        color: '#858F8E',
    },
};

export default {
    NODE_ENV: process.env.NODE_ENV || 'production',
    BASE_URL: (process.env.NODE_ENV === 'production') ? 'https://api.genderlawindex.org/api/v1' : 'http://localhost:3001/api/v1',
    scale: scale,
    IS_PERCENTAGE: false,
    TITLE: 'Gender Law Index',
}
