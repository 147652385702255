import React, {useState} from 'react';
import {TextInput} from '../../../common';
import './EditProfile.css';
import '../../../submit/Submit.css';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {useDispatch, useSelector} from 'react-redux';
import {Spinner} from '../../../common/spinner';
import {AlertMessage} from '../../../layout';
import {getApiClient} from '../../../../api';
import {AuthActions} from '../../../../state/actions';

interface ITableRow {
    label: string,
    name: string,
    value?: string,
    onChange?: any,
    onBlur?: any,
    type?: string,
    warning?: any
}

const TableRow: React.FC<ITableRow> = ({label, name, value, onChange, onBlur, type = 'text', warning}) => (
    <tr className={'profile-edit'}>
        <td>
            <p className={'profile-label'}>{label}</p>
            <div className={'edit-div'}>
            <TextInput
                label={label}
                name={name}
                value={value}
                type={type}
                warning={warning}
                onChange={onChange}
                onBlur={onBlur}/>
            </div>
        </td>
    </tr>
);


type Schema = { firstName: string, lastName: string };
const EditProfile: React.FC = () => {
    const {user} = useSelector((state: any) => (state.auth));
    const [formError, setError] = useState(null);
    const dispatch = useDispatch();

    const handle = async (values: Schema): Promise<void> => {
        try {
            setError(null);
            const {firstName, lastName} = values;
            if (!firstName || !lastName) {throw new Error('Form failed.')}
            await getApiClient().usersService.update(values.firstName, values.lastName);
            dispatch(AuthActions.extend());
        } catch (e) {
            setError(e);
        }
    };

    const validation = Yup.object().shape({
        firstName: Yup.string()
            .required('Required'),
        lastName: Yup.string()
            .required('Required'),
    });

    if (!user) {
        return <p>Please sign in again.</p>
    }

    const initial = {firstName: user.firstName, lastName: user.lastName};

    return (
        <Formik
            initialValues={initial}
            validationSchema={validation}
            onSubmit={handle}>
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  isSubmitting,
              }) =>
                <Form>
                    <h3>Profile Details</h3>
                    <table className={'profile-edit'}>
                        <tbody>
                            <TableRow
                                label={'First Name'}
                                name={'firstName'}
                                key={'firstName'}
                                warning={(errors.firstName && touched.firstName) && errors.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.firstName}/>
                            <TableRow
                                label={'Last Name'}
                                name={'lastName'}
                                key={'lastName'}
                                warning={(errors.lastName && touched.lastName) && errors.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.lastName}/>
                            <tr className={'profile-edit'}>
                                <td>
                                    <p className={'profile-label'}>Email</p>
                                    <div className={'edit-div'}>
                                        <span className={'profile-email'}>{user.email}</span>
                                    </div>
                                </td>
                            </tr>
                            {(initial.firstName !== values.firstName || initial.lastName !== values.lastName) && <tr>
                                <td colSpan={2}>
                                    {(isSubmitting) && <Spinner/>}
                                    <button className={'submit'} disabled={isSubmitting || (initial.firstName === values.firstName && initial.lastName === values.lastName)}>Update Details</button>
                                </td>
                            </tr>}
                            <tr>
                                <td colSpan={2}>
                                    <>
                                    <div className={'warning-space'}>
                                        <AlertMessage className={'error'} message={formError}/>
                                    </div>
                                    </>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Form>
            }
        </Formik>

    );
};

export default EditProfile;
