import { createAction } from '../../helpers';
import {Dispatch} from 'redux';
import {getApiClient} from '../../api';
import {IUser} from '../../helpers/interfaces';
import {AxiosError} from 'axios';

export const AUTH_ACTIONS = {
    LOGIN_REQUESTED: 'USER_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    ME_REQUESTED: 'ME_REQUESTED',
    ME_SUCCESS: 'ME_SUCCESS',
    LOGOUT: 'LOGOUT',
};

const AuthActions = {
    loginRequested: () => createAction(AUTH_ACTIONS.LOGIN_REQUESTED),
    loginSuccess: () => createAction(AUTH_ACTIONS.LOGIN_SUCCESS),
    loginFailure: (error: AxiosError) => createAction(AUTH_ACTIONS.LOGIN_FAILURE, error),
    meRequested: () => createAction(AUTH_ACTIONS.ME_REQUESTED),
    meSuccess: (user: IUser) => createAction(AUTH_ACTIONS.ME_SUCCESS, user),
    logout: () => createAction(AUTH_ACTIONS.LOGOUT),
};

/**
 * Called to repopulate user into app
 */
const extend = () => async (dispatch: Dispatch) => {
    if (!getApiClient().authService.isTokenValid()) {
        dispatch(AuthActions.logout());
        getApiClient().authService.logout();
        return;
    }
    try {
        // Extend user
        dispatch(AuthActions.loginRequested());
        await getApiClient().authService.extend();
        dispatch(AuthActions.loginSuccess());

        // Retrieve user's profile
        dispatch(AuthActions.meRequested());
        const user = await getApiClient().usersService.getMe();
        dispatch(AuthActions.meSuccess(user.data));
    } catch (e) {
        dispatch(AuthActions.loginFailure(e));

        // Logout user if the error is not network related
        if (e.message !== "Network Error") {
            dispatch(AuthActions.logout());
            getApiClient().authService.logout();
        }
    }
};

/**
 * Logs a user in given a username and password
 *
 * Note: due to hanging issue, successCb is called to trigger modal closing
 */
const login = (email: string, password: string, successCb?: () => void) => async (dispatch: Dispatch): Promise<void> => {
    try {
        // Login user
        await dispatch(AuthActions.loginRequested());
        await getApiClient().authService.login(email, password);
        await dispatch(AuthActions.loginSuccess());

        // Retrieve user's profile
        await dispatch(AuthActions.meRequested());
        const user = await getApiClient().usersService.getMe();
        await dispatch(AuthActions.meSuccess(user.data));
        if (successCb) {
            successCb();
        }
    } catch (e) {
        dispatch(AuthActions.loginFailure(e));

        // Logout user if the error is not network related
        if (e.message !== "Network Error") {
            await dispatch(AuthActions.logout());
            getApiClient().authService.logout();
        }
    }
};

/**
 * Logout user and remove their profile
 */
const logout = () => (dispatch: Dispatch) => {
    dispatch(AuthActions.logout());
    getApiClient().authService.logout();
};

export default {
    login,
    logout,
    extend,
};
