import {IQuestion, IScoreDetailMC} from "../../../helpers/interfaces";
import React from "react";
import {Text, View, Image} from "@react-pdf/renderer";
import {createStylesheet} from "./styleHelper";
import {commonStylesheet} from "./EvaluationDocument";
import constants from "../../../constants";
import {pdfImages} from "../../../assets";

const borderWidth = 1;
const backgroundGrey = '#fafafa';
const stylesheet = createStylesheet({
    heatmapGrid: {
        width: '100%',
    },
    row: {
        flexDirection: 'row',
    },
    question: {
        width: '40%',
        padding: 5,
        paddingRight: 0,
    },
    questionOdd: {
        backgroundColor: backgroundGrey,
    },
    marginFixer: {
        // Fill space created by overlapping cell margins
        width: borderWidth * 5,
    },
    legendCell: {
        width: '12%',
        margin: -(borderWidth / 2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 5,
        backgroundColor: backgroundGrey,
    },
    legendCircle: {
        width: 10,
        height: 10,
        borderRadius: 10,
    },
    legendCellText: {
        textAlign: 'center',
        width: '100%',
        marginTop: 5,
        fontSize: 8,
    },
    legendCellLeft: {
        borderTopLeftRadius: 10,
    },
    legendCellRight: {
        borderTopRightRadius: 10,
    },
    cell: {
        width: '12%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: borderWidth,
        borderStyle: 'solid',
        borderColor: '#BBBBBB',
        // Make borders overlap
        margin: -(borderWidth / 2),
    },
    cellBackground: {
        position: 'absolute',
        left: -(borderWidth / 2),
        right: -(borderWidth / 2),
        top: -(borderWidth / 2),
        bottom: -(borderWidth / 2),
    },
    cellText: {
    },
    cellTextEmpty: {
        opacity: 0.3,
    },
    cellTick: {
        width: 10,
        height: 10,
    }
});

interface IQuestionProps {
    question: IQuestion;
    index: number;
}

const Question: React.FC<IQuestionProps> = ({question, index}) => {
    const styles = [stylesheet.question];
    if(index % 2 === 1) {
        styles.push(stylesheet.questionOdd);
    }
    return (
        <View style={styles}>
            <Text>{index + 1}. {question.description}</Text>
        </View>
    );
};

interface ICellProps {
    count: number;
    total: number;
    variant: keyof typeof constants.scale;
    showRatio?: boolean;
}

const Cell: React.FC<ICellProps> = (props) => {
    const {
        count,
        total,
        variant,
        showRatio = true,
    } = props;
    const backgroundStyles = [stylesheet.cellBackground, {
        backgroundColor: constants.scale[variant].color,
        opacity: Math.pow(count / total, 1/0.75), // see ScoreDetailAggregate.GridCell
    }];
    const textStyles = [
        stylesheet.cellText,
    ];
    const isEmpty = count === 0;
    if(isEmpty) {
        textStyles.push(stylesheet.cellTextEmpty);
    }

    return (
        <View style={stylesheet.cell}>
            <View style={backgroundStyles}/>
            {showRatio
                ? <Text style={textStyles}>{count}/{total}</Text>
                : (
                    !isEmpty && <Image style={stylesheet.cellTick} src={pdfImages.tick}/>
                )
            }
        </View>
    );
}

interface IMarginFixerProps {
    index: number;
}

const MarginFixer: React.FC<IMarginFixerProps> = ({index}) => {
    const styles = [stylesheet.marginFixer];
    if(index % 2 === 1) {
        styles.push(stylesheet.questionOdd);
    }
    return (
        <View style={styles}/>
    );
}

interface ILegendItemProps {
    variant: keyof typeof constants.scale;
    position?: 'first' | 'last';
}

export const LegendItem: React.FC<ILegendItemProps> = (props) => {
    const { variant, position } = props;

    const circleStyles = [stylesheet.legendCircle, {
        backgroundColor: constants.scale[variant].color
    }];

    const cellStyles = [stylesheet.legendCell];
    switch(position) {
        case 'first': cellStyles.push(stylesheet.legendCellLeft); break;
        case 'last': cellStyles.push(stylesheet.legendCellRight); break;
    }

    return (
        <View style={cellStyles}>
            <View style={circleStyles}/>
            <Text style={stylesheet.legendCellText}>{constants.scale[variant].description}</Text>
        </View>
    );
};

interface ILegendProps {}

const Legend: React.FC<ILegendProps> = () => {
    return (
        <View style={stylesheet.row}>
            <View style={stylesheet.question}/>
            <MarginFixer index={0}/>

            <LegendItem variant="inconclusiveOrNA" position="first"/>
            <LegendItem variant="regressive" />
            <LegendItem variant="blind" />
            <LegendItem variant="neutral" />
            <LegendItem variant="responsive" position="last"/>
        </View>
    );
};

interface IHeatmapProps {
    heatmap: IScoreDetailMC[];
    questions: IQuestion[];
    showRatio?: boolean;
}

export const Heatmap: React.FC<IHeatmapProps> = (props) => {
    const {
        heatmap,
        questions,
        showRatio = true
    } = props;

    const getHeatmapData = (questionId: string) => heatmap.find(mc => mc.questionId === questionId);
    return (
        <View style={[commonStylesheet.text, stylesheet.heatmapGrid]}>
            <Legend />
            {questions.map((question, i) => {
                const data = getHeatmapData(question.id);
                const total = Object.values(data!.mc!).reduce((a, v) => a + v, 0);
                return (
                    <View style={stylesheet.row} key={question.id}>
                        <Question question={question} index={i}/>
                        <MarginFixer index={i}/>
                        <Cell count={(data?.mc?.inconclusive || 0) + (data?.mc?.na || 0)} total={total} variant="inconclusiveOrNA" showRatio={showRatio}/>
                        <Cell count={data?.mc?.regressive || 0} total={total} variant="regressive" showRatio={showRatio}/>
                        <Cell count={data?.mc?.blind || 0} total={total} variant="blind" showRatio={showRatio}/>
                        <Cell count={data?.mc?.neutral || 0} total={total} variant="neutral" showRatio={showRatio}/>
                        <Cell count={data?.mc?.responsive || 0} total={total} variant="responsive" showRatio={showRatio}/>
                    </View>
                );
            })}
        </View>
    );
}
