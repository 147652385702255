import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import './TopBar.css';
import {branding, general} from '../../assets';
import {Link, useLocation} from 'react-router-dom';
import {INavButton} from "../../helpers/topbar-helpers";
import {useSelector} from 'react-redux';
import {BaseModal} from '../common/modal';
import {AuthModalWrapper } from '../auth';
import {Context as HeightContext} from '../../state/hooks/evaluationHeight';


interface INavProps{
    buttons: INavButton []
}

const TopBar: React.FC<INavProps> = (props:INavProps) => {
    const [modal, setModal] = useState<boolean>(false);
    const {setTopbar} = useContext(HeightContext);
    const location = useLocation();
    const {user} = useSelector((state: any) => (state.auth));
    const [visible, setVisible] = useState<boolean>();
    const heightRef = useRef<HTMLDivElement>(null);

    const memoizedCallback = useCallback(setTopbar, []);
    useEffect(() => {
        if (!!heightRef) {
            let height = 0;
            if (heightRef.current) {
                height = heightRef.current.clientHeight;
            }
            memoizedCallback(height);

        }
    }, [heightRef, memoizedCallback]);

    const  setIndex = (index: number)  => {
        localStorage.setItem("currentPageIndex", String(index));
        // setActiveIndex(index);
    };

    return (
        <header className='top-bar' ref={heightRef}>
            <div className='logo'>
                <Link to='/'>
                <h1 className={'text-wide'}>
                    <img src={branding.logoFull} alt='logo'/>
                    Gender Legislative Index
                </h1>
                <h1 className={'text-small'}>
                    <img src={branding.logoFull} alt='logo'/>
                    GLI
                </h1>
                </Link>
            </div>
            <div
                className={`blur ${visible && 'active'}`}
                onMouseDown={(e) => {
                    e.preventDefault();
                    setVisible(!visible);
                }}/>
            <img
                className={'hamburger'}
                src={general.hamburger}
                alt='='
                onMouseDown={(e) => {
                    e.preventDefault();
                    setVisible(!visible);
                }}/>
            <div className={`menu ${visible && 'visible'}`}>
                <ul>
                    {props.buttons.map((item,index) => {
                    return(
                        <NavButton
                            key = {index}
                            button={item}
                            selected={(location.pathname.includes(item.linkTo.split('/')[1]) || (location.pathname === '/' && item.linkTo === '/home'))}
                            onSelected={()=>{setVisible(false); setIndex(index)}}/>
                    );
                })}
                {(user !== null) ? (
                    <NavButton
                        key={'auth'}
                        button={{
                            linkTo: '/profile',
                            selected: false,
                            name: 'Profile'
                        }}
                        selected={location.pathname.includes('profile')}
                        onSelected={() => {setVisible(false); setIndex(6);}}
                    />
                ) : (
                    <div className={'nav-button'}>
                        <li onClick={() => {setModal(true);  setVisible(!visible);}}>
                            <span className={'login-item'}>Login</span>
                        </li>
                    </div>
                )}
                </ul>
            </div>
            <BaseModal visible={modal && user === null} size={'small'} toggle={() => setModal(false)}>
                <AuthModalWrapper/>
            </BaseModal>
        </header>
    )
};
export default TopBar;

 class NavButton extends React.Component<{button: INavButton , selected: boolean, onSelected: any} >{

     constructor(props: {button: INavButton, selected: boolean, onSelected:any}) {
         super(props);
         this.state = {
             selected : false
         }
     }

    render() : React.ReactElement{
        return (
            <div className={'nav-button'}>
                <li onClick={()=>this.getButton()} className={this.props.selected ? 'blue' :'black'}>
                    <Link to={this.props.button.linkTo}>{this.props.button.name}</Link>
                </li>
            </div>
        )
    };

     private getButton() {
         this.props.onSelected();
     }
 }
