import React, {ErrorInfo} from 'react';

interface StateType {
    hasError: boolean,
    error?: any,
    info?: any
}

/**
 * This lifecycle is invoked after an error has been thrown by a descendant components.
 *
 * https://reactjs.org/docs/react-component.html#componentdidcatch
 *
 * Note: currently no lifecycle methods exist for error boundaries but are likely to be added in future releases
 * https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes
 */
export default class ErrorBoundary extends React.Component<any, StateType> {
    constructor(props: any){
        super(props)
        this.state = {hasError: false}
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        this.setState({hasError: true, error, info}); // Display fallback UI
        console.error(error);
        console.info(info);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div>
                    <h1>Something went wrong.</h1>
                    Error:
                    <pre>{JSON.stringify(this.state.error, null, 2)}</pre>
                    Info:
                    <pre>{this.state.info ? this.state.info.componentStack : '-'}</pre>
                </div>
            );
        }
        return this.props.children;
    }
}
