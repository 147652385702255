import {Scale} from '../../../helpers/get-score';
import React from 'react';
import {general} from '../../../assets';
import './ScoreRadio.css';


interface IScoreRadioProps {
    name: string;
    warning?: string | false | undefined;
    values: any;
    setFieldValue: any;
    setTouched: any;
}

const ScoreRadio: React.FC<IScoreRadioProps> = ({name, warning, values, setFieldValue, setTouched}) => {
    return (
        <ul style={{listStyle: 'none'}}>
            {Scale.map((item) => (
                <div key={item.score.alpha} onClick={() => setFieldValue('score', item.score.alpha)}>
                    <li className={`radio-score`}>
                        {values.score === item.score.alpha ? (
                            <general.pointer/>
                        ) : (
                            <img src={general.pointer_unfilled} alt='o'/>
                        )}
                        <span className={warning ? 'score-warning' : ''}>{item.description}</span>
                    </li>
                </div>
            ))}
        </ul>
    );
};

export default ScoreRadio;


//interface IListItemProps {
//     enabled: boolean;
//     className: string;
//     description: string;
//     showPlaceholder?: boolean;
// }
//
// export const ScoreItem: React.FC<IListItemProps> = ({enabled, className, description, showPlaceholder}: IListItemProps) => (
//     <li className={`score ${(showPlaceholder) ? 'score-hover' : ''} ${className ? className : ''}`}>
//         {showPlaceholder && !enabled ? (
//             <img src={general.pointer_unfilled} alt='o'/>
//         ) : (
//             <general.pointer className={(enabled) ? className : 'hidden-path'}/>
//         )}
//         {description}
//     </li>
// );
