
import React from 'react';
import {connect} from 'react-redux';
import {IAboutContent} from '../../state/reducers/staticContent';
import Placeholder from '../../components/layout/Placeholder';
import {AlertMessage} from '../../components/layout';
import './About.css';
import constants from '../../constants';
import Markdown from '../../components/common/Markdown';
import {general} from '../../assets';

/**
 * Container for the about page
 */
interface IAboutProps {
    content?: IAboutContent;
    loading: boolean;
}
class About extends React.Component<IAboutProps> {

    componentDidMount(): void {
        document.title = `${constants.TITLE} - About`;
    }

    render(): React.ReactElement {
        const {content, loading} = this.props;

        if (loading) {
            return <Placeholder loading={loading}/>;
        }
        if (!content) {
            return <AlertMessage title={'Something went wrong'} message={'Content was not able to be loaded.'}/>
        }
        return (
            <div className={'about content-sizing'}>
                <h2>{content.title}</h2>
                <div className={'about-content'}>
                    <h3>{content.methodologySubheader}</h3>
                    {content.methodology && content.methodology.map((item) => (
                        <div key={item.title} className={'segment'}>
                            <h4>{item.title}{(item.link && item.linkText) && (
                                <a className={'heading-link'} target={'_blank'} href={item.link}>{item.linkText}</a>
                            )}</h4>
                            <Markdown children={item.content}/>
                        </div>
                    ))}
                    <br/>
                    <img className={'about-logo-uni'} src={general.uni_logo} alt='rapido'/>
                    <h3>{content.teamSubheader}</h3>
                    {content.team && content.team.map((item) => (
                        <div key={item.title} className={'segment'}>
                            {item.title && <h4>{item.title}{(item.link && item.linkText) && (
                                <a className={'heading-link'} target={'_blank'} href={item.link}>{item.linkText}</a>
                            )}</h4>}
                            <Markdown children={item.content}/>
                        </div>
                    ))}
                    <br/>
                    <h3>{content.assetsSubheader}</h3>
                    {content.assets && content.assets.map((item) => (
                        <div key={item.title} className={'segment'}>
                            <Markdown children={item.content}/>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state : any ) =>{
    return {
        content: state.content.about,
        loading: state.content.loading,
    }
};

export default  connect(mapStateToProps)(About);
