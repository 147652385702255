import React from 'react';
import {ISearchCategoryItem} from '../../helpers/search-indexes';
import './searchCategory.css'


interface ISearchCategoryProps {
    categoryItem : ISearchCategoryItem,
    onClick: any

}
const CategoryItem : React.FC<ISearchCategoryProps>  = (props:ISearchCategoryProps) => {
    const {
        onClick,
        categoryItem,
    } = props;
    return (
        <div
            className={`category-filter ${categoryItem.selected ? 'selected' : ''}`}
            onClick={() => (!categoryItem.selected) && onClick(categoryItem.id)}
        >
            <div className="category-item" title={categoryItem.title}>
                <img
                    src={categoryItem.selected ? categoryItem.icon_selected : categoryItem.icon}
                    alt={categoryItem.title}
                />
                <div className="title">
                    {categoryItem.title}
                </div>
            </div>
        </div>
    );
};

export default CategoryItem;
