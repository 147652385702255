import React, {useState} from 'react';
import './Score.css';
import {general} from '../../../assets';
import {Link, useParams} from 'react-router-dom';
import {ICompactAssessment, ILaw} from '../../../helpers/interfaces';
import {useSelector} from 'react-redux';
import { ScorePill } from '../../common/score-pill';
import ScorePopup from "../../common/score-popup/ScorePopup";

export const EvaluatorCount: React.FC<{assessments: any[] | undefined}> = ({assessments}) => {
    if (assessments !== undefined) {
        if (assessments.length > 0) {
            return (
                <>
                    <img src={general.person} alt='person'/>
                    <p>
                        This law has been scored by <b>{assessments.length} evaluator{(assessments.length === 1) ? '' : 's'}. </b>
                        To learn more about how this score is calculated, visit <Link to={'/scoring'}>Scoring</Link>.
                    </p>
                </>
            );
        }
    }
    return null;
};

interface IScoreProps {
    law: ILaw,
}

interface IScoreParams {
    assessmentId: string,
}

const Score: React.FC<IScoreProps> = ({law}) => {
    const {assessmentId} = useParams<IScoreParams>();
    const {scoring} = useSelector((state: any) => (state.content));
    const [hovering, setHovering] = useState(false);

    const findAssessment = (assessment: ICompactAssessment) => assessment.id === assessmentId;

    const {assessments} = law;
    const getTitle = () => {
        const index = assessments ? assessments.findIndex(findAssessment) : -1;
        if (index !== -1) {
            return `(Evaluator ${index + 1})`;
        }
        return '(Overall)';
    };

    const getScore = () => {
        if(!assessmentId || assessmentId === 'intention' || assessmentId === 'effect') {
            return law.score;
        } else {
            return assessments.find(findAssessment)!.score;
        }
    };
    const score = getScore();

    return (
        <div className="score">
            <div className="overall">
                <h3>Score based on International Standards {getTitle()}</h3>
                <div
                    className="score-score-pill"
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)}
                >
                    <ScorePill score={score} fill bold/>
                    {(hovering && score) && (
                        <ScorePopup score={score} mediumScreenExpandDirection="centered"/>
                    )}
                </div>
            </div>
            <div className="contents">
                <EvaluatorCount assessments={assessments}/>
                <img src={general.speech}  alt="speech"/>
                {scoring !== undefined && <p>{scoring.disclaimer.content}</p>}
            </div>
        </div>
    )
};

export default Score;
