import React from 'react';
import {EditorState, ContentBlock} from 'draft-js';
import './TextEditor.css';
import {LinkButton} from './Links';

interface IStyle {
    label: string,
    style: string,
}

const BLOCK_TYPES: IStyle[] = [
    {label: 'H1', style: 'header-one'},
    {label: 'H2', style: 'header-two'},
    {label: 'H3', style: 'header-three'},
    {label: 'H4', style: 'header-four'},
    {label: 'H5', style: 'header-five'},
    {label: 'H6', style: 'header-six'},
    {label: 'Blockquote', style: 'blockquote'},
    {label: 'UL', style: 'unordered-list-item'},
    {label: 'OL', style: 'ordered-list-item'},
];

const INLINE_STYLES: IStyle[] = [
    {label: 'Bold', style: 'BOLD'},
    {label: 'Italic', style: 'ITALIC'},
    {label: 'Underline', style: 'UNDERLINE'},
];

/** Custom block styling, applies css returned */
export const CustomBlockStyling = (block: ContentBlock): string => {
    switch (block.getType()) {
        case 'blockquote':
            return 'blockquote';
        default:
            return '';
    }
};


interface IStyleButtonProps {
    onToggle: (style: string) => void;
    active: boolean;
    label: string;
    style: string;
}
const StyleButton: React.FC<IStyleButtonProps> = ({onToggle, active, label, style}) => {
    return (
        <div className={`editor-button ${(active) ? 'active' : ''}`}>
            <span
                onMouseDown={e => {
                    // Note: on mouse down was used in the Draft.js docs
                    // I've used it here because it makes the editor feels snappy
                    e.preventDefault();
                    onToggle(style)
                }}>
                {label}
            </span>
        </div>
    );
};


interface IControlsProps {
    editorState: EditorState;
    onBlockToggle: (style: string) => void; // For block styles (h1, ul, etc.)
    onInlineToggle: (style: string) => void; // For inline styles (italic, bold, etc.)
    onLink: (link: string) => void;
    onRemoveLink: () => void;
}

const Controls: React.FC<IControlsProps> = ({editorState, onBlockToggle, onInlineToggle, onLink, onRemoveLink}) => {
    // Is block active
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    // Is inline active
    const currentStyle = editorState.getCurrentInlineStyle();

    // Is link active


    return (
        <div className={'editor-controls'}>
            {BLOCK_TYPES.map((style) => (
                <StyleButton
                    key={style.label}
                    active={style.style === blockType}
                    label={style.label}
                    onToggle={onBlockToggle}
                    style={style.style}/>
            ))}
            {INLINE_STYLES.map((style) => (
                <StyleButton
                    key={style.label}
                    active={currentStyle.has(style.style)}
                    label={style.label}
                    onToggle={onInlineToggle}
                    style={style.style}/>
            ))}
            <LinkButton
                active={false /*Todo: toggle disabled*/}
                onLink={onLink}
                onRemove={onRemoveLink}/>
        </div>
    );
};
export default Controls;
