import {AxiosInstance, AxiosResponse} from 'axios';

const PATH = 'standards';

export class StandardsService {
    private readonly axiosInstance: AxiosInstance;
    constructor(axios: AxiosInstance) {
        this.axiosInstance = axios;
    }

    //================================
    // Endpoints
    //================================

    /**
     * Get all categories
     */
    public getStandards = async (categoryIds: string[]): Promise<AxiosResponse> => {
        return await this.axiosInstance.post(`/${PATH}/categories`,  {categories: categoryIds});
    };

    public getAllBenchMarks = async ():Promise<AxiosResponse> => {
        return await  this.axiosInstance.get(`/${PATH}`);
    }


    //================================
    // Private Endpoints
    //================================

    /**
     * Edit existing benchmark / standard
     */
    public edit = async ({benchmarkId, description}: {benchmarkId: string, description: string}): Promise<AxiosResponse> => {
        return await this.axiosInstance.post(`/${PATH}/update`, {standardId: benchmarkId, description});
    }

    /**
     * Create new benchmark / standard
     */
    public create = async ({questionId, categoryId, description}: {questionId: string, categoryId: string, description: string}): Promise<AxiosResponse> => {
        return await this.axiosInstance.post(`/${PATH}/create`, {questionTextId: questionId, categoryId, description});
    }

    /**
     * Delete existing benchmark / standard
     */
    public delete = async ({benchmarkId}: {benchmarkId: string}): Promise<AxiosResponse> => {
        return await this.axiosInstance.post(`/${PATH}/disable`, {standardId: benchmarkId});
    }
}
