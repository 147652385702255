import React from 'react';
import './Analysis.css';
import {useHistory, useParams} from 'react-router-dom';
import {ICompactAssessment, ILaw} from '../../../helpers/interfaces';
import ScoreDetail from '../score-detail/ScoreDetail';
import {useSelector} from 'react-redux';
import {UserRole} from '../../../helpers/enums';
import {AlertMessage} from '../../layout';
import {Blog} from '../../blog';
import {Toggle} from '../toggle';
import DownloadLink from "./DownloadLink";

interface IAnalysisProps {
    lawId: string,
    law: ILaw,
    assessments?: ICompactAssessment[],
    flagged?: boolean;
}

interface IAnalysisParams {
    assessmentId?: string;
    type: 'intention' | 'effect';
}

const Analysis: React.FC<IAnalysisProps> = ({lawId, law, assessments, flagged = false}) => {
    const {assessmentId, type = 'intention'} = useParams<IAnalysisParams>() as IAnalysisParams;
    const assessmentIndex = () => (assessments) ? assessments.findIndex((assessment: any) => assessment.id === assessmentId) : -1;
    const history = useHistory();
    const {user} = useSelector((state: any) => (state.auth));

    const handleChangeTab = (value: 'intention' | 'effect') => {
        const parts = ['laws', lawId];
        if(assessmentId) {
            parts.push(assessmentId);
        }
        if(value) {
            parts.push(value);
        }
        history.push(`/${parts.join('/')}`);
    };

    const toOverall = () => {
        history.push(`/laws/${lawId}`);
    }

    /** Returns comment from law or from individual evaluator */
    const getComment = (): string | undefined => {
        if (!assessmentId) {
            return law.comments;
        }
        const index = assessmentIndex();
        if (index === -1) {
            return law.comments;
        }
        if (assessments) {
            return assessments[index].comments;
        }
        return undefined;
    };

    const getEvaluator = () => {
        const index = assessmentIndex();
        if (index >= 0 && assessments) {
            return `Evaluator ${index + 1}`;
        }
        return null;
    }

    const isEvaluator = !!assessmentId;

    return (
        <div className='analysis'>
            {flagged && (
                <>
                    {user?.role === UserRole.ARCHITECT && (
                        <div className={'analysis-alert'}>
                            <AlertMessage
                                className={'info'}
                                title={'Architect View'}
                                message={'Law has not been published, only architects can see this information.'}
                            />
                        </div>
                    )}
                    {user?.role === UserRole.EVALUATOR && (
                        <div className={'analysis-alert'}>
                            <AlertMessage
                                className={'info'}
                                title={'Evaluator View'}
                                message={'Law has not been published, you can only see your own evaluations.'}
                            />
                        </div>
                    )}
                </>
            )}
            {!assessments?.length ? (
                <div className={'analysis-alert no-load'}>
                    <AlertMessage className={'info'} title={'Nothing here'} message={'No evaluations to load.'}/>
                </div>
            ) : (
                <>
                    {isEvaluator && (
                        <button onClick={toOverall} className="analysis-back">
                            &lt; Back to Overall Evaluation
                        </button>
                    )}
                    {!!getComment() && (
                        <>
                            <h5 className={'comments-title'}>
                                {!isEvaluator
                                    ? 'Summary'
                                    : `${getEvaluator()} Comments`
                                }
                            </h5>
                            <p className={'comments-description'}>
                                {getComment()}
                            </p>
                        </>
                    )}
                    <div className='title'>
                        <h3>{getEvaluator()} Evaluation</h3>
                        <DownloadLink law={law}/>
                    </div>
                    <div className="selector">
                        <Toggle value={type} onChange={handleChangeTab}/>
                    </div>
                    <ScoreDetail lawId={lawId} assessmentId={assessmentId} type={type}/>
                </>
            )}
            <Blog lawId={lawId} className="analysis-blog"/>
        </div>
    );
};

export default Analysis;
