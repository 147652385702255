import React, {ChangeEvent, useState} from 'react';
import {TextArea, TextInput} from '../common/text-input';
import './BlogComments.css';
import {branding, general} from '../../assets';

export type BlogCommentObject = { author: string, body: string }


interface IEditCommentProps {
    initial?: BlogCommentObject;
    onSubmit: (val: BlogCommentObject) => void;
    onRemove?: () => void;
    onCancel: () => void;
}
const EditComment: React.FC<IEditCommentProps> = ({initial, onSubmit, onRemove, onCancel}) => {
    const starting: BlogCommentObject = (initial) ? initial : {author: '', body: ''};
    const [author, setAuthor] = useState<string>(starting.author);
    const [body, setBody] = useState<string>(starting.body);

    return (
        <div className={'edit-comment'}>
            <img className={'comment-cancel'} src={general.cross} alt='x' onClick={onCancel}/>
            <TextInput
                onChange={(e: ChangeEvent<HTMLInputElement>) => setAuthor(e.target.value)}
                label={'Author'}
                value={author}/>
            <TextArea
                onChange={(e: ChangeEvent<HTMLInputElement>) => setBody(e.target.value)}
                label={'Comment'}
                value={body}/>
            <button
                className={'submit'}
                disabled={(author === starting.author && body === starting.body) || (author === '' || body === '')}
                onClick={(e) => {
                    e.preventDefault();
                    onSubmit({author, body});
                }}>{initial ? 'Submit': 'Add Comment'}</button>
            {(initial && onRemove) && (
                <button
                    className={'submit red'}
                    disabled={author !== starting.author || body !== starting.body}
                    onClick={(e) => {
                        e.preventDefault();
                        onRemove();
                    }}>
                    Remove
                </button>
            )}
        </div>
    );
};


interface IAddCommentProps {
    name?: string;
    value: BlogCommentObject[];
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    warning?: any;
}
const BlogComments: React.FC<IAddCommentProps> = (props) => {
    const [editing, setEditing] = useState<number | undefined>(undefined); // -1 = add new, > 0 is index of comment

    /** Adds new comment */
    const addComment = (val: BlogCommentObject) => {
        props.setFieldValue('comments', [...props.value, val]);
        cancel();
    };

    /** Edits existing comment */
    const editComment = (val: BlogCommentObject, index: number) => {
        const state = [...props.value];
        state[index] = val;
        props.setFieldValue('comments', state);
        cancel();
    };

    /** Removes a comment */
    const removeComment = (index: number) => {
        let state = [...props.value];
        state.splice(index, 1);
        props.setFieldValue('comments', state);
        cancel();
    };

    /** cancels edit/add comment */
    const cancel = () => setEditing(undefined);

    return (
        <div className={'blog-comments'}>
            {props.value.map((comment: BlogCommentObject, index: number) => (
                (editing === index ? (
                    <EditComment
                        key={index}
                        initial={comment}
                        onSubmit={(val) => editComment(val, index)}
                        onRemove={() => removeComment(index)}
                        onCancel={cancel}/>
                ) : (
                    <div key={index}>
                        {index !== 0 && <div className={'spacer'}/>}
                        <div key={index} className={'view-comment'} onClick={() => setEditing(index)}>
                            <h4>{comment.author}</h4>
                            <p>{comment.body}</p>
                        </div>
                    </div>
                ))
            ))}
            <div>
                {editing === -1 ? (
                    <EditComment onSubmit={addComment} onCancel={cancel}/>
                ) : (
                    <div className={'add-comment-button-wrapper'}>
                        <span className={'add-comment-button'} onClick={() => setEditing(-1)}>
                            <img src={branding.new_law_icon_blue} alt='+'/>Add Comment
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BlogComments;
