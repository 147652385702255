import React from 'react';
import './coverimage.css';

interface CoverImageProps {
    image: string,
    text: string,
}

const CoverImage: React.FC<CoverImageProps> = (props) => {
    const { image, text } = props;
    return (
        <div className="cover-image">
            <img className="cover-image-image" src={image} alt="cover"/>
            <div className="cover-image-text">{text}</div>
        </div>
    );
};

export default CoverImage;
