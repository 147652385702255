import React from 'react';
import './searchindex.css'
import {useHistory} from "react-router-dom";
import {getCategoryIcon, getCountryIcon} from '../../helpers/get-icons';

interface ISearchIndexProps {
    indexes: any;
    options?: 'country' | 'category';
}

//Search Index on the Home Page
const SearchIndex: React.FC<ISearchIndexProps> = ({indexes, options = 'category'}) => {
    return (
        <div className={'search-index-box'}>
            {/*Note: keys map is used to access id of indexes*/}
            {Object.keys(indexes).map((key) => (
                <SearchIcon
                    key={key}
                    icon={(options === 'category') ? getCategoryIcon(indexes[key].identifier).standard : getCountryIcon(indexes[key].identifier)}
                    title={indexes[key].title} id={key}
                    options={options}/>
            ))}
        </div>
    );

};

interface ISearchIconProps {
    icon: string;
    title: string;
    id: string;
    options?: 'country' | 'category';
}
const SearchIcon: React.FC<ISearchIconProps> = ({icon, title, id, options= 'category'}) => {
    const history = useHistory();

    /** Pass the filter to Search Result page */
    let searchBy = (id: string) => {
        if (options === 'category') {
            history.push(`/laws?category=${id}`);
        } else {
            history.push(`/laws?country=${id}`);
        }

    };

    return (
        <div className={'search-index-item'} onClick={() => searchBy(id)}>
            <div className={'icon-container'}>
                <img src={icon} alt={title}/>
            </div>
            <p>{title}</p>
        </div>
    );
};

export default SearchIndex;
