import React from 'react';
import './Details.css';
import {useLocation} from 'react-router-dom';
import {NotAuthorised, NotFound} from '../../layout';
import EditProfile from './profile-detail/EditProfile';
import MyEvaluations from './my-evaluations/MyEvaluations';
import AddLaw from './add-law/AddLaw';
import UserManagement from './user-management/UserManagement';
import ChangePasswordModal from '../../auth/modals/ChangePasswordModal';
import {Modal} from '../../common/modal';
import EditLaw from './add-law/EditLaw';
import {useSelector} from 'react-redux';
import {roleCheck} from '../../../helpers';
import {UserRole} from '../../../helpers/enums';
import {AddCategory} from './add-category';
import {EditCategory} from './edit-category';


const DetailsComponent = () => {
    const location = useLocation();
    const {user} = useSelector((state: any) => (state.auth));

    if (user) {
    switch (location.pathname) {
        case '/profile':
            return (
                <>
                    <EditProfile/>
                    <Modal size='small' trigger={<button>Change password</button>}><ChangePasswordModal close={() => null}/></Modal>
                </>
            );
        case '/profile/my-evaluations':
            return <MyEvaluations/>;
        case '/profile/user-management':

            if (roleCheck(user.role, UserRole.ARCHITECT)) {
                return <UserManagement/>;
            }
            return <NotAuthorised/>;
        case '/profile/new-law':
            if (roleCheck(user.role, UserRole.ARCHITECT)) {
                return <AddLaw/>;
            }
            return <NotAuthorised/>;
        case '/profile/new-category':
            if (roleCheck(user.role, UserRole.ARCHITECT)) {
                return <AddCategory/>;
            }
            return <NotAuthorised/>;
        case '/profile/edit-category':
            if (roleCheck(user.role, UserRole.ARCHITECT)) {
                return <EditCategory/>;
            }
            return <NotAuthorised/>;
        default:
            if (!roleCheck(user.role, UserRole.ARCHITECT)) {
                return <NotAuthorised/>;
            }
            if (location.pathname.includes('/profile/edit-law/')) {
                return <EditLaw/>;
            } else if (location.pathname.includes('/profile/edit-category/')) {
                return <EditCategory/>
            }
            return <NotFound/>;
        }
    }
    return <NotAuthorised/>;
};

const Details: React.FC = () => {
    return (
        <div className={'profile-details'}>
            <DetailsComponent/>
        </div>
    )
};

export default Details;
