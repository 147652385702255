import React from 'react';
import './LawForm.css';
import {TextInput} from '../../../common';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {FormDropdown, FormMultiDropdown} from '../../../common/dropdown';
import {useSelector} from 'react-redux';
import {getCategoryIcon, getCountryIcon} from '../../../../helpers/get-icons';
import useLawUpload from './useLawUpload';
import {ProgressBar} from '../../../common/progress';
import Dropzone from 'react-dropzone';

export type LawSchema = {
    title: string,
    country: string,
    year: string,
    lawNumber: string,
    primaryCategory: string,
    categories: string[],
    file: File | undefined,
};
const initial: LawSchema = {
    title: '',
    country: '',
    year: '',
    lawNumber: '',
    primaryCategory: '',
    categories: [],
    file: undefined,
};


interface ILawFormProps {
    onSubmit: (schema: LawSchema) => Promise<void>;
    initialSchema?: LawSchema;
}
const LawForm: React.FC<ILawFormProps> = ({onSubmit, initialSchema}) => {
    const {loading, progress} = useLawUpload();
    const {countries} = useSelector((state: any) => (state.countries));
    const {categories} = useSelector((state: any) => (state.categories));

    const handle = async (values: LawSchema): Promise<void> => {
        await onSubmit(values);
    };

    const yearList = (): { value: string, description: string }[] => {
        const currentYear = (new Date()).getFullYear();
        return Array.from(
            {length: (100)}, (p, i) => ({
                value: `${currentYear + (i * -1)}`,
                description: `${currentYear + (i * -1)}`,
            }));
    };

    const validation = Yup.object().shape({
        title: Yup.string()
            .required('Required'),
        country: Yup.string()
            .required('Required'),
        year: Yup.string()
            .required('Required'),
        lawNumber: Yup.string()
            .required('Required'),
        primaryCategory: Yup.string()
            .required('Required'),
        // Note: a function is used for the categories check so that 'test' has access to the Yup context
        categories: Yup.array().ensure().test('no-dupe', 'Cannot have duplicate secondary category', function (value: string[]) {
            const parentCate = this.parent.primaryCategory;
            const cates = this.parent.categories;
            if (!!parentCate && parentCate !== '') {
                return !cates.includes(parentCate);
            }
            return true;
        }),
        file: Yup.mixed()
            .required('Law PDF required'),
    });

    const validationWithOptionalFile = Yup.object().shape({
        title: Yup.string()
            .required('Required'),
        country: Yup.string()
            .required('Required'),
        year: Yup.string()
            .required('Required'),
        lawNumber: Yup.string()
            .required('Required'),
        primaryCategory: Yup.string()
            .required('Required'),
        categories: Yup.array().ensure().test('no-dupe', 'Cannot have duplicate secondary category', function (value: string[]) {
            const parentCate = this.parent.primaryCategory;
            const cates = this.parent.categories;
            if (!!parentCate && parentCate !== '') {
                return !cates.includes(parentCate);
            }
            return true;
        }),
        file: Yup.mixed(),
    });

    const initialVals = (initialSchema) ? initialSchema : initial;

    return (
        <div className={'profile-add-law'}>
            <Formik
                initialValues={initialVals}
                validationSchema={(initialSchema) ? validationWithOptionalFile : validation}
                onSubmit={handle}>
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      setErrors,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                      isSubmitting,
                  }) =>

                    <Form>
                        <table>
                            <tbody>
                            <tr>
                                <td>
                                    <span>Law Title</span>
                                    <TextInput
                                        label={'Law Title'}
                                        name={'title'}
                                        key={'title'}
                                        warning={(errors.title && touched.title) && errors.title}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.title}/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Jurisdiction</span>
                                    <FormDropdown
                                        name={'country'}
                                        label={'Country'}
                                        options={Object.keys(countries).map((key) => ({
                                            value: key,
                                            description: countries[key].title,
                                            img: getCountryIcon(countries[key].identifier),
                                        }))}
                                        onChange={handleChange}
                                        warning={(errors.country && touched.country) && errors.country}/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Year</span>
                                    <FormDropdown
                                        name={'year'}
                                        options={yearList()}
                                        onChange={handleChange}
                                        warning={(errors.year && touched.year) && errors.year}
                                        label={'Year'}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Law Number</span>
                                    <TextInput
                                        label={'Law Number'}
                                        name={'lawNumber'}
                                        key={'lawNumber'}
                                        warning={(errors.lawNumber && touched.lawNumber) && errors.lawNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.lawNumber}/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Category</span>
                                    <FormDropdown
                                        name={'primaryCategory'}
                                        label={'Category'}
                                        options={Object.keys(categories).map((key) => ({
                                            value: key,
                                            description: categories[key].title,
                                            img: getCategoryIcon(categories[key].identifier).standard,
                                        }))}
                                        onChange={handleChange}
                                        warning={(errors.primaryCategory && touched.primaryCategory) && errors.primaryCategory}/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Tag other categories</span>
                                    <FormMultiDropdown
                                        name={'categories'}
                                        label={'Tag Additional Categories'}
                                        options={Object.keys(categories).map((key) => ({
                                            value: key,
                                            description: categories[key].title,
                                            img: getCategoryIcon(categories[key].identifier).standard,
                                        }))}
                                        warning={errors.categories}/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Law PDF</span>
                                    <Dropzone
                                        accept={'application/pdf'}
                                        onDropRejected={() => setErrors({file: 'wrong file type'})}
                                        onDrop={acceptedFiles => setFieldValue('file', (acceptedFiles) ? acceptedFiles[0] : undefined)}>
                                        {({
                                              getRootProps,
                                              getInputProps,
                                              isDragActive,
                                          }) => (
                                            <div {...getRootProps({className: `file-input ${(values.file !== undefined) ? 'active' : ''} ${isDragActive ? 'dragging' : ''} ${(errors.file && touched.file) ? 'warning' : ''}`})}>
                                                <input {...getInputProps()} />
                                                {isDragActive ? (
                                                    <p className={'file-input-message'}>Drop here!</p>
                                                ) : (
                                                    <p className={`file-input-message ${(values.file !== undefined) ? 'file-selected' : ''}`}>
                                                        {
                                                            values.file ? `${values.file.name}`: `${initialSchema ? 'File upload optional' : 'Drop PDF file here'}`
                                                        }
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    </Dropzone>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        {
                            (
                                initialVals.title !== values.title ||
                                initialVals.country !== values.country ||
                                initialVals.year !== values.year ||
                                initialVals.lawNumber !== values.lawNumber ||
                                initialVals.primaryCategory !== values.primaryCategory ||
                                touched.categories ||
                                initialVals.file !== values.file
                            ) && (
                            <button type={'submit'} className={'submit'}>Submit</button>
                        )}
                    </Form>
                }
            </Formik>
            {
                loading && <ProgressBar width={(progress ? progress : 0)}/>
            }
        </div>
    );
};
export default LawForm;
