import React from 'react';
import './Glossary.css';
import Placeholder from '../../components/layout/Placeholder';
import {AlertMessage} from '../../components/layout';
import {IGlossaryContent} from '../../state/reducers/staticContent';
import {connect} from 'react-redux';
import constants from '../../constants';
import Markdown from '../../components/common/Markdown';
import {Citations} from '../../components/common/citations';


/**
 * Container for the glossary page
 */
interface IGlossaryProps {
    content?: IGlossaryContent;
    loading: boolean;
}
class Glossary extends React.Component<IGlossaryProps> {

    componentDidMount(): void {
        document.title = `${constants.TITLE} - Glossary`;
    }

    render(): React.ReactElement {
        const {content, loading} = this.props;

        if (loading) {
            return <Placeholder loading={loading}/>;
        }
        if (!content) {
            return <AlertMessage title={'Something went wrong'} message={'Content was not able to be loaded.'}/>
        }
        return (
            <div className={'glossary content-sizing'}>
                <h2>{content.title}</h2>
                {content.sections.map(row => (
                    <div key={row.title}>
                        <h4>{row.title}</h4>
                        <Markdown>{row.content}</Markdown>
                    </div>
                ))}
                <Citations citations={content.citations}/>
            </div>
        );
    }
}

const mapStateToProps = (state : any ) =>{
    return {
        content: state.content.glossary,
        loading: state.content.loading,
    }
};

export default connect(mapStateToProps)(Glossary);
