import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';
import {Provider as ReduxProvider} from 'react-redux';
import {default as store} from './state/store';
import {BrowserRouter as Router} from 'react-router-dom';
import {Provider as HeightProvider} from './state/hooks/evaluationHeight';

function isIE(){
    return window.navigator.userAgent.match(/(MSIE|Trident)/);
}

if(isIE()) {
    const element = document.getElementById('ie-message');
    if(element) {
        element.style.display = 'block';
    }
} else {
    ReactDOM.render(
        <ReduxProvider store={store}>
            <HeightProvider>
                <Router>
                    <App/>
                </Router>
            </HeightProvider>
        </ReduxProvider>, document.getElementById('root'));
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
