import React, {useCallback, useEffect, useState} from 'react';
import {getApiClient} from '../../../api';
import {IScoreDetail} from '../../../helpers/interfaces';
import {CategoryActions, QuestionActions} from '../../../state/actions';
import {useDispatch, useSelector} from 'react-redux';
import './ScoreDetail.css';
import ScoreDetailAggregate from './ScoreDetailAggregate';
import {Spinner} from "../../common/spinner";

interface ITableProps {
    lawId: string;
    assessmentId?: string;
    type: 'intention' | 'effect';
}

/**
 * Container for law detail table
 */
const ScoreDetail: React.FC<ITableProps> = (props) => {
    const [heatmap, setHeatmap] = useState<IScoreDetail>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string>();
    const {questions} = useSelector((state: any) => (state.questions));
    const dispatch = useDispatch();

    const {
        lawId,
        assessmentId,
        type,
    } = props;

    const getHeatmap = useCallback(async () => {
        setLoading(true);
        try {
            let heatmap;
            if (assessmentId) {
                heatmap = await getApiClient().assessService.getSingleScoreDetail(assessmentId);
                setHeatmap(heatmap.data.result);
            } else {
                heatmap = await getApiClient().assessService.getHeatmap(lawId);
                setHeatmap(heatmap.data);
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
            setError(`failed to load heatmap data: ${e.message}, ${e.code}`);
            console.error(e);
        }
    }, [assessmentId, lawId]);

    useEffect(() => {
        dispatch(QuestionActions.get());
        dispatch(CategoryActions.get());
    }, [dispatch])

    useEffect(() => {
        getHeatmap();
    }, [assessmentId, getHeatmap]);

    if (loading || !questions) {
        return <div className="score-detail-message"><Spinner/><p>Loading...</p></div>;
    } else if(error) {
        return <div className="score-detail-message"><p>{error}</p></div>;
    } else if (!heatmap) {
        return <div className="score-detail-message"><p>Heatmap not yet available</p></div>
    }

    return <ScoreDetailAggregate heatmap={heatmap} type={type} isEvaluator={!!assessmentId}/>;
}

export default ScoreDetail;
