import React from 'react';
import constants from '../../../constants';

export type ScoreOptions = keyof typeof constants['scale'];

interface ITooltipEvaluatorProps {
    count: number;
    total: number;
    score: ScoreOptions;
}

const TooltipEvaluatorCount: React.FC<ITooltipEvaluatorProps> = ({count, total, score}) => {
    return (
        <div className="tooltip-evaluator-count">
            <div>{count}/{total} evaluators scored</div>
            <div>{constants.scale[score].description}</div>
        </div>
    );
};

export default TooltipEvaluatorCount;
