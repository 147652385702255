import React, {useEffect, useRef} from 'react';
import {CircleIcon} from '../circle';
import {choiceToColor} from '../../../helpers';
import {useSelector} from 'react-redux';

interface ITooltipEvaluatorProps {
    id?: string,
    group?: string, // Intention or Effect
    choice?: string,
    justification?: string,
    reference?: string,
    standardIds?: string[],
    setFlip?: (val: boolean) => void;
}

const TooltipEvaluator: React.FC<ITooltipEvaluatorProps> = ({id, group, choice, justification, reference, standardIds = [], setFlip}: ITooltipEvaluatorProps) => {
    const {standardsMap} = useSelector((state: any) => (state.standards));
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!!setFlip) {
            if (ref.current) {
                if (ref.current.clientHeight > 200) {
                    setFlip(true);
                } else {
                    setFlip(false);
                }
            } else {
                setFlip(false);
            }
        }
    }, [setFlip, ref]);

    return (
        <div className={'tooltip-evaluator'} ref={ref}>
            <div className='tooltip-breakdown'>
                <CircleIcon color={choiceToColor(choice).color}/>
                <span>This law is {choiceToColor(choice).description} ({group})</span>
            </div>
            {justification &&  <p>{justification}</p>}
            {reference &&  <p>Ref: {reference}</p>}
            {(standardIds?.length > 0) &&
                <>
                    <span>Standards</span>
                    <ul>

                        {standardIds.map((standardId) =>
                            <li key={standardId}>{(standardsMap[standardId]) ? standardsMap[standardId].description : 'unavailable'}</li>)}
                    </ul>
                </>}
        </div>
    );
};

export default TooltipEvaluator;
