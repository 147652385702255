import React, {useState} from 'react';
import './Tooltip.css';
import { general } from '../../../assets';

interface ITooltipProps {
    visible: boolean;
    children: any;
    onMouseLeave?(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void
}
const Tooltip: React.FC<ITooltipProps> = ({visible, children, onMouseLeave}) => {
    const [flip, setFlip] = useState(false);

    return (visible) ? (
        <div className={`tooltip${flip ? ' flip' : ''}`} onMouseLeave={onMouseLeave}>
            <div className={`wrapper${flip ? ' flip' : ''}`}>
                <img src={general.tooltipPoint} alt="" className={flip ? ' flip' : ''}/>
                {React.cloneElement(children, {setFlip})}
            </div>
        </div>) : null;
};

export default Tooltip;
