import {IRouteConfig} from '../../helpers/interfaces';
import Home from './Home';

const PREFIX = '/home';

const routes: IRouteConfig[] = [
    {
        prefix: PREFIX,
        path: '',
        component: Home,
    },
];

export default routes;
