export const getScore = (score: string | number) => {
    switch (score) {
        case 'a':
        case 5:
            return {index: 5, className: 'meeting', description: 'Meeting international standards'};
        case 'b':
        case 4:
            return {index: 4, className: 'mostly', description: 'Mostly meeting international standards'};
        case 'c':
        case 3:
            return {index: 3, className: 'partially', description: 'Partially meeting international standards'};
        case 'd':
        case 2:
            return {index: 2, className: 'not', description: 'Not meeting international standards'};
        case 'e':
        case 1:
            return {index: 1, className: 'disregard', description: 'Complete disregard for international standards'};
        default:
            return {index: 0, className: 'na', description: 'Insufficient information to make an evaluation'};
    }
};

export const getNumericalScore = (score: string) => {
    switch (score) {
        case 'a':
            return 5;
        case 'b':
            return 4;
        case 'c':
            return 3;
        case 'd':
            return 2;
        case 'e':
            return 1;
        default:
            return -1;
    }
};

export const getDescriptionFromNumericalScore = (score: number | undefined) => {
    switch (score) {
        case 5:
            return {className: 'meeting', description: 'Meeting international standards'};
        case 4:
            return {className: 'mostly', description: 'Mostly meeting international standards'};
        case 3:
            return {className: 'partially', description: 'Partially meeting international standards'};
        case 2:
            return {className: 'not', description: 'Not meeting international standards'};
        case 1:
            return {className: 'disregard', description: 'Complete disregard for international standards'};
        default:
            return {className: 'na', description: 'Insufficient information to make an evaluation'};
    }
};

export const Scale = [
    {className: 'meeting', description: 'Meeting international standards', score: {numerical: 5, alpha: 'a'}},
    {className: 'mostly', description: 'Mostly meeting international standards', score: {numerical: 4, alpha: 'b'}},
    {className: 'partially', description: 'Partially meeting international standards', score: {numerical: 3, alpha: 'c'}},
    {className: 'not', description: 'Not meeting international standards', score: {numerical: 2, alpha: 'd'}},
    {className: 'disregard', description: 'Complete disregard for international standards', score: {numerical: 1, alpha: 'e'}},
    {className: 'na', description: 'Insufficient information to make an evaluation', score: {numerical: -1, alpha: 'f'}},
];


