import React, {useCallback, useEffect, useState} from 'react';
import './UserManagement.css';
import {IPagination, IUser} from '../../../../helpers/interfaces';
import {getApiClient} from '../../../../api';
import {AlertMessage} from '../../../layout';
import {SearchPagination} from '../../../search/results';
import {BaseModal, Modal} from '../../../common/modal';
import {EditUserModal, NewUserModal} from '../../../auth/modals';
import {branding} from '../../../../assets';



const UserManagement: React.FC = () => {
    const [users, setUsers] = useState<IUser[]>([]);
    const [pagination, setPagination] = useState<IPagination|null>(null);
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [modal, setModal] = useState<IUser|undefined>(undefined);



    const getUsers = async (val?: number) => {
        if (!loading) {
            setLoading(true);
            setError(null);
            try {
                const response = await getApiClient().usersService.all(val);
                const {items, pageInfo} = response.data;
                if (items !== null && pageInfo !== null) {
                    setUsers(response.data.items);
                    setPagination(response.data.pageInfo);
                } else {
                    throw new Error('API response object was malformed.')
                }
            } catch (e) {
                setError(e);
                setUsers([]);
                setPagination(null);
            }
            setLoading(false);
        }
    };

    const memoizedCallback = useCallback(getUsers, []);
    useEffect(() => {
        memoizedCallback();
    }, [memoizedCallback]);

    return (
        <>
            <h3>User Management</h3>
            <BaseModal visible={!!modal} size={'small'} toggle={() => setModal(undefined)}>
                <EditUserModal schema={modal} refresh={() => getUsers()} close={() => setModal(undefined)}/>
            </BaseModal>
            <AlertMessage message={error && error.message}/>
            <Modal trigger={
                    <button className={'user-management add-user'}>
                        <img src={branding.new_law_icon_blue} alt='^'/>
                        <span>Add User</span>
                    </button>
                } size={'small'}><NewUserModal refresh={() => getUsers()}/>
            </Modal>
            <div className={'user-grid'}>
                {users.map(item =>
                    <div key={item.id} className={'user-row'} onMouseDown={(e) => {e.preventDefault(); setModal(item)}}>
                        <div className={'user-name'}>{item.firstName} {item.lastName}</div>
                        <div className={'user-email'}>{item.email}</div>
                        <div className={'user-role'}>{item.role}</div>
                    </div>
                )}
            </div>
            <SearchPagination
                pagination={pagination}
                onChange={((val: number) => getUsers(val))}/>
        </>
    );
};
export default UserManagement;
