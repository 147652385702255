import React, {useRef, useState} from 'react';
import './Dropdown.css';
import {useField} from 'formik';
import useOutsideAlerter from '../../../helpers/hooks/OutsideAlert';
import BaseDropdown, {IDropdownOptions} from './BaseDropdown';

interface IDropdownProps {
    name?: string,
    onChange?: (value: string) => void,
    options?: IDropdownOptions[];
    value?: string,
    warning?: any,
    className?: 'small' | '',
    label?: string,
}

const FormDropdown: React.FC<IDropdownProps> = (
    {
        name = '',
        options = [],
        onChange,
        className = '',
        label,
        value,
        warning
    }) => {
    // Sets visibility of menu
    const [visible, setVisible] = useState(false);

    // Used for form validation
    // Note: "field" is not used but is needed for Formik field to work
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [field, meta, helpers] = useField(name);

    // Closes menu on click outside the dropdown
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => setVisible(false));

    /**
     * Handles updating change
     */
    const handleChange = (val: string) => {
        setVisible(!visible);
        helpers.setValue(val);
    };

    /**
     * Returns the current value for either the predefined value or the formik value
     */
    const getValue = () => (value !== undefined) ? value : meta.value;

    /**
     * Used to return either the placeholder or the selected value
     */
    const getTitle = (): IDropdownOptions => {
        // Return the placeholder or the current value
        if (getValue() === '' || getValue() === undefined) {
            return {value: '', description: `${label}`};
        } else {
            const selected = getSelected(getValue());
            return (selected) ? selected : {value: '', description: `${label}`};
        }
    };

    /**
     * Used to retrieve the current selected details
     */
    const getSelected = (selectedId: string): IDropdownOptions | undefined => {
        return options.find((item) => item.value === selectedId);
    };

    return (
        <BaseDropdown
            options={options}
            getTitle={getTitle}
            getValue={getValue}
            setValue={handleChange}
            className={className}
            warning={warning}/>
    );
};

export default FormDropdown;
