export default (groupLabel: string, request: any) => {
    console.group(groupLabel);
    const {method, url} = request;
    if (method || url) {
        console.log(`${request.method}: ${request.url} `);
    }
    const printObj = (obj: any) => {
        const {data} = obj;
        if (!data) {
            return;
        }
        if (data.constructor.name === 'FormData') {
            console.log('  Form data payload');
            for (const pair of data.entries()) {
                console.log('    ' + pair[0] + ', ' + pair[1]);
            }
        } else {
            console.log(data);
        }
    };
    printObj(request);
    console.groupEnd();
};
