import React from 'react';
import './benchmarks.css'
import {connect} from "react-redux";
import QuestionItem from "../../components/benchmarks/QuestionItem";
import CriteriaItem from "./CriteriaItem";
import constants from '../../constants';
/**
 * Container for the benchmarks page
 */

interface IBenchmarkProps {

    benchmarks: any,
    questions: any,
    loading: boolean
}

interface IBenchmarkState {
    loading: boolean
}
class Benchmarks extends React.Component<IBenchmarkProps,IBenchmarkState> {
    constructor(props:IBenchmarkProps) {
        super(props);

        this.state = {
            loading : true
        }
    }

    componentDidMount(): void {
        document.title = `${constants.TITLE} - Benchmarking`;
    }


    static getDerivedStateFromProps(props: (IBenchmarkProps), state: IBenchmarkState){
        if (props.questions == null) {
            return {
                loading: true
            };
        }else {
            return {
                loading: false
            };
        }

    }

    componentDidUpdate(prevProps: Readonly<IBenchmarkProps>, prevState: Readonly<IBenchmarkState>, snapshot?: any): void {
        if(prevState.loading !==  this.state.loading){
            this.setState({loading: !prevState.loading})
        }

    }

    render(): React.ReactElement {
        if(this.state.loading)
            return (<p>Loading..</p>);
        else
            return (
                <div className='benchmarks-route content-sizing'>
                <div className='benchmarks'>
                    <h2>Benchmarking</h2>
                    <div className='benchmarks-layout'>
                        <div className='benchmarks-content'>
                            <div>
                                <h3>Seven common criteria for comparability</h3>
                                <p>The GLI seeks to be a global tool that facilitates comparisons across jurisdictions and areas of law. In order to achieve this, a fair and comparable set of questions have been used. These questions have been derived from CEDAW and its 37 General Recommendations and provide a common set of overarching criteria used to assess all of the laws, regardless of the sector.</p>
                                <p> </p>
                                <p>One of the strongest arguments in favour of deriving standards from CEDAW is its almost universal ratification. By applying the approach of grounded theory, beginning with the CEDAW Committee’s first General Recommendation from 1989 and continuing until the 37th General Recommendation released in 2018, the GLI has incorporated 7 criteria that reflect the substantive general recommendations that the CEDAW Committee has directed at the currently 189 States Parties.</p>
                            </div>
                            <div>
                                <h3>Examples of good practice benchmarks</h3>
                                <p>The GLI applies the above criteria in a sector-specific manner. In order to do this, a set of benchmarks for each area of law have been derived from international law. To the greatest extent possible, these benchmarks strictly adhere to the language that appears in the global commitments (primarily international human rights treaties or ILO Conventions), neither reducing them nor expanding them.
                                </p>
                                <p> </p>
                                <p>In instances where no binding international law could be identified, the GLI relies on regional agreements that are relevant to that area of law.</p>
                            </div>
                        </div>
                        <div className='border-benchmark'/>
                        <div className={'links-to-questions'}>
                        <table className='navigate-to'>
                            <thead>
                            <tr>
                                <td>Navigate to</td>
                            </tr>
                            </thead>
                            <tbody>

                                {this.props.questions.map((item: any, index: any) => {
                                    return <QuestionItem key={index} question={`${index + 1}. ${item.description}`} id={item.id}
                                                         onClick={() => {
                                                         }}/>
                                })}
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>

                        {this.props.questions.map((item: any, index: any) => {
                            return (
                                <div key={item.id} id={item.id} style={{scrollMarginTop : "160px"}}>
                                    <CriteriaItem question={item.description} questionId={item.id}
                                          criteria={`Criteria ${index + 1}`}/>
                                </div>)

                        })}
                </div>
            );
        }
}

const mapStateToProps  = (state : any ) =>{
    return{
        benchmarks : state.benchmarks,
        questions: state.questions.questions,
        loading : state.questions.loading

    }
};
export default connect(mapStateToProps)(Benchmarks);
