import Axios, {AxiosInstance, AxiosResponse} from 'axios';

const PATH = 'filestorage';

export class StorageService {
    private readonly axiosInstance: AxiosInstance;
    constructor(axios: AxiosInstance) {
        this.axiosInstance = axios;
    }

    //================================
    // Private Endpoints
    //================================

    /**
     * Get presigned url
     *
     * Note: Law and file storage order on first upload
     * =============================================================
     * | Frontend      | S3               | Backend                |
     * |---------------|------------------|------------------------|
     * | GetPresigned  |                  |                        |
     * |               |                  | Return presigned url   |
     * | Upload to url |                  |                        |
     * |               | Confirm received |                        |
     * | Submit form   |                  |                        |
     * |               |                  | verify file upload     |
     * |               |                  | Create law and storage |
     * -------------------------------------------------------------
     */
    public getPresignedUrl = async (filename: string): Promise<AxiosResponse> => {
        return await this.axiosInstance.post(`/${PATH}`,  {filename});
    };

    public uploadFile = async (url: string, file: File, progress?: (val: ProgressEvent) => void): Promise<AxiosResponse> => {
        return await Axios.put(url, file, {onUploadProgress: progress, headers: {'Content-Type': 'application/pdf'}});
    }
}
