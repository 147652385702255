import {AxiosInstance, AxiosResponse} from 'axios';
import * as queryString from "querystring";

const PATH = 'laws';


/**
 * Dto for uploading a law
 */
export interface ILawDto {
    title: string,
    countryId: string,
    year: string,
    lawNumber: string,
    primaryCategory: string,
    categories: string[],
}

export interface IFileDto {
    filekey: string,
    originalfilename: string,
}

export class LawsService {
    private readonly axiosInstance: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axiosInstance = axios;
    }

    //================================
    // Endpoints
    //================================

    /**
     * Get laws, optional search with pagination
     */
    public all = async (
        {
            country, category, query, sortingName, sortingDir, page, limit
        }: {
            country: string, category: string, query: string, sortingName: string, sortingDir: string, page: string, limit?: number
        }, 
        alternative?: 'pending' | 'blog' | string
    ): Promise<AxiosResponse> => {
        // Check page index is valid and shift it from one index to zero index
        let parsed = Number.parseInt(page);
        if (Number.isNaN(parsed)) {
            parsed =  0;
        } else {
            parsed--;
        }

        const val = Object.assign({},
            country && {country},
            category && {category},
            query && {q: query},
            (sortingName && sortingDir) && {sorting: `${sortingName}.${sortingDir}`},
            {pageIndex: parsed},
        );

        if (limit) {
            val.pageSize = limit;
        }
        if (alternative === 'pending') {
            return await this.axiosInstance.get(`/${PATH}/pending?${queryString.stringify(val)}`);
        }
        if (alternative === 'blog') {
            return await this.axiosInstance.get(`/${PATH}/blogs?${queryString.stringify(val)}`);
        }
        return await this.axiosInstance.get(`/${PATH}?${queryString.stringify(val)}`);
    }

    /**
     * Get law by id
     */
    public get = async (id: string): Promise<AxiosResponse> => {
        return await this.axiosInstance.get(`/${PATH}/${id}`);
    }

    /**
     * Get most active
     */
    public getRecentEvaluation = async (): Promise<AxiosResponse> => {
        return await this.axiosInstance.get(`/${PATH}/getRecentEvaluation`);
    }

    //================================
    // Private Endpoints
    //================================
    /** Create a new law */
    public create = async (law: ILawDto & IFileDto): Promise<AxiosResponse> => {
        return await this.axiosInstance.post(`${PATH}/create`, law);
    }

    /** Update an existing law */
    public update = async (id: string, law: ILawDto): Promise<AxiosResponse> => {
        return await this.axiosInstance.post(`${PATH}/update/${id}`, law);
    }

    /** Update pdf for an existing law */
    public updateFile = async (id: string, file: IFileDto): Promise<AxiosResponse> => {
        return await this.axiosInstance.post(`${PATH}/update/${id}/file`, file);
    }

    /** Get all laws that I've assessed */
    public assessed = async (page?: number): Promise<AxiosResponse> => {
        return await this.axiosInstance.get(`/${PATH}/assessed${(page) ? `?pageIndex=${page}` : ''}`);
    }

    /** Sets a law to be either public or private, on public calculates score */
    public changeStatus = async (publish: boolean, lawId: string): Promise<AxiosResponse> => {
        return await this.axiosInstance.post(`${PATH}/publish/${lawId}`, {status: publish});
    }

    public comment = async (lawId: string, comments: string): Promise<AxiosResponse> => {
        return await this.axiosInstance.post(`${PATH}/comments/${lawId}`, {comments});
    }

    /**
     * Hide a law from the gli
     *
     * Note: law can only be re-enabled from modifying the database directly
     */
    public disableLaw = async (lawId: string): Promise<AxiosResponse> => {
        return await this.axiosInstance.post(`${PATH}/disable/${lawId}`);
    }
}
