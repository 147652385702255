import {IRouteConfig} from '../../helpers/interfaces';
import About from './About';

const PREFIX = '/about';

const routes: IRouteConfig[] = [
    {
        prefix: PREFIX,
        path: '',
        component: About,
    },
];

export default routes;
