import React, {useContext} from 'react';
import {useParams} from 'react-router-dom';
import constants from '../../constants';
import {getApiClient} from '../../api';
import {ILaw} from '../../helpers/interfaces';
import Placeholder from '../../components/layout/Placeholder';
import {AlertMessage, NotAuthorised} from '../../components/layout';
import {Pdf} from '../../components/evaluation/pdf';
import './Evaluation.css';
import {EvaluationPanel} from '../../components/evaluation/evaluation-panel';
import {Header} from '../../components/laws/header';
import {Context as HeightContext} from '../../state/hooks/evaluationHeight';


/**
 * Container for the evaluations page
 */
interface IEvaluationProps {
    lawId: string;
    viewHeight: number;
}

interface IEvaluationState {
    law: ILaw | null,
    loading: boolean,
    error: any,
    windowHeight: number,
}

interface IEvaluationParams {
    lawId: string,
}

class Evaluation extends React.Component<IEvaluationProps, IEvaluationState> {
    constructor(props: IEvaluationProps) {
        super(props);
        this.state = {law: null, loading: true, error: null, windowHeight: 0};
    }

    async componentDidMount(): Promise<void> {
        document.title = `${constants.TITLE} - Evaluate`;
        /** Load law information */
        try {
            const law = await getApiClient().lawsService.get(this.props.lawId);
            this.setState({law: law.data, loading: false});
            // await this.props.getCategories();
        } catch (e) {
            this.setState({loading: false, error: e});
            console.error(e);
        }
    }

    render(): React.ReactElement {
        const {lawId} = this.props;
        const {law, error, loading} = this.state;
        if (loading) {
            return (<Placeholder loading={loading}/>);
        } else if (error || !lawId) {
            // Handle errors
            if (error.response) {
                if (error.response.status === 401) {
                    return <NotAuthorised/>;
                }
            }
            return <AlertMessage className={'error'} title={'Error'} message={error.message}/>
        } else if (law) {
            return (
                <div className={'evaluation content-sizing'}>
                    <Header
                        title={law?.title}
                        category={law?.primaryCategory}
                        country={law?.country}
                        evaluations={law.assessments.length}
                    />
                    <div className={'evaluation-container'} style={{top: this.props.viewHeight + 8}}>
                        <div className={'pdf-wrapper'}>
                            <Pdf url={law.url}/>
                        </div>
                        <div className={'panel-wrapper'}>
                            <EvaluationPanel lawId={lawId} categories={[...((law.categories ? law.categories : [])?.map((category) => category.id)), law?.primaryCategory.id]}/>
                        </div>
                    </div>
                </div>
            );
        } else {
            // Note: This shouldn't happen
            return <AlertMessage
                className={'error'} title={'Law failed to load'}
                message={'The current law you\'re viewing failed to load.'}
            />
        }
    }
}

const EvaluationWrapper = () => {
    const {state} = useContext(HeightContext);
    const {lawId} = useParams<IEvaluationParams>();
    return <Evaluation lawId={lawId || ''} viewHeight={state.topbarHeight + state.headerHeight}/>
};

export default EvaluationWrapper;
