import React from 'react';
import './ProgressBar.css';

interface IProgressBarProps {
    width?: number;
}
const ProgressBar: React.FC<IProgressBarProps> = ({width = 0}) => {
    return (
        <div className={'bar'}>
            <div className={'progress'} style={{width: `${width}%`}}/>
        </div>
    );
};
export default ProgressBar;
