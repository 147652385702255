import {combineReducers} from 'redux';
import {default as AuthReducer} from './auth';
import {default as QuestionsReducer} from './questions';
import {default as CategoriesReducer} from './categories';
import {default as CountriesReducer} from './countries';
import {default as StandardsReducer} from './standards';
import {default as BenchmarkReducer} from './benchmarks';
import staticContent from './staticContent';

const rootReducer = combineReducers({
    auth: AuthReducer,
    questions: QuestionsReducer,
    countries: CountriesReducer,
    categories: CategoriesReducer,
    standards: StandardsReducer,
    benchmarks: BenchmarkReducer,
    content: staticContent,
});

export type IState = ReturnType<typeof rootReducer>;

export default rootReducer;
