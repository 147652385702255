import {Payload} from '../../helpers/create-action';
import {STANDARDS_ACTIONS} from '../actions';

export interface StandardsState {
    categoriesLoaded: any;
    standardsMap: any;
    loading: boolean;
    error: any | null;
}

const initialState: StandardsState = {
    loading: false,
    categoriesLoaded: {},
    standardsMap: {},
    error: null,
};

export default (state: StandardsState = initialState, {type, payload}: Payload): StandardsState => {
    switch (type) {
        case STANDARDS_ACTIONS.STANDARDS_REQUEST:
            return {...state, loading: true, categoriesLoaded: {...state.categoriesLoaded, ...payload}, error: null};
        case STANDARDS_ACTIONS.STANDARDS_SUCCESS:
            return {...state, loading: false, standardsMap: {...state.standardsMap, ...toStandardsMap(payload)}, error: null};
        case STANDARDS_ACTIONS.STANDARDS_FAILURE:
            return {...state, loading: false, error: payload};
        default:
            return state;
    }
}


const toStandardsMap = (items: any[]): any => {
    return Object.assign({}, ...items.map(item => ({[item.id]: {...item, categoryId: item.category.id, questionId: item.questionText.id, id: undefined, questionText: undefined, category: undefined}})));
}
