import React from 'react';
import {Document, Font, Page} from '@react-pdf/renderer';
import NunitoSansRegular from '../../../fonts/NunitoSans-Regular.ttf';
import NunitoSansBold from '../../../fonts/NunitoSans-Bold.ttf';
import NunitoSansItalic from '../../../fonts/NunitoSans-Italic.ttf';
import NunitoSansLightItalic from '../../../fonts/NunitoSans-LightItalic.ttf';
import {IAssessmentHeatmap, IBlog, ILaw, IQuestion, IScoreDetail} from "../../../helpers/interfaces";
import {Header} from "./PDFHeader";
import {Summary} from "./PDFSummary";
import {OverallScore} from "./PDFOverallScore";
import {Citation} from "./PDFCitation";
import {PageNumber} from "./PDFPageNumber";
import {Evaluation, EvaluatorEvaluation} from "./PDFEvaluation";
import {Blog} from "./PDFBlog";
import {createStylesheet} from "./styleHelper";

Font.register({
    family: 'NunitoSans',
    fonts: [
        {
            src: NunitoSansRegular,
        },
        {
            src: NunitoSansBold,
            fontWeight: 700,
        },
        {
            src: NunitoSansItalic,
            fontStyle: 'italic'
        },
        {
            src: NunitoSansLightItalic,
            fontWeight: 'light',
            fontStyle: 'italic',
        },
    ]
});

Font.registerHyphenationCallback(word => [word])

export const commonStylesheet = createStylesheet({
    page: {
        fontFamily: 'NunitoSans',
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        padding: 40,
        paddingBottom: 70,
        color: '#000000',
        lineHeight: 1.4,
    },
    title: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    text: {
        fontSize: 10,
        fontWeight: 'normal',
    },
});

interface IEvaluationDocumentProps {
    law: ILaw;
    blog?: IBlog;
    overallHeatmap: IScoreDetail;
    evaluatorHeatmaps: IAssessmentHeatmap[];
    questions: IQuestion[];
}

interface IStandardPageProps {
    law: ILaw;
}

export const StandardPage: React.FC<IStandardPageProps> = (props) => {
    const { law, children } = props;
    return (
        <Page size="A4" style={commonStylesheet.page}>
            <Header law={law}/>
            {children}
            <PageNumber/>
        </Page>
    );
};

const EvaluationDocument: React.FC<IEvaluationDocumentProps> = (props) => {
    const {
        law,
        blog,
        overallHeatmap,
        questions,
        evaluatorHeatmaps
    } = props;
    return (
        <Document>
            <StandardPage law={law}>
                {law.comments && <Summary summary={law.comments}/>}
                <OverallScore score={law.score} evaluatorCount={law.assessments.length}/>
                <Citation law={law}/>
            </StandardPage>
            <StandardPage law={law}>
                <Evaluation
                    title="Intention"
                    heatmap={overallHeatmap.intention}
                    questions={questions}
                />
            </StandardPage>
            <StandardPage law={law}>
                <Evaluation
                    title="Likely Effect"
                    heatmap={overallHeatmap.effect}
                    questions={questions}
                />
            </StandardPage>
            {evaluatorHeatmaps.map((heatmap, i) => {
                const name = `Evaluator ${i + 1}/${evaluatorHeatmaps.length}`;
                return (
                    <React.Fragment key={heatmap.id}>
                        <StandardPage law={law}>
                            <EvaluatorEvaluation
                                title={`${name} (Intention)`}
                                comment={heatmap.comments}
                                heatmap={heatmap.result.intention}
                                questions={questions}
                            />
                        </StandardPage>
                        <StandardPage law={law}>
                            <EvaluatorEvaluation
                                title={`${name} (Likely Effect)`}
                                heatmap={heatmap.result.effect}
                                questions={questions}
                            />
                        </StandardPage>
                    </React.Fragment>
                );
            })}
            {blog && (
                <StandardPage law={law}>
                    <Blog blog={blog}/>
                </StandardPage>
            )}
        </Document>
    );
};

export default EvaluationDocument;
