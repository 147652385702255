import React from 'react';
import {ILaw} from '../../../helpers/interfaces';
import './LawItem.css';
import {branding, general} from '../../../assets';
import {getCountryIcon} from '../../../helpers/get-icons';
import {ScorePill} from '../score-pill';
import {useHistory} from 'react-router-dom';

interface ILawProps {
    law: ILaw,
    isStretch?: boolean, //stretch is a law item on the search page, not stretch is a law item on the home page
    goToMyEvaluation?: boolean;
}

const LawItem: React.FC<ILawProps> = ({law, isStretch = false, goToMyEvaluation = false}) => {
    const history = useHistory();

    const navigateToLaw = () => {
        history.push(`/laws/${law.id}`);
    };

    return (
        <tr className={`law-item ${isStretch ? 'stretch' : ''}`} onClick={navigateToLaw}>
            <td>
                <p className="title">{law.title}</p>
                <div className="l-score">
                    <ScorePill score={law.score}/>
                </div>
                <div className="l-stats">
                    <div className="meta country">
                        <img src={getCountryIcon(law.country.identifier)} alt='x'/>
                        {law.country.title}
                    </div>
                    <div className="meta year">
                        <img src={branding.calendar} alt='x'/>
                        {law.year}
                    </div>
                    <div className="meta number">
                        #{law.lawNumber}
                    </div>
                    {law.assessmentCount !== undefined && (
                        <div className="meta assessed">
                            <img src={general.pen} alt='x'/>
                            {law.assessmentCount}
                        </div>
                    )}
                </div>
            </td>
        </tr>
    );
};

export default LawItem;
