import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {NotFound, PrivateRoute} from '../components/layout';
import {UserRole} from '../helpers/enums';
import {default as authRoutes} from './auth';
import {default as lawRoutes} from './laws';
import {default as aboutRoutes} from './about';
import {default as benchmarksRoutes} from './benchmarks';
import {default as glossaryRoutes} from './glossary';
import {default as scoringRoutes} from './scoring';
import {default as homeRoutes} from './home';
import {default as searchRoutes} from './search';
import {default as leadersRoutes} from './leaders';
import {connect} from 'react-redux';
import Home from "./home/Home";


const routes = [
    ...authRoutes,
    ...leadersRoutes,
    ...lawRoutes,
    ...aboutRoutes,
    ...benchmarksRoutes,
    ...glossaryRoutes,
    ...scoringRoutes,
    ...searchRoutes,
    ...homeRoutes
];

const toPath = (prefix: string | undefined, path: string | string[]): string | string[] => {
    if (Array.isArray(path)) {
        return path.map((single) => `${prefix ? prefix : ''}${single}`);
    }
    return `${prefix ? prefix : ''}${path}`;
};

interface IRootRouterProps {
    loading: boolean,
    user: any,
}

class RootRouter extends React.Component<IRootRouterProps> {
    render(): React.ReactElement {
        const {loading, user} = this.props;
        return (
            <Switch>
                {routes.map((route, index) => (
                    (!!route.requiredRole ? (
                        <PrivateRoute
                            loading={loading}
                            key={index}
                            path={toPath(route.prefix, route.path)}
                            role={(user) ? user.role : UserRole.NOT_LOGGED_IN}
                            required={route.requiredRole}>
                            {route.component}
                        </PrivateRoute>
                    ) : (
                        <Route
                            key={index}
                            path={toPath(route.prefix, route.path)}
                            component={route.component}/>
                    ))
                ))}
                <Route
                    exact
                    key={'home'}
                    path={['/', '/forgot']}
                    component={Home}/>
                <Route component={NotFound}/>
            </Switch>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        loading: state.auth.loading,
        user: state.auth.user,
    }
};

export default connect(mapStateToProps)(RootRouter);
