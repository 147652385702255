import {Payload} from '../../helpers/create-action';
import {STATIC_CONTENT_ACTIONS} from "../actions";


/** Home content */
export interface IHomeContent {
    title: string;
    sections : IStaticSection[];
    citations: string[];
}

/** Glossary content */
export interface IGlossaryContent {
    title: string;
    sections : IStaticSection[];
    citations: string[];
}

/** Scoring content */
export interface IScoringContent {
    title: string;
    subheader: string;
    subcontent: string;
    scale: {
        regressive: IExampleSection;
        blind: IExampleSection;
        neutral: IExampleSection;
        responsive: IExampleSection;
        inconclusiveOrNA: IExampleSection;
    }
    citations: string[];
    standard: IStaticSection;
    disclaimer: {title: string} & IExampleSection;
}

export interface ILeaderSection {
    title: string,
    content: string,
    country: string,
}

/** Country content */
export interface ICountryContent {
    leaders: {
        title: string;
        introduction: string,
        sections: {
            megawati: ILeaderSection,
            maria: ILeaderSection,
            gloria: ILeaderSection,
            chandrika: ILeaderSection,
            julia: ILeaderSection,
        };
    }
    parliament: {
        title: string,
        sections: {
            // julia: ILeaderSection,
        }
    }
    info: {
        title: string;
        sections: string[];
    }
}

/** Evaluator prompts content */
export interface IEvaluationContent {
    subsections: {
        "intention"?: string;
        "effect"?: string;
    },
    questions: {
        "1"?: string;
        "2"?: string;
        "3"?: string;
        "4"?: string;
        "5"?: string;
        "6"?: string;
        "7"?: string;
    },
    mc: {
        "responsive"?: string;
        "neutral"?: string;
        "blind"?: string;
        "regressive"?: string;
        "inconclusive"?: string;
        "na"?: string;
    },
    justification?: string;
    benchmarks?: string;
    comments?: string;
    bands?: string;

}

/** About content */
export interface IAboutContent {
    title: string;
    teamSubheader: string;
    team: {
        title?: string;
        link?: string;
        linkText?: string;
        content: string; // Markdown
    }[],
    methodologySubheader: string;
    methodology: {
        title: string;
        link?: string;
        linkText?: string;
        content: string; // Markdown
    }[];
    assetsSubheader: string;
    assets: {
        title: string;
        content: string; // Markdown
    }[];
}

/** Section format */
export interface IStaticSection {
    title: string;
    content: string;
}

/** Scale format */
export interface IExampleSection {
    content: string;
    example: string;
}

/** Static content interface */
interface  IStaticContentState {
    loading: boolean;
    home?: IHomeContent;
    scoring?: IScoringContent;
    glossary?: IGlossaryContent;
    about?: IAboutContent;
    evaluation?: IEvaluationContent;
    country?: ICountryContent;
    error: any;
}

/** Static content reducer */
const initialState : IStaticContentState = {
    loading : true,
    home: undefined,
    scoring: undefined,
    glossary: undefined,
    evaluation: undefined,
    country: undefined,
    error: undefined,
};
const  staticContent = (state: IStaticContentState = initialState, action: Payload ) => {
    switch(action.type){

        case STATIC_CONTENT_ACTIONS.GET_HOME_CONTENT:
            return {...state , loading: true, home: undefined, error : undefined};
        case STATIC_CONTENT_ACTIONS.GET_HOME_CONTENT_SUCCESS:
            return {...state , loading: false, home: action.payload, error : null};
        case STATIC_CONTENT_ACTIONS.GET_HOME_CONTENT_ERROR:
            return { ...state, loading: false, home: undefined, error: { message: action.payload.message, name: action.payload.name, stack: action.payload.stack}}
        case STATIC_CONTENT_ACTIONS.GET_ALL_CONTENT:
            return {...state, loading: true, home: undefined, scoring: undefined, glossary: undefined};
        case STATIC_CONTENT_ACTIONS.GET_ALL_CONTENT_SUCCESS:
            return {...state, loading: false, home: action.payload.home, scoring: action.payload.scoring, glossary: action.payload.glossary, about: action.payload.about, evaluation: action.payload.evaluation , country: action.payload.country,  error: undefined};
        case STATIC_CONTENT_ACTIONS.GET_ALL_CONTENT_ERROR:
            return {...state, loading: false, home: action.payload.home, scoring: action.payload.scoring, glossary: action.payload.glossary, about: action.payload.about, evaluation: action.payload.evaluation,  country: action.payload.country, error: action.payload.error};
        default:
            return state;
    }
};

export default staticContent;
