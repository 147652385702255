import {Dispatch} from 'redux';
import {getApiClient} from '../../api';
import {BenchmarksState} from '../reducers/benchmarks';
import {createAction} from "../../helpers";

export const BENCHMARK_ACTIONS = {
    BENCHMARK_REQUEST: 'BENCHMARK_REQUEST',
    BENCHMARK_SUCCESS: 'BENCHMARK_SUCCESS',
    BENCHMARK_FAILURE: 'BENCHMARK_FAILURE',
};

const BenchmarksAction = {
    benchmarksRequest: () => createAction(BENCHMARK_ACTIONS.BENCHMARK_REQUEST),
    benchmarksSuccess: (benchmarks: any[]) => createAction(BENCHMARK_ACTIONS.BENCHMARK_SUCCESS, benchmarks),
    benchmarksFailure: (error: any) => createAction(BENCHMARK_ACTIONS.BENCHMARK_FAILURE, error)
};


/**
 * Pre-populates state with category ids
 *
 * TODO: pass in specific categories to load
 */
const get = () => async (dispatch: Dispatch, state: BenchmarksState) => {

    try {
        dispatch(BenchmarksAction.benchmarksRequest());
        const benchmark = await getApiClient().standardsService.getAllBenchMarks();
        if (Array.isArray(benchmark.data)) {
            let datamappedByQuestion: any = {};
            const questions = await getApiClient().assessService.getQuestions();
            for (let questionKey in questions.data) {
                // console.log('Start for', questionKey);
                var benchmarks: any = [];
                var datarecevied: any[] = benchmark.data;
                for (let index in datarecevied) {
                    let standard: any = benchmark.data[index];
                    if (standard.questionText.id === questions.data[questionKey].id) {
                        benchmarks.push(standard);
                    }
                }

                datamappedByQuestion[questions.data[questionKey].id] = benchmarks;

            }
            dispatch(BenchmarksAction.benchmarksSuccess(datamappedByQuestion));


        }
    } catch (e) {
        dispatch(BenchmarksAction.benchmarksFailure(e));
    }
};
// else do nothing, categories are already loade


export default {
    get,
}
