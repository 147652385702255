import React, {useState} from 'react';
import './TextInput.css';
import {general} from '../../../assets';

interface ITextInputProps {
    label?: string,
    name?: string,
    value?: string,
    onChange?: any,
    onBlur?: any,
    type?: string,
    warning?: any
    autocomplete?: string;
}

const TextInput: React.FC<ITextInputProps> = (
    {
        label,
        name,
        value,
        onChange,
        onBlur,
        type = 'text',
        warning,
        autocomplete = 'on',
    }) => {
    const [visiblePassword, setVisiblePassword] = useState<boolean>(false);

    // Allow toggle if password text field
    if (type === 'password') {
        return (
            <div>
                <input
                    className={`text-input password ${warning ? 'warning' : ''}`}
                    name={name}
                    value={value}
                    placeholder={label}
                    type={(visiblePassword) ? 'text' : type}
                    onChange={onChange}
                    onBlur={onBlur}
                    autoComplete={autocomplete}/>
                <img
                    className={'eye'}
                    src={(visiblePassword) ? general.iris_scan : general.iris_scan_grey}
                    alt='x'
                    onMouseDown={(e) => {
                        e.preventDefault();
                        setVisiblePassword(!visiblePassword)
                    }}/>
            </div>
        )
    }
    // Standard text input
    return (
        <input
            className={`text-input ${warning ? 'warning' : ''}`}
            name={name}
            value={value}
            placeholder={label}
            type={(visiblePassword) ? 'text' : type}
            onChange={onChange}
            onBlur={onBlur}
            autoComplete={autocomplete}/>
    );
};

export default TextInput;
