import {
    RawDraftContentState,
} from "draft-js";
import React from "react";
import {Link, Text, View} from "@react-pdf/renderer";
import {getDate} from "../../../helpers";
import {IBlog} from "../../../helpers/interfaces";
import {generateAttributedText, MappingFunction} from "./draftJSParser";
import {createStylesheet} from "./styleHelper";
import {commonStylesheet} from "./EvaluationDocument";

const stylesheet = createStylesheet({
    blogSpacing: {
        marginBottom: 5,
    },
    blogTitle: {
        fontSize: 12,
        fontWeight: 'bold',
        marginTop: 10,
    },
    blogDetails: {
        fontSize: 10,
        display: 'flex',
        flexDirection: 'row',
        fontStyle: 'italic',
        marginTop: 5,
        marginBottom: 15,
    },
    blogAuthor: {
        marginRight: 10,
    },
    blogContent: {
        fontSize: 10,
        fontWeight: 'normal',
    },
    blogContentBold: {
        fontWeight: 'bold',
    },

    blogComments: {
        marginTop: 20,
    },
    blogCommentsTitle: {
        fontWeight: 'bold',
        fontSize: 12,
        marginBottom: 4,
    },
    blogCommentText: {
        marginTop: 2,
        fontStyle: 'italic',
        fontWeight: 'light',
    }
});

interface BlogContentProps {
    content: RawDraftContentState;
}

const BlogContent: React.FC<BlogContentProps> = ({content}) => {
    return (
        <View style={stylesheet.blogContent}>
            {content.blocks.map(block => {
                if (block.text.length === 0) {
                    return <Text key={block.key}>&nbsp;</Text>
                }
                return <Text style={stylesheet.blogSpacing} key={block.key}>
                    {generateAttributedText(
                        block,
                        content.entityMap,
                        draftJSToPDF
                    )}
                </Text>;
            })}
        </View>
    );
};

const draftJSToPDF: MappingFunction = (
    activeRange,
    content,
    entityMap,
    key,
) => {
    if (!activeRange) {
        return <Text key={key}>{content}</Text>;
    }
    if ('key' in activeRange) {
        return <Link src={entityMap[activeRange.key].data.href} key={key}>{content}</Link>;
    }
    switch (activeRange.style) {
        case 'BOLD':
            return <Text style={stylesheet.blogContentBold} key={key}>{content}</Text>;
    }
}

interface BlogCommentsProps {
    comments: IBlog['comments'];
}

const BlogComments: React.FC<BlogCommentsProps> = ({comments}) => {
    if(comments.length === 0) {
        return null;
    }
    return (
        <View style={[commonStylesheet.text, stylesheet.blogComments]}>
            <Text style={stylesheet.blogCommentsTitle}>Comments</Text>
            {comments.map(comment => (
                <View key={comment.author}>
                    <Text>{comment.author}</Text>
                    <Text style={stylesheet.blogCommentText}>{comment.body}</Text>
                </View>
            ))}
        </View>
    );
};

interface IBlogProps {
    blog: IBlog;
}

export const Blog: React.FC<IBlogProps> = ({blog}) => (
    <View>
        <Text style={commonStylesheet.title}>Insights from the ground</Text>
        <Text style={stylesheet.blogTitle}>{blog.title}</Text>
        <View style={stylesheet.blogDetails}>
            <Text style={stylesheet.blogAuthor}>{blog.author}</Text>
            <Text>{getDate(blog.createdDate)}</Text>
        </View>
        <BlogContent content={blog.blog}/>
        <BlogComments comments={blog.comments}/>
    </View>
);
