import {useEffect} from 'react';

const useOutsideAlerter = (ref: any, cb: () => void) => {
    /**
     * Alert if clicked on outside of element
     * https://codesandbox.io/s/outside-alerter-hooks-lmr2y?module=%2Fsrc%2FOutsideAlerter.js
     */
    const  handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target)) {
            cb();
        }
    }

    useEffect(() => {
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });
};
export default useOutsideAlerter;
