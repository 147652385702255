import logo from './logo.svg';
import calendar from './calendar.svg';
import number from "./number.svg";
import family_icon from "./family_icon.svg";
import labour_icon from "./labour_icon.svg";
import reproductive_icon from "./reproductive_icon.svg"
import taxation_icon from "./taxation_law_icon.svg"
import gender_based_violance from "./gender_based_violence.svg"
import extractive_services_law from "./extractive_services_law.svg"
import financial_services from "./financial_icon.svg"

import family_icon_blue from "./family_icon_blue.svg";
import labour_icon_blue from "./labour_icon_blue.svg";
import reproductive_icon_blue from "./reproductive_icon_blue.svg"
import taxation_icon_blue from "./taxation_law_icon_blue.svg"
import gender_based_violance_blue from "./gender_based_violence_blue.svg"
import extractive_services_law_blue from "./extractive_services_law_blue.svg"
import financial_services_blue from "./financial_icon_blue.svg"

import family_icon_orange from "./family_icon_orange.svg";
import labour_icon_orange from "./labour_icon_orange.svg";
import reproductive_icon_orange from "./reproductive_icon_orange.svg"
import taxation_icon_orange from "./taxation_law_icon_orange.svg"
import gender_based_violance_orange from "./gender_based_violence_orange.svg"
import extractive_services_law_orange from "./extractive_services_law_orange.svg"
import financial_services_orange from "./financial_icon_orange.svg"

import family_icon_grey from "./family_icon_grey.svg";
import labour_icon_grey from "./labour_icon_grey.svg";
import reproductive_icon_grey from "./reproductive_icon_grey.svg"
import taxation_icon_grey from "./taxation_law_icon_grey.svg"
import gender_based_violance_grey from "./gender_based_violence_grey.svg"
import extractive_services_law_grey from "./extractive_services_law_grey.svg"
import financial_services_grey from "./financial_icon_grey.svg"
import all_categories from "./all_legislation.svg"
import all_categories_blue from "./all_legislation_blue.svg"
import all_categories_orange from "./all_legislation_orange.svg"
import all_categories_grey from "./all_legislation_grey.svg"

import profile_detail from './profile_details.svg';
import profile_detail_blue from './profile_details_blue.svg';
import profile_detail_grey from './profile_details_grey.svg';
import my_evaluation from './my_evaluation.svg';
import my_evaluation_grey from './my_evaluation_grey.svg';
import my_evaluation_blue from './my_evaluation_blue.svg';
import my_evaluation_orange from './my_evaluation_orange.svg';
import new_law_icon from './new_law_icon.svg';
import new_law_icon_grey from './new_law_icon_grey.svg';
import new_law_icon_blue from './new_law_icon_blue.svg';

import back_icon_blue from './back_icon_blue.svg';
import back_icon_grey from './back_icon_grey.svg';
import back_icon from './back_icon.svg';

import edit_law_blue from './edit_law_blue.svg';
import edit_law_grey from './edit_law_grey.svg';
import edit_law from './edit_law.svg';

import user_management from './user_management.svg';
import user_management_grey from './user_management_grey.svg';
import user_management_blue from './user_management_blue.svg';

import pending_evaluation from './pending_evaluation.svg';
import pending_evaluation_grey from './pending_evaluation_grey.svg';
import pending_evaluation_blue from './pending_evaluation_blue.svg';
import pending_evaluation_orange from './pending_evaluation_orange.svg';

export default {
    logoFull: logo,
    calendar,
    number,
    family_icon,
    labour_icon,
    reproductive_icon,
    taxation_icon,
    gender_based_violance,
    extractive_services_law,
    financial_services,
    family_icon_grey,
    labour_icon_grey,
    reproductive_icon_grey,
    taxation_icon_grey,
    gender_based_violance_grey,
    extractive_services_law_grey,
    financial_services_grey,
    family_icon_blue,
    labour_icon_blue,
    reproductive_icon_blue,
    taxation_icon_blue,
    gender_based_violance_blue,
    extractive_services_law_blue,
    financial_services_blue,
    family_icon_orange,
    labour_icon_orange,
    reproductive_icon_orange,
    taxation_icon_orange,
    gender_based_violance_orange,
    extractive_services_law_orange,
    financial_services_orange,
    all_categories,
    all_categories_grey,
    all_categories_blue,
    all_categories_orange,
    profile_detail,
    profile_detail_grey,
    profile_detail_blue,
    my_evaluation,
    my_evaluation_grey,
    my_evaluation_blue,
    my_evaluation_orange,
    new_law_icon,
    new_law_icon_grey,
    new_law_icon_blue,
    back_icon_blue,
    back_icon_grey,
    back_icon,
    edit_law,
    edit_law_blue,
    edit_law_grey,
    user_management,
    user_management_blue,
    user_management_grey,
    pending_evaluation,
    pending_evaluation_grey,
    pending_evaluation_blue,
    pending_evaluation_orange,
}
