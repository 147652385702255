import React, {cloneElement, useEffect, useState} from 'react';
import './Modal.css';
import {general} from '../../../assets';
import '../../submit/Submit.css';

interface IModalProps {
    children: JSX.Element,
    trigger: JSX.Element,
    size?: 'auto' | 'small' | 'medium' | 'large',
}

const Modal: React.FC<IModalProps> = ({trigger, children, size = 'auto'}: IModalProps) => {
    const [visible, setVisible] = useState(false);

    const toggle = (e?: any): void => {
        if (e) {
            e.preventDefault();
        }
        const next = !visible;
        if (next) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
        setVisible(next);
    };

    /** If component is unmounted, allow overflow regardless of state */
    useEffect(() => {
       return () => {
           document.body.style.overflow = 'unset';
       }
    }, []);

    const parentClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!e.isDefaultPrevented()) {
            toggle();
        }
    };

    return (
        <div>
            {trigger && (
                cloneElement(trigger, {
                    onClick: toggle,
                })
            )}
            {visible && (
                <div className='modal' onClick={parentClick}>
                    <div className={`modal-content ${(size === 'auto') ? '' : size}`} onClick={(e: React.MouseEvent<HTMLDivElement>) => e.preventDefault()}>
                        <img onClick={toggle} className='modal-cross' src={general.cross} alt='x'/>
                        {cloneElement(children, {
                            close: toggle,
                        })}
                    </div>
                </div>
            )}
        </div>
    )
};

export default Modal;
