import Search from "./Search";
import {IRouteConfig} from "../../helpers/interfaces";

// Note: search is under the laws endpoint (if modifying check for conflicts)
export const SEARCH_PREFIX = 'laws';

const routes: IRouteConfig[] = [
    {
        prefix: `/${SEARCH_PREFIX}`,
        path: '',
        component: Search,
    },
];

export default routes;
