import React from "react";
import {Text, View} from "@react-pdf/renderer";
import {createStylesheet} from "./styleHelper";
import {commonStylesheet} from "./EvaluationDocument";

const stylesheet = createStylesheet({
    pageNumber: {
        position: 'absolute',
        bottom: 40,
        left: 40,
    },
});

interface IPageNumberProps {
}

export const PageNumber: React.FC<IPageNumberProps> = () => {
    const totalToText = (total: number | undefined) => total ? `of ${total}` : '';
    return (
        <View style={[commonStylesheet.text, stylesheet.pageNumber]} fixed>
            <Text render={({pageNumber, totalPages}) => {
                return `Page ${pageNumber} ${totalToText(totalPages)}`
            }} fixed/>
        </View>
    );
};
