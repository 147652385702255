import {IRouteConfig} from '../../helpers/interfaces';
import WomenLeaders from './WomenLeaders';

const PREFIX = '/leaders';
// Keep old url to maintain old links
const OLD_PREFIX = '/country';

const routes: IRouteConfig[] = [
    {
        prefix: PREFIX,
        path: '',
        component: WomenLeaders,
    },
    {
        prefix: OLD_PREFIX,
        path: '',
        component: WomenLeaders,
    },
];

export default routes;
