import React from 'react';
import './Circle.css';

/**
 * Simplified circle for displaying additional information
 */
interface ICircleIconProps {
    color: string;
    diameter?: number;
}

const CircleIcon: React.FC<ICircleIconProps> = ({color, diameter = 15}) => (
    <div className='circle'>
        <svg className='icon' width={diameter} height={diameter}>
            <g>
                <circle
                    cx={diameter/2}
                    cy={diameter/2}
                    r={diameter/2}
                    fill={color}
                />
            </g>
        </svg>
    </div>
);

export default CircleIcon;
