import React, {useReducer} from 'react';
import {Payload} from '../../helpers/create-action';


export const HEIGHT_ACTIONS = {
    SET_TOPBAR: 'SET_TOPBAR',
    SET_HEADER: 'SET_HEADER',
};

export interface HeightState {
    topbarHeight: number;
    headerHeight: number;
}

const initialState: HeightState = {
    topbarHeight: 0,
    headerHeight: 0,
};

const reducer = (state: HeightState = initialState, {type, payload}: Payload) => {
    switch (type) {
        case HEIGHT_ACTIONS.SET_TOPBAR:
            return {...state, topbarHeight: payload};
        case HEIGHT_ACTIONS.SET_HEADER:
            return {...state, headerHeight: payload};
        default:
            return state;
    }
};

interface HeightContext {
    state: HeightState;
    setTopbar(height: number): void;
    setHeader(height: number): void;
}


export const Context = React.createContext<HeightContext>({
    state: {
        headerHeight: 0,
        topbarHeight: 0,
    },
    setHeader(height: number) {},
    setTopbar(height: number) {}
});

interface IProviderProps {
    children: any;
}
export const Provider: React.FC<IProviderProps> = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const setTopbar = (dispatch: any) => (height: number) => {
            dispatch({type: HEIGHT_ACTIONS.SET_TOPBAR, payload: height});
    };

    const setHeader = (dispatch: any) => (height: number) => {
        dispatch({type: HEIGHT_ACTIONS.SET_HEADER, payload: height});
    };

    const bound = {
        setTopbar: setTopbar(dispatch),
        setHeader: setHeader(dispatch),
    };

    return (
        <Context.Provider value={{state, ...bound}}>{children}</Context.Provider>
    );
};
