import React from 'react';
import './SearchResults.css';
import '../../laws/score/Score.css';
import LawItem from '../../common/law-item/LawItem';

interface IResultsProps {
    laws: any[],
    loading: boolean,
    notFoundText: string,
    goToMyEvaluation?: boolean;
}
const Results: React.FC<IResultsProps> = ({laws, loading, notFoundText, goToMyEvaluation = false}: IResultsProps) => {
    if (loading) {
        return (
            <p>loading...</p>
        )
    }
    if (laws.length === 0) {
        return (
            <p>{notFoundText}</p>

        )
    }
    return (
        <div className='results'>
            <table>
                <tbody>
                {laws.map((law) => <LawItem key={law.id} law={law} isStretch goToMyEvaluation={goToMyEvaluation}/>)}
                </tbody>
            </table>
        </div>
    );
};

export default Results;
