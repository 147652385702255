import React, {useState} from 'react';
import './ScoreGradient.css';
import {getScore} from "../../../helpers/get-score";
import {ScorePill} from "../../common/score-pill";
import ScorePopup from "../../common/score-popup/ScorePopup";

interface IScoreGradientProps {
    score: string | number,
}

const ScoreGradient: React.FC<IScoreGradientProps> = ({score}: IScoreGradientProps) => {
    const scoreDetails = getScore(score);
    const [hovering, setHovering] = useState(false);

    return (
        <div
            className={`score-gradient score-gradient-${scoreDetails.className}`}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
        >
            <ScorePill score={score} fill bold/>
            {scoreDetails.index !== 0 && (
                <>
                    <div className="score-gradient-pointer"/>
                    <div className="score-gradient-bar">
                        <div className="score-gradient-dot"/>
                    </div>
                </>
            )}
            {hovering && (
                <ScorePopup score={score} smallScreenExpandDirection="centered"/>
            )}
        </div>
    );
};

export default ScoreGradient;
