import React from 'react';
import './home.css'
import VideoPlayer from "../../components/home/VideoPlayer";
import {IHomeContent} from "../../state/reducers/staticContent";
import {connect} from "react-redux";
import FeaturedLaws from "../../components/home/FeaturedLaws";
import SearchIndex from "../../components/home/SearchIndex";
import {ICategory, ICountry, IUser} from "../../helpers/interfaces";
import {ResetModal} from '../../components/auth/modals';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import constants from '../../constants';
import Markdown from '../../components/common/Markdown';
import {Citations} from '../../components/common/citations';
import CountCard from '../../components/home/CountCard';
import CoverImage from '../../components/home/CoverImage';
import { images } from '../../assets';
import {getApiClient} from "../../api";
import {Spinner} from "../../components/common/spinner";

interface  IHomeProps extends RouteComponentProps {
    homeContent : IHomeContent;
    loading: boolean;
    categories: ICategory[];
    countries: ICountry[];
    user?: IUser;
}

interface IHomeState {
    loading: boolean,
    lawsCount: undefined,
}


class Home extends React.Component<IHomeProps , IHomeState> {

    constructor(props: IHomeProps) {
        super(props);
        this.state = {
            loading: true,
            lawsCount: undefined,
        }
    }

    componentDidMount(): void {
        document.title = `${constants.TITLE}`;

        getApiClient().lawsService.all({
            category: "",
            country: "",
            page: "",
            query: "",
            sortingDir: "",
            sortingName: "",
            limit: 1,
        }).then((response) => {
            this.setState({
                lawsCount: response.data.pageInfo.totalItemCount,
            });
        });
    }

    componentDidUpdate(prevProps: Readonly<IHomeProps>, prevState: Readonly<IHomeState>, snapshot?: any): void {
        if(prevProps.homeContent !==  this.props.homeContent){
            this.setState({loading: this.props.loading})
        }
    }

    static getDerivedStateFromProps(props: IHomeProps, state: IHomeState){
        if (props.loading !== state.loading) {
            return {
                homeContent: props.homeContent,
                loading: props.loading
            };
        }
        return null;

    }

    render(): React.ReactElement {
        if(this.state.loading){
            return <div><Spinner/></div>
        }

        const {
            categories,
            countries,
            homeContent,
            location,
            user,
        } = this.props;

        const countryCount = Object.keys(countries).length;
        const legislationCount = this.state.lawsCount;
        const categoryCount = Object.keys(categories).length;

        return (
            <div className='home'>
                {location.pathname === '/forgot' && <ResetModal/>}
                <CoverImage image={images.cover} text="Ranking and scoring legislation against global standards for women’s rights"/>
                <div className="content-sizing">
                    <div className='home-layout'>
                        <CountCard count={countryCount} text="countries"/>
                        <CountCard count={legislationCount} text="laws evaluated"/>
                        <CountCard count={categoryCount} text="areas of legislation"/>
                        <div className='home-content'>
                            <div className="home-heavy-content">
                                <Markdown>{homeContent.sections[0].content}</Markdown>
                            </div>
                            <div className="video-player-wrapper">
                                <VideoPlayer video="https://player.vimeo.com/video/404817635"/>
                            </div>
                            <div className="search-index">
                                <h3>Search Index - By Category</h3>
                                <SearchIndex indexes={categories} options={'category'}/>
                            </div>
                            <div className="search-index">
                                <h3>Search Index - By Country</h3>
                                <SearchIndex indexes={countries} options={'country'}/>
                            </div>
                            <div>
                                <h3>{homeContent.sections[1].title}</h3>
                                <Markdown>{homeContent.sections[1].content}</Markdown>
                            </div>
                            <div>
                                <h3>{homeContent.sections[2].title}</h3>
                                <Markdown>{homeContent.sections[2].content}</Markdown>
                            </div>
                            <div>
                                <h3>{homeContent.sections[3].title}</h3>
                                <Markdown>{homeContent.sections[3].content}</Markdown>
                            </div>
                            <div>
                                <h3>{homeContent.sections[4].title}</h3>
                                <Markdown>{homeContent.sections[4].content}</Markdown>
                            </div>
                            <Citations citations={homeContent.citations}/>
                        </div>
                        <div className='law-highlights'>
                            <FeaturedLaws options={'highlighted'}/>
                            {user && (
                                <>
                                    <br/>
                                    <FeaturedLaws options={'pending'}/>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps  = (state : any ) =>{
    return{
        homeContent : state.content.home,
        loading : state.content.loading,
        categories: state.categories.categories,
        countries: state.countries.countries,
        user: state.auth.user,
    }
};
export default withRouter(connect(mapStateToProps)(Home));
