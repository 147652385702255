import React from 'react';
import {NotAuthorised} from './index';
import {Route} from 'react-router-dom';
import {roleCheck} from '../../helpers';
import {UserRole} from '../../helpers/enums';
import {RouteProps} from 'react-router';
import Placeholder from './Placeholder';

interface IPrivateRouteProp extends RouteProps {
    children?: any;
    role: UserRole;
    required: UserRole;
    loading?: boolean;
}
const placeholder = () => (<Placeholder loading/>);
const PrivateRoute: React.FC<IPrivateRouteProp> = ({children, role, required, loading, ...props}) => (
    <>
        <Route
            {...props}
            component={(loading) ? placeholder : (roleCheck(role, required) ? (children) : (NotAuthorised))}
        />
    </>
);



export default PrivateRoute;
