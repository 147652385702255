import React, {useState} from 'react';
import {ForgotModal, LoginModal, PanelState} from './modals';

interface IAuthModalWrapperProps {
    close?: () => void;
}
const AuthModal: React.FC<IAuthModalWrapperProps> = ({close = ()=>null}: IAuthModalWrapperProps) => {
    const [panel, setPanel] = useState(PanelState.LOGIN);

    switch (panel) {
        case PanelState.FORGOT:
            return <ForgotModal setPanel={setPanel} close={close}/>;
        case PanelState.LOGIN:
        default:
            return <LoginModal setPanel={setPanel} close={close}/>
    }
};

export default AuthModal;
