import React, {useState} from 'react';
import {Form, Formik} from 'formik';
import {AlertMessage} from '../../layout';
import {TextInput} from '../../common';
import './AuthModal.css';
import * as Yup from 'yup';
import {Spinner} from '../../common/spinner';
import {getApiClient} from '../../../api';


type Schema = { currentPassword: string, password: string, repeatPassword: string };
const initial: Schema = {currentPassword: '', password: '', repeatPassword: ''};

interface IChangePasswordModal {
    close: () => void;
}

const ChangePasswordModal: React.FC<IChangePasswordModal> = ({close}: IChangePasswordModal) => {
    const [error, setError] = useState(null);

    const handle = async (values: Schema): Promise<void> => {
        try {
            await getApiClient().usersService.updatePassword(values.currentPassword, values.password);
            if (close) {
                close();
            }
        } catch (e) {
            setError(e);
        }
    };

    const validation = Yup.object().shape({
        currentPassword: Yup.string()
            .required('Required'),
        password: Yup.string()
            .min(8)
            .required('Required'),
        repeatPassword: Yup.string()
            .required('Required')
            .test({
                name: 'equalTo',
                exclusive: false,
                message: 'Passwords must match',
                test: function (value: any) {
                    return value === this.resolve(Yup.ref('password'));
                },
            })
    });

    return (
        <div className='auth-form'>
            <div className={'header-space'}>
                <h3>Change Password</h3>
            </div>
            <Formik
                initialValues={initial}
                validationSchema={validation}
                onSubmit={handle}>
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                  }) =>
                    <Form>
                        <div className={'input-space'}>
                            <TextInput
                                name={'currentPassword'}
                                label={'Current Password'}
                                type={'password'}
                                warning={(errors.currentPassword && touched.currentPassword) && errors.currentPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.currentPassword}/>
                        </div>
                        <div className={'input-space'}>
                            <TextInput
                                name={'password'}
                                label={'New Password'}
                                type={'password'}
                                warning={(errors.password && touched.password) && errors.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}/>
                        </div>
                        <div className={'input-space'}>
                            <TextInput
                                name={'repeatPassword'}
                                label={'Repeat Password'}
                                type={'password'}
                                warning={(errors.repeatPassword && touched.repeatPassword) && errors.repeatPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.repeatPassword}/>
                        </div>
                        <div className={'warning-space'}>
                            <AlertMessage className={'error'} message={error}/>
                        </div>
                        <button className={'submit'} type='submit' disabled={isSubmitting}
                                onClick={() => handleSubmit()}>
                            {(isSubmitting) && <Spinner/>} Change Password
                        </button>
                    </Form>
                }
            </Formik>
            <div className={'links-space'}/>
        </div>
    );
};
export default ChangePasswordModal;
