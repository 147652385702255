import React from 'react';
import './countcard.css';

interface CountCardProps {
    count?: number,
    text: string,
}

const CountCard: React.FC<CountCardProps> = (props) => {
    const { count, text } = props;
    return (
        <div className="count-card">
            <div className="count-card-count">
                {count === undefined
                    ? <>&nbsp;</>
                    : count
                }
            </div>
            <div className="count-card-label">{text}</div>
        </div>
    );
};

export default CountCard;
