import {AxiosInstance} from 'axios';
import {IUser} from '../../helpers/interfaces';

const PATH = 'users';

export class UsersService {
    private readonly axiosInstance: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axiosInstance = axios;
    }

    //================================
    // Endpoints
    //================================

    /**
     * Get my profile (uses jwt token to fetch my profile
     * Note: if this fails the jwt token is invalid or the server is unreachable
     */
    public getMe = async () => {
        return await this.axiosInstance.get(`/${PATH}/me`);
    }


    //================================
    // Private Endpoints
    // Note: will fail without token
    //================================
    /**
     * Gets list of users with pagination
     */
    public all = async (pageIndex?: number) => {
        let index = 0;
        if (pageIndex) {
            if (pageIndex >= 1) {
                index = pageIndex - 1;
            }
        }
        return await this.axiosInstance.get(`/${PATH}?pageSize=6&pageIndex=${index}`);
    };

    /**
     * Update own user
     */
    public update = async (firstName: string, lastName: string) => {
        return await this.axiosInstance.put(`${PATH}/me`, {firstName, lastName});
    };

    /**
     * Update user's as architect
     */
    public updatePrivileged = async (user: IUser) => {
        return await this.axiosInstance.put(`${PATH}/${user.id}`, {...user, password: undefined});
    };

    /**
     * Register user
     */
    public register = async (user: IUser & {password: string}) => {
        return await this.axiosInstance.post(`${PATH}`, user);
    };

    /**
     * Update my password with existing password
     */
    public updatePassword = async (oldPassword: string, newPassword: string) => {
        return await this.axiosInstance.post(`${PATH}/me/password`, {oldPassword, newPassword});
    };
}
