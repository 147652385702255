import {Payload} from '../../helpers/create-action';
import {COUNTRIES_ACTIONS} from '../actions';
import {arrayToMap} from '../../helpers';

export interface CountriesState {
    loading: boolean;
    countries: ICategoryMap,
    error: any | null;
}

interface ICategoryMap {
    [key: string]: {
        title: string;
        identifier: string;
        createdDate: string;
        updatedDate: string;
    };
}

const initialState: CountriesState = {
    loading: false,
    countries: {},
    error: null,
};

export default (state: CountriesState = initialState, {type, payload}: Payload): CountriesState => {
    switch (type) {
        case COUNTRIES_ACTIONS.COUNTRIES_REQUEST:
            return {...state, loading: true, error: null};
        case COUNTRIES_ACTIONS.COUNTRIES_SUCCESS:
            return {...state, loading: false, countries: arrayToMap(payload), error: null};
        case COUNTRIES_ACTIONS.COUNTRIES_FAILURE:
            return {...state, loading: false, error: payload};
        default:
            return state;
    }
}
