import {IRouteConfig} from '../../helpers/interfaces';
import Glossary from './Glossary';

const PREFIX = '/glossary';

const routes: IRouteConfig[] = [
    {
        prefix: PREFIX,
        path: '',
        component: Glossary,
    },
];

export default routes;
