import React from 'react';
import {connect} from 'react-redux';
import {AuthActions} from '../../state/actions';
// import { LoginForm } from '../../components/auth';
import {Dispatch} from 'redux';

interface ILoginProps {
    logout: () => void;
}

class Login extends React.Component<ILoginProps> {
    logoutDummy = () => {
        this.props.logout();
    };

    render(): React.ReactElement {
        return (
            <>
                {/*<LoginForm/>*/}
                <button onClick={this.logoutDummy}></button>
            </>
        );
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        logout: () => dispatch<any>(AuthActions.logout()),
    }
};

export default connect(null, mapDispatchToProps)(Login);
