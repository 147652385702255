import {RawDraftContentBlock, RawDraftContentState} from "draft-js";
import React from "react";

type InlineStyleRanges = RawDraftContentBlock['inlineStyleRanges'];
type EntityRanges = RawDraftContentBlock['entityRanges'];
type InlineStyleRange = InlineStyleRanges[number];
type EntityRange = EntityRanges[number];

type EntityMap = RawDraftContentState['entityMap'];
type AttributeRange = InlineStyleRange | EntityRange;
export type MappingFunction = (activeRange: AttributeRange | undefined, content: React.ReactNode, entityMap: EntityMap, key: string) => React.ReactNode;


const generateRangeStructures = (inlineStyles: InlineStyleRanges, entityRanges: EntityRanges) => {
    const rangeBreaks: Record<number, { starting: number[], ending: number[] }> = {};
    const ranges: AttributeRange[] = [...inlineStyles, ...entityRanges];

    ranges.forEach((style, i) => {
        if (!rangeBreaks[style.offset]) {
            rangeBreaks[style.offset] = {starting: [], ending: []};
        }
        if (!rangeBreaks[style.offset + style.length]) {
            rangeBreaks[style.offset + style.length] = {starting: [], ending: []};
        }
        rangeBreaks[style.offset].starting.push(i);
        rangeBreaks[style.offset + style.length].ending.push(i);
    });

    const rangeBreakOffsetList = (
        Object.keys(rangeBreaks)
            .map(key => parseInt(key, 10))
            .sort((a, b) => a - b)
    );

    return {
        rangeBreaks,
        ranges,
        rangeBreakOffsetList,
    };
}

// TODO handle block types (e.g. header-one, header-two, etc)
export const generateAttributedText = (
    content: RawDraftContentBlock,
    entityMap: EntityMap,
    mapFunction: MappingFunction
) => {
    const {
        text,
        inlineStyleRanges,
        entityRanges
    } = content;

    const elements = [];
    let key = 0; // key for react child array

    let activeRanges: number[] = [];
    const {
        rangeBreaks,
        ranges,
        rangeBreakOffsetList
    } = generateRangeStructures(inlineStyleRanges, entityRanges);

    // Map just text if no ranges
    if (rangeBreakOffsetList.length === 0) {
        elements.push(mapFunction(undefined, text, entityMap, `${key++}`));
    } else {
        // Add text before first attributed range
        const firstOffset = rangeBreakOffsetList[0];
        if (firstOffset !== 0) {
            elements.push(mapFunction(undefined, text.substring(0, firstOffset), entityMap, `${key++}`))
        }

        // Iterate between ranges
        rangeBreakOffsetList.forEach((rangeBreakOffset, i) => {
            // Exit if at end of text
            if (rangeBreakOffset === text.length) {
                return;
            }

            const rangeBreak = rangeBreaks[rangeBreakOffset];
            // Activate ranges
            activeRanges = activeRanges.concat(rangeBreak.starting);
            // Deactivate ranges
            activeRanges = activeRanges.filter(activeIndex => !rangeBreak.ending.includes(activeIndex));

            // Get text content
            const nextIndex = i + 1;
            const rangeEnd = (nextIndex < rangeBreakOffsetList.length) ? rangeBreakOffsetList[nextIndex] : undefined;
            let content: React.ReactNode = text.substring(rangeBreakOffset, rangeEnd);

            // Run mapping function for each active range
            activeRanges.forEach(rangeIndex => {
                const range = ranges[rangeIndex];
                content = mapFunction(range, content, entityMap, `${key++}`);
            });

            elements.push(content)
        });
    }
    return elements;
};
