import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Blog} from '../../../blog';
import {useHistory, useLocation, useParams, Link} from 'react-router-dom';
import '../EditLaw.css';
import LawForm, {LawSchema} from './LawForm';
import useLawUpload from './useLawUpload';
import {ILaw} from '../../../../helpers/interfaces';
import {AlertMessage} from '../../../layout';
import {getApiClient} from '../../../../api';
import LawComment from './LawComment';
import {Modal} from '../../../common/modal';
import {branding} from '../../../../assets';

const EditLaw = () => {
    const [law, setLaw] = useState<{ law?: ILaw, loading: boolean, error?: any }>({
        law: undefined,
        loading: true,
        error: undefined
    });
    const {lawId} = useParams();
    const {updateLaw} = useLawUpload();
    const history = useHistory();
    const location = useLocation();
    const [errors, setErrors] = useState<{ edit?: any, status?: any }>();


    /** scroll to blog on load if there is a blog flag */
    const blogRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (!law.loading) {
            const search = new URLSearchParams(location.search);
            if (search.get('blog') !== null) {
                // scroll to ref if blog is requested
                if (blogRef) {
                    blogRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }
        }
    }, [location, law]);

    const getLaw = async () => {
        await setLaw({law: law.law, loading: true, error: law.error});
        if (lawId === undefined) {
            await setLaw({law: undefined, loading: false, error: 'no law id'});
        } else {
            try {
                const result = await getApiClient().lawsService.get(lawId);
                await setLaw({loading: false, law: result.data, error: undefined});
            } catch (e) {
                await setLaw({loading: false, error: e});
            }
        }
    };

    const memoizedCallback = useCallback(getLaw, []);
    useEffect(() => {
        if (lawId) {
            memoizedCallback();
        }
    }, [lawId, memoizedCallback]);

    const getSchema = (): LawSchema | undefined => {
        if (law.law) {
            let categories: string[] = [];
            if (law.law.categories) {
                categories = law.law.categories.map((item) => item.id);
            }
            return {
                title: law.law.title,
                country: law.law.country.id,
                year: law.law.year,
                lawNumber: law.law.lawNumber,
                primaryCategory: law.law.primaryCategory.id,
                categories: categories,
                file: undefined,
            }
        }
    };

    const handle = async (values: LawSchema): Promise<void> => {
        setErrors(undefined);
        if (lawId) {
            try {
                await updateLaw(lawId, values);
                history.push(`/laws/${lawId}`);
            } catch (e) {
                setErrors({...errors, edit: e});
            }
        }
    };

    const publishLaw = async (makePublic: boolean) => {
        setErrors(undefined);
        if (lawId) {
            try {
                await getApiClient().lawsService.changeStatus(makePublic, lawId);
                window.location.reload();
            } catch (e) {
                setErrors({...errors, status: e});
            }
        }
    };

    const disableLaw = async () => {
        setErrors(undefined);
        if (lawId) {
            try {
                await getApiClient().lawsService.disableLaw(lawId);
                history.push('/');
            } catch (e) {
                setErrors({...errors, status: e});
            }
        }
    };

    if (law.loading) {
        return <p>Loading...</p>
    } else if (law.error) {
        return <AlertMessage className={'error'} title={'Failed to load law'} message={law.error}/>
    }
    if (law.law !== undefined) {
        return (
            <div className={'edit-law'}>
                <h3>Edit Law</h3>
                <Link to={`/laws/${lawId}`}><img className={'back-img'} src={branding.back_icon_blue} alt=""/>Back to
                    law</Link>
                <div className={'edit-container'}>
                    <div className={'edit-law-container'}>
                        <h3>Law Management</h3>
                        <LawForm onSubmit={handle} initialSchema={getSchema()}/>
                        <AlertMessage message={errors?.edit}/>
                    </div>
                    <div className={'manage-comments-container'}>
                        {lawId && <LawComment lawId={lawId} comments={law.law?.comments} cb={() => getLaw()}/>}
                    </div>
                    <div className={'blog-container'}>
                        <h3 ref={blogRef}>Blog Management</h3>
                        {lawId && <Blog editable lawId={lawId}/>}
                    </div>
                    <br/>
                    <div className={'manage-law-container'}>
                        <h3>Manage Status</h3>
                        <h4>Status: {(law.law?.flagged) ? 'private' : 'public'}</h4>
                        {law.law?.flagged ? (
                            <button className={'flag-btn submit'} disabled={!law.law?.flagged}
                                    onClick={() => publishLaw(true)}>Make Public
                            </button>
                        ) : (
                            <button className={'flag-btn submit'} disabled={law.law?.flagged}
                                    onClick={() => publishLaw(false)}>Make Private
                            </button>
                        )}
                        <Modal trigger={<button className={'submit red'}>Delete Law</button>} size={'small'}>
                            <>
                                <h3>Delete Law</h3>
                                <h5>{law.law?.title}</h5>
                                <button className={'submit red'} onClick={() => disableLaw()}>Are you sure you want to
                                    delete this law
                                </button>
                                <AlertMessage message={errors?.status}/>
                            </>
                        </Modal>
                        <AlertMessage message={errors?.status}/>
                    </div>
                </div>
            </div>
        );
    }
    return <p>Failed to load law. {JSON.stringify(law)}</p>

};
export default EditLaw;
