import React from "react";
import {Text, View} from "@react-pdf/renderer";
import {createStylesheet} from "./styleHelper";
import {commonStylesheet} from "./EvaluationDocument";
import {IAssessmentQuestionDetail, IQuestion, IScoreDetailMC} from "../../../helpers/interfaces";
import {Heatmap} from "./PDFHeatmap";

const stylesheet = createStylesheet({
    evaluation: {
    },
    title: {
        marginBottom: 10,
    },
    comment: {
        marginBottom: 10,
    },
    commentTitle: {
        fontSize: 12,
        fontWeight: 'bold',
    }
});

interface IEvaluationProps {
    title: string;
    heatmap: IScoreDetailMC[];
    questions: IQuestion[];
}

export const Evaluation: React.FC<IEvaluationProps> = (props) => {
    const { heatmap, questions } = props;
    return (
        <View style={stylesheet.evaluation}>
            <Text style={[commonStylesheet.title, stylesheet.title]}>
                Overall Evaluation: {props.title}
            </Text>
            <Heatmap heatmap={heatmap} questions={questions} />
        </View>
    );
};

interface IEvaluatorEvaluationProps {
    title: string,
    heatmap: IAssessmentQuestionDetail[];
    questions: IQuestion[];
    comment?: string;
}

export const EvaluatorEvaluation: React.FC<IEvaluatorEvaluationProps> = (props) => {
    const { heatmap, questions, title, comment } = props;
    const convertedHeatmap: IScoreDetailMC[] = heatmap.map(question => ({
        mc: {
            [question.choice]: 1,
        },
        questionId: question.questionId,
    }));

    return (
        <View style={stylesheet.evaluation}>
            <Text style={[commonStylesheet.title, stylesheet.title]}>
                Evaluation: {title}
            </Text>
            {comment && (
                <View style={[commonStylesheet.text, stylesheet.comment]}>
                    <Text style={stylesheet.commentTitle}>Evaluator comments</Text>
                    <Text>{comment}</Text>
                </View>
            )}
            <Heatmap heatmap={convertedHeatmap} questions={questions} showRatio={false}/>
        </View>
    );
};
