import React, {RefObject, useContext, useEffect, useLayoutEffect, useRef} from 'react';
import {ICategory, ICountry} from '../../../helpers/interfaces';
import Breadcrumb from './Breadcrumb';
import './Header.css';
import {Context as HeightContext, HeightState} from '../../../state/hooks/evaluationHeight';
import {getScore} from '../../../helpers/get-score';
import {getCountryIcon} from "../../../helpers/get-icons";
import ScoreGradient from "./ScoreGradient";

interface IHeaderProps {
    title?: string,
    score?: string | number,
    country?: ICountry,
    category?: ICategory,
    evaluations?: number,
    year?: string,
}

function checkAndSetHeight(
    heightRef: RefObject<HTMLDivElement>,
    state: HeightState,
    setHeader: (height: number) => void
) {
    if(heightRef.current) {
        const height = heightRef.current.clientHeight;
        if(state.headerHeight !== height) {
            setHeader(height);
        }
    }
}

const Header: React.FC<IHeaderProps> = (props) => {
    const {
        title,
        score,
        country,
        category,
        year,
    } = props;

    const {setHeader, state} = useContext(HeightContext);
    const heightRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        checkAndSetHeight(heightRef, state, setHeader);
    });

    useEffect(() => {
        const resizeCallback = () => {
            checkAndSetHeight(heightRef, state, setHeader);
        }
        window.addEventListener('resize', resizeCallback);
        return () => {
            window.removeEventListener('resize', resizeCallback);
        }
    }, [state, heightRef, setHeader])

    if (!title) {
        return <div className='header'/>;
    }

    const scoreDetails = getScore(score!);

    return (
        <div className="header" ref={heightRef}>
            <div className="header-breadcrumbs">
                <Breadcrumb country={country} category={category}/>
            </div>
            <div className={`header-details ${score ? 'header-has-score' : ''} header-score-${scoreDetails.className}`}>
                <div className='header-left'>
                    <h2>{title}</h2>
                    <div className="header-meta">
                        {country &&
                            <div className="country">
                                <img src={getCountryIcon(country.identifier)} alt={`${country.title} flag`}/>
                                {country.title}
                            </div>
                        }
                        {year && <div className="year">{year}</div>}
                    </div>
                </div>
                {score &&
                    <div className="header-score">
                        <ScoreGradient score={score}/>
                    </div>
                }
            </div>
        </div>
    );
};

export default Header;
