import React from 'react';
import BlogForm, {BlogFormSchema} from './BlogForm';
import {connect} from 'react-redux';
import {RawDraftContentState} from 'draft-js';
import {getApiClient} from '../../api';
import {IBlog, IUser} from '../../helpers/interfaces';
import {UserRole} from '../../helpers/enums';
import TextViewer from './TextViewer';
import {branding} from '../../assets';
import './Blog.css';
import {Link} from 'react-router-dom';
import {AlertMessage} from '../layout';


interface IBlogState {
    loading: boolean;
    blog?: IBlog;
    error?: any;
    isEditing: boolean;
}

interface IBlogProps {
    lawId: string;
    user: IUser;
    editable?: boolean;
    className?: string;
}

class Blog extends React.Component<IBlogProps, IBlogState> {
    constructor(props: IBlogProps) {
        super(props);

        this.state = {
            loading: true,
            isEditing: false,
            blog: undefined,
            error: undefined,
        }
    }

    async componentDidMount(): Promise<void> {
        try {
            const blog = await getApiClient().blogsService.get(this.props.lawId);
            this.setState({error: undefined, blog: blog.data});
        } catch (e) {
            if (e.response) {
                if (e.response.status !== 404) {
                    this.setState({error: e});
                }
            }
        }
        this.setState({loading: false});
    }

    handleSubmit = async (values: BlogFormSchema & { blog: RawDraftContentState }, cb?: (error?: any) => void): Promise<void> => {
        this.setState({error: undefined});
        try {
            const blog = await getApiClient().blogsService.create(this.props.lawId, {
                title: values.title,
                author: values.author,
                blog: values.blog,
                comments: values.comments,
            });
            if (cb) { cb(); } // Clears form state
            this.setState({blog: blog.data});
            this.setState({isEditing: false});
        } catch (e) {
            if (cb) {
                cb(e.response);
            }
            this.setState({error: e});
        }
    };

    isArchitect = (): boolean => {
        if (this.props.user) {
            return this.props.user.role === UserRole.ARCHITECT;
        }
        return false;
    };

    isDraft = (): boolean => !!sessionStorage.getItem(`blog-${this.props.lawId}-editor`);
    getEditLabel = (): string => {
        if (this.isArchitect() && this.state.isEditing) {return 'Cancel';}
        if (this.isDraft()) {
            return 'Continue Blog';
        }
        if (!!this.state.blog) {
            return 'Edit Blog';
        }
        return 'Add Blog';
    };

    toggleEditor = () => this.setState({isEditing: !this.state.isEditing});

    render(): React.ReactElement {
        const {lawId, user, editable, className} = this.props;
        const {loading, blog, error} = this.state;

        const isEditing = this.isArchitect() && this.state.isEditing;

        if (loading) {
            return <p>Loading...</p>
        } else {
            return (
                <div className={className}>
                    {this.isArchitect() && editable && <button onClick={this.toggleEditor}>{this.getEditLabel()}</button>}
                    {(!editable && (!!blog || this.isArchitect())) && <h3>Insights from the Ground {(!blog && this.isArchitect()) && (
                        <Link className={'add-insight'} to={`/profile/edit-law/${lawId}?blog`}>
                            <img src={branding.new_law_icon_blue} alt='+'/>
                            <span>Add Insight</span>
                        </Link>
                    )}</h3>}
                    {isEditing ? (
                        <>
                            <BlogForm lawId={lawId} onSubmit={this.handleSubmit} user={user} previous={blog}/>
                        </>
                    ) : (
                        <>
                            {(!!blog) && <TextViewer blog={blog}/>}
                        </>
                    )}
                    <AlertMessage message={error}/>
                </div>
            );
        }
    }
}

const mapStateToProps = (state: any) => {
    return {
        user: state.auth.user,
    }
};

export default connect(mapStateToProps)(Blog);


