import {Payload} from '../../helpers/create-action';
import {AUTH_ACTIONS} from '../actions';
import {IUser} from '../../helpers/interfaces';

interface AuthState {
    loading: boolean;
    isAuthenticating: boolean;
    isAuthenticated: boolean;
    user: IUser | null;
    error: null;
}

const initialState: AuthState = {
    loading: true,
    isAuthenticating: false,
    isAuthenticated: false,
    user: null,
    error: null
};

/**
 * Auth Reducer
 *
 * Handles login, retrieve current user, logout and session extending
 */
export default (state: AuthState = initialState, {type, payload}: Payload) => {
    switch (type) {

        // Login
        case AUTH_ACTIONS.LOGIN_REQUESTED:
            return {...state, loading: true, isAuthenticated: false, isAuthenticating: true, error: null};
        case AUTH_ACTIONS.LOGIN_SUCCESS:
            return {...state, loading: false, isAuthenticated: true, isAuthenticating: false, error: null};
        case AUTH_ACTIONS.LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                isAuthenticating: false,
                error: {message: payload.message, name: payload.name, stack: payload.stack}
            };

        // Get my profile
        case AUTH_ACTIONS.ME_REQUESTED:
            return {...state, loading: true, user: null, error: null};
        case AUTH_ACTIONS.ME_SUCCESS:
            return {...state, loading: false, user: payload, error: null};

        // Logout
        case AUTH_ACTIONS.LOGOUT:
            return {...state, loading: false, user: null, isAuthenticated: false, isAuthenticating: false};
        default:
            return state;
    }
}
